import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  navTitle: "",
  progressItems: null
};

const globalsSlice = createSlice({
  name: "globals",
  initialState,
  reducers: {
    setNavTitle(state, action) {
      state.navTitle = action.payload;
    },
    setProgressItems(state, action) {
      state.progressItems = action.payload;
    },
  },
});

export const globalsReducer = globalsSlice.reducer;
export const { setNavTitle, setProgressItems } = globalsSlice.actions;

export const selectProgressItems = (state) => {
  return state.globals.progressItems
}
