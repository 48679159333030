import { default as i18n, default as i18next } from 'i18next';
import { initReactI18next } from 'react-i18next';
import { de } from './locales/de';
import { en } from './locales/en';

const resources = {
    de,
    en
};

i18n
    .use(initReactI18next)
    .init({
        resources,
        lng: 'de',
        fallbackLng: 'en'
    });

export const getAllTranslationObjects = (lang) => {
    return i18next.options?.resources[lang]?.translation ?
        Object.keys(i18next.options.resources[lang].translation).map((k) => {
            return {
                key: k,
                value: i18next.options.resources[lang].translation[k]
            }
        }) :
        []
}

export default i18n;