import React from "react";
import { Navigate, Route, Routes, useParams } from "react-router-dom";
import "./App.css";
import "./assets/css/themes/global-override.css";
import "./assets/css/themes/index.scss";
import Layout from "./components/BaseLayout";
import PrivateRoute from "./components/PrivateRoute";
import FleetDashboard from "./pages/Dashboard/Fleet";
import VehicleDashboard from "./pages/Dashboard/Vehicle";
import EditOrCreate from "./pages/EditOrCreate/EditOrCreate";
import List from "./pages/List/List";
import Login from "./pages/Login/Login";

import { useAuthenticator } from "@aws-amplify/ui-react";
import Settings from "pages/Account/Settings";
import CompanyDashboard from "pages/Dashboard/Company";
import Statistics from "pages/Statistics/Statistics";
import { useSelector } from "react-redux";
import { selectIsAdmin, selectIsFleetManager } from "store/userSlice";

export const Router = () => {
    const { authStatus } = useAuthenticator((context) => [context.authStatus]);
    const { route } = useAuthenticator((context) => [context.route]);
    const isFleetManager = useSelector(selectIsFleetManager);
    const isAdmin = useSelector(selectIsAdmin);
    const ListWrapper = () => {
        const { type } = useParams();
        return <PrivateRoute><List key={type} /></PrivateRoute>
    };
    return (
        <Routes>
            <Route exact path="/" element={<Layout />}>
                <Route
                    index
                    element={
                        <PrivateRoute>
                            <Navigate to={isFleetManager ? "/company-dashboard" : "/list/fleets"} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="list/:type"
                    shouldRevalidate={() => true}
                    element={
                        <ListWrapper />
                    }
                />
                <Route
                    path="create/:type"
                    element={
                        <PrivateRoute>
                            <EditOrCreate key={Date.now()} />
                        </PrivateRoute>
                    }
                />
                {isAdmin && <Route
                    path="statistics"
                    element={
                        <PrivateRoute>
                            <Statistics key={Date.now()} />
                        </PrivateRoute>
                    }
                />}
                <Route
                    path="edit/:type/:id"
                    element={
                        <PrivateRoute>
                            <EditOrCreate key={Date.now()} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="vehicle-dashboard/:id"
                    element={
                        <PrivateRoute>
                            <VehicleDashboard
                                key={Date.now()}
                            />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="fleet-dashboard/:id"
                    element={
                        <PrivateRoute>
                            <FleetDashboard key={Date.now()} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="company-dashboard"
                    element={
                        <PrivateRoute>
                            <CompanyDashboard key={Date.now()} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="company-dashboard"
                    element={
                        <PrivateRoute>
                            <CompanyDashboard key={Date.now()} />
                        </PrivateRoute>
                    }
                />
                <Route
                    path="settings"
                    element={
                        <PrivateRoute>
                            <Settings key={Date.now()} />
                        </PrivateRoute>
                    }
                />
                <Route path="login" element={route !== 'authenticated' || authStatus === 'configuring' || authStatus === "unauthenticated" ? <Login /> : <PrivateRoute>
                    <Navigate to={"/"} />
                </PrivateRoute>} />
            </Route>
        </Routes>
    );
}