import PropTypes from 'prop-types';
import React from 'react';
import { SelectField } from '../../../../stories/crud/FieldTypes/SelectField';

export const SelectFleetId = (props) => {
    const {
        type,
        action,
        itemId,
        preDefinedProperties,
        schemesHandler,
        postHandler,
        patchHandler,
        backHandler,
        hidden,
        getHandler,
        attr,
        value,
        index,
        formData,
        collectionData,
        htmlSchemes,
        onChangeFormData,
        onChangeCollectionData
    } = props;

    const handleOnChange = async (val) => {
        let data = { ...val };
        const colData = { ...collectionData };
        data.vehicleId = undefined;
        colData.vehicleId.options = [];
        onChangeCollectionData(colData);
        onChangeFormData(data)
    }
    return (
        <SelectField {...props} onChangeFormData={handleOnChange} collectionData={
            {
                ...collectionData,
                [attr]: {
                    ...collectionData[attr],
                    readonly: formData?.immutableByInitialTask ? true : collectionData[attr]?.readonly
                }
            }
        } />
    )
}
SelectFleetId.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
};