import React from "react";

const TermsOfUse_EN = () => {
  return (
    <>
      <div className="row">
        <div className="col-6">
          <p>
            <strong>Information according to § 5 TMG:</strong>
            <br /> VSC Bike GmbH
            <br /> Breite Str. 32
            <br /> 06542 Allstedt
            <br /> Germany
          </p>
          <p>
            <strong>Responsible according to § 18 Abs. 2 MStV:</strong>
            <br /> Thomas Herzog
            <br /> Innere Schneeberger Straße 20
            <br /> 08056 Zwickau
            <br /> Deutschland
          </p>
          <p>
            <strong>Represented by:</strong>
            <br /> Thomas Herzog (CEO)
            <br /> Christian Hennig (CTO)
          </p>
          <p>
            <strong>Contact us:</strong>
            <br /> telephone: +49 34652 67 16 - 0<br /> Fax: +49 34652 67 16 -
            19
            <br />
            e-mail:
            <a href="mailto:info@vsc.bike" class="link-mail">
              {" "}
              info(at)vsc.bike
            </a>
          </p>
          <p>
            <br />
            <strong>Register entry:</strong>
            <br /> Entry in the commercial register.
            <br /> Register court: Stendal Local Court
            <br /> Registration number: HRB 23979
          </p>
        </div>
        <div class="col-6">
          <p>
            <strong>Value added tax:</strong>
            <br /> Value added tax identification number according to §27 a
            Umsatzsteuergesetz:
            <br /> DE 815673590
          </p>
          <p>
            <strong>Dispute resolution:</strong>
            <br /> We are not willing or obliged to participate in dispute
            resolution proceedings before a consumer dispute resolution body.
            Link
          </p>
        </div>
      </div>
    </>
  );
};
export default TermsOfUse_EN;
