export const CONSTANTS = {
  namespaces: {
    FLEETS: "fleets",
    VEHICLES: "vehicles",
    FLEET_COMPANIES: "fleet-companies",
    MAINTENANCE_COMPANIES: "maintenance-companies",
    MAINTENANCE_TASKS: "maintenance-tasks",
    COMPONENTS: "components",
    USERS: "users",
    ERRORS: "errors",
    TRANSLATIONS: "translations",
    DATASETS: "datasets",
    HISTORIES: "histories",
    REMINDERS: "reminders",
    APP_LOGS: "app-logs",
  },
  roles: {
    ADMIN: "admin",
    FLEET_OPERATOR: "fleet_operator",
    FLEET_MANAGER: "fleet_manager",
    FLEET_VIEWER: "fleet_viewer",
    MAINTENANCE_MANAGER: "maintenance_manager",
    MAINTENANCE_OPERATOR: "maintenance_operator",
  },
  STATUS: {
    OPEN: "open",
    IN_PROGRESS: "in_progress",
    PLANNED: "planned",
    CLOSED: "closed",
    RETIRED: "retired",
    OPERATIONAL: "operational",
    MAINTENANCE: "maintenance",
    DEFECT: "defect",
    INACTIVE: "inactive",
    REMOVED: "removed"
  },
  CATEGORIES: {
    ELECTRICAL: 'electrical',
    MECHANICAL: 'mechanical',
    UVV: 'uvv',
    INSPECTION: 'inspection',
  },
  ACTIONS: {
    REPAIRED: "repaired",
    REPLACED: "replaced",
    NO_ACTION: "no_action",
    VEHICLE_NOT_PRESENT: "vehicle_not_present",
  },
  CRUD_PERMISSIONS: {
    ALL: "all",
    OWN: "own",
    NONE: "none",
  },
  METHODS: {
    GET: "GET",
    POST: "POST",
    PUT: "PUT",
    DELETE: "DELETE",
  },
  VEHICLE_TYPES: {
    electrical: "electrical",
    mechanical: "mechanical",
  },
  TYPES: {
    ELECTRICAL: "electrical",
    MECHANICAL: "mechanical",
  },
  ERROR_CODES: {
    AUTH: {
      NO_ROLE_PROVIDED: 'PX_001',
    },
    MAINTENANCE_TASKS: {
      TASK_ALREADY_CLOSED: 'PX_201',
    },
    COMPONENTS: {
      COMPONENT_ALREADY_EXISTS: 'PX_301',
      COMPONENT_ALREADY_RETIRED: 'PX_302',
    },
    VEHICLES: {
      NO_SERIAL_DEFINED: 'PX_401',
    },
    DEMO: {
      USER_IS_NOT_DEMO_USER: 'PX_800'
    },
    OFFLINE_SYNC: {
      FLEET_IS_LOCKED: 'PX_900',
      USER_HAS_A_LOCKED_FLEET_ALREADY: 'PX_901',
      NO_FLEET_ID_PROVIDED: 'PX_902',
      UPDATE_USER_FLEET_LOCK_FAILED: 'PX_903',
      UPDATE_USER_FLEET_UNLOCK_FAILED: 'PX_904',
      FLEET_LOCK_FAILED: 'PX_905',
      FLEET_UNLOCK_FAILED: 'PX_906',
      OFFLINE_SYNC_POST_FAILED: 'PX_907',
      OFFLINE_SYNC_PUT_FAILED: 'PX_908',
    },
  }
};
