import React from 'react';
import Dropdown from 'react-bootstrap/Dropdown';
import { RiAccountCircleFill } from "react-icons/ri";

export const AccountNav = ({ items }) => {
    return (
        <Dropdown drop={'down'} >
            <Dropdown.Toggle style={{ color: '#fff' }} variant="link" id="dropdown-basic">
                <RiAccountCircleFill size={26} />
            </Dropdown.Toggle>
            <Dropdown.Menu style={{ right: 0, left: 'auto' }} >
                {items.map((item, i) =>
                    <Dropdown.Item key={'account_item_' + i} style={{ fontWeight: item.link ? 'bold' : 'normal' }} onClick={item?.link ? (ev) => item.link(ev) : undefined}>{item.name}</Dropdown.Item>
                )}
            </Dropdown.Menu>
        </Dropdown>
    );
};


