import { CONSTANTS } from "@constants";
import FileUpload from "components/File";
import { Colors } from "constants/colors";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Accordion, ListGroup } from "react-bootstrap";
import { BiCheckDouble, BiCog } from "react-icons/bi";
import { get } from "utils/api";

export const CompanyDashboardSettings = () => {
  const [file, setFile] = useState();
  const [mounted, setMounted] = useState();
  const [activeKey, setActiveKey] = useState(-1);

  useEffect(() => {
    const apiRequests = async () => {
      await getFile();
    };
    if (mounted) return;
    setMounted(true);
    apiRequests();
  }, []);

  const Settings = ({ file }) => {
    const getUploadUrl = async (fileName, fileExtension) => {
      return await get(
        `/${CONSTANTS.namespaces.FLEET_COMPANIES
        }/get-import-upload-url/${fileName}/${fileExtension.replace(".", "")}`,
        {}
      );
    };

    return (
      <Accordion activeKey={activeKey} flush>
        <Accordion.Item eventKey="0">
          <Accordion.Header
            onClick={(e) => {
              setActiveKey(activeKey === -1 ? "0" : -1);
            }}
          >
            <BiCog style={{ marginRight: 10 }} />
            {t("importer")}
          </Accordion.Header>
          <Accordion.Body>
            <ListGroup>
              <ListItemWithHeading>
                {!file ? (
                  <FileUpload
                    file={file}
                    reload={getFile}
                    defaultFileName={"taskImport"}
                    getUploadUrl={getUploadUrl}
                  />
                ) : (
                  <>
                    <BiCheckDouble
                      color={Colors.primary}
                      size={30}
                      style={{ marginRight: 10 }}
                    />
                    {t("importing_file")}
                  </>
                )}
              </ListItemWithHeading>
            </ListGroup>
          </Accordion.Body>
        </Accordion.Item>
      </Accordion>
    );
  };

  const ListItemWithHeading = (props) => {
    return (
      <ListGroup.Item title="hello">
        <div className="ms-2 me-auto">
          <div className="mb-2">
            {
              <div className="fw-bold">
                {t("import_file_tasks")}{" "}
                <span className="text-danger">({t("only_utf_8")})</span>
              </div>
            }
          </div>
          {props.children}
        </div>
      </ListGroup.Item>
    );
  };

  const getFile = async () => {
    const res = await get(
      `/${CONSTANTS.namespaces.FLEET_COMPANIES}/get-import-file`
    );
    if (res) setFile(res);
  };

  return <Settings file={file} />;
};
