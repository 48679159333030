import { AdminSelectFleetCompany } from "components/AdminSelectFleetCompany";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectAdminChosenFleetCompanyId } from "store/pagesSlice";
import { selectIsAdmin } from "store/userSlice";
import { get } from "utils/api";
import { store } from "../../store";
import { setNavTitle } from "../../store/globalsSlice";
import { CompanyDashboard as Dashboard } from "../../stories/pages/CompanyDashboard";

export default function CompanyDashboard() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAdmin = useSelector(selectIsAdmin);
    const selectedFleetCompanyId = useSelector(selectAdminChosenFleetCompanyId);
    let mandatoryParams;

    useEffect(() => {
        dispatch(setNavTitle(t("company_dashboard")));
        return () => { };
    }, []);
    if (isAdmin && !selectedFleetCompanyId) {
        return (
            <AdminSelectFleetCompany />
        )
    }
    if (isAdmin && selectedFleetCompanyId) {
        mandatoryParams = { fleetCompanyId: selectedFleetCompanyId }
    }
    return (
        <>
            {isAdmin && <AdminSelectFleetCompany />}
            <Dashboard
                mandatoryParams={mandatoryParams}
                getHandler={async (path, init) => { return await get(path, init, [], store.dispatch) }}
                onShow={(url) => navigate(url)}
            />
        </>
    );
}