import ace from "brace";
import "brace/mode/json";
import "brace/theme/github";
import { JsonEditor } from "jsoneditor-react";
import "jsoneditor-react/es/editor.min.css";
import React, { useEffect, useState } from "react";
import "./JSONEditor.css";

const Editor = ({ value, onChangeFormData, formData, attr }) => {
  const [json, setJson] = useState();
  useEffect(() => {
    if (!value) return;
    try {
      setJson(value);
    } catch (e) {
      setJson(undefined);
    }
  }, [value]);
  return json ? (
    <JsonEditor
      mode={"code"}
      allowedModes={["code", "form", "text", "tree", "view"]}
      value={json}
      onChange={(val) => {
        let temp = { ...formData };
        temp[attr] = val;
        onChangeFormData(temp);
      }}
      ace={ace}
      theme="ace/theme/github"
    />
  ) : (
    <></>
  );
};
export default Editor;
