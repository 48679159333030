import { CONSTANTS } from "@constants";
import { Colors } from "constants/colors";
import dayjs from "dayjs";
import { t } from "i18next";
import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Badge from "react-bootstrap/esm/Badge";
import { BiLock, BiLockOpen } from "react-icons/bi";
import { BsFillEyeFill } from "react-icons/bs";
import { addListFilter, clearAndAddListFilter } from "store/pagesSlice";
import { store } from '../store';

export const assignPostTransformations = (collectionType, navigate, modal) => {
  const postTransformations = {
    createdAt: function (val) {
      return dayjs(val).format("DD.MM.YYYY  HH:mm:ss");
    },
    changedAt: function (val) {
      return dayjs(val).format("DD.MM.YYYY  HH:mm:ss");
    },
    reminderAt: function (val) {
      return dayjs(val).format("DD.MM.YYYY  HH:mm:ss");
    },
    plannedAt: function (val) {
      return dayjs(val).format("DD.MM.YYYY  HH:mm:ss");
    },
    reportedAt: function (val) {
      return dayjs(val).format("DD.MM.YYYY  HH:mm:ss");
    },
  };

  postTransformations.sortByValues = {
    createdAt: function (val) {
      return val;
    },
    changedAt: function (val) {
      return val;
    },
    reportedAt: function (val) {
      return val;
    },
  }
  postTransformations.allowedEmptyProps = ["lockedAt", "componentInstallationState", "isDemoUser"];

  if (collectionType === CONSTANTS.namespaces.USERS) {
    postTransformations.isDemoUser = function (val, item) {
      return val ? t('yes') : t('no')
    };
    postTransformations.cognitoInfos = function (val, item) {
      if (!val || val === "") return;
      return JSON.stringify(val)
    };
    postTransformations.attachedRoles = function (val, item) {
      if (!val || val === "") return;
      return val.map((role, i) => (
        <Badge key={"badge_roles" + i} role="button" bg="primary">
          {t(role)}
        </Badge>
      ));
    };
    postTransformations.maintenanceCompanyId = function (val, item) {
      if (!val || val === "") return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_maintenanceCompany_Id' + val.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_maintenanceCompany_Id' + val.id} {...props}>
                {t('tooltip_open_maintenance_company_item')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <span
            key={"link_maintenanceCompany_Id" + val.id}
            role="button"
            className="text-decoration-underline"
            onClick={() => {
              store.dispatch(clearAndAddListFilter({
                namespace: CONSTANTS.namespaces.MAINTENANCE_COMPANIES,
                filterSearchTerm: val.id,
                filterProperty: 'id',
                filterOperator: '=',
                destroyOnOnmount: true
              }));
              navigate("/list/maintenance-companies");
            }}
          >
            {val.maintenanceCompanyName}
          </span>
        </OverlayTrigger>
      );
    };
    postTransformations.fleetCompanyId = function (val, item) {
      if (!val || val === "") return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_fleet_companies_Id' + val.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_fleet_companies_Id' + val.id} {...props}>
                {t('tooltip_open_fleet_companies_item')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <span
            key={"link_fleetCompany_Id" + val.id}
            role="button"
            className="text-decoration-underline"
            onClick={() => {
              store.dispatch(clearAndAddListFilter({
                namespace: CONSTANTS.namespaces.FLEET_COMPANIES,
                filterSearchTerm: val.id,
                filterProperty: 'id',
                filterOperator: '=',
                destroyOnOnmount: true
              }));
              navigate("/list/fleet-companies");
            }}
          >
            {val.fleetCompanyName}
          </span>
        </OverlayTrigger>
      );
    };
    postTransformations.sortByValues = {
      ...postTransformations.sortByValues,
      attachedRoles: (val) => val?.[0] ? t(val[0]) : '',
      fleetCompanyId: (val) => val.fleetCompanyName,
      maintenanceCompanyId: (val) => val.maintenanceCompanyId,
    }
  }
  if (collectionType === CONSTANTS.namespaces.MAINTENANCE_COMPANIES) {
    postTransformations.fleetCompanyId = function (val, item) {
      if (!val || val === "" || val?.length === 0) return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_fleet_companies_Id' + val.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_fleet_companies_Id' + val.id} {...props}>
                {t('tooltip_open_fleet_companies_item')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <span
            key={"link_fleetCompanyId" + val.id}
            role="button"
            className="text-decoration-underline"
            onClick={() => {
              store.dispatch(clearAndAddListFilter({
                namespace: CONSTANTS.namespaces.FLEET_COMPANIES,
                filterSearchTerm: val.id,
                filterProperty: 'id',
                filterOperator: '=',
                destroyOnOnmount: true
              }));
              navigate("/list/fleet-companies");
            }}
          >
            {val.fleetCompanyName}
          </span>
        </OverlayTrigger>
      );
    };
    postTransformations.sortByValues = {
      ...postTransformations.sortByValues,
      fleetCompanyId: (val) => val.fleetCompanyName
    }
  }
  if (collectionType === CONSTANTS.namespaces.FLEETS) {
    postTransformations.lockedAt = function (val) {
      return val ?
        <BiLock color={Colors.danger} size={24} /> :
        <BiLockOpen color={Colors.success} size={24} />;
    };
    postTransformations.vehicles = function (val, item) {
      if (!val || val === "") return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_badge_vehicles' + item.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_badge_vehicles' + item.id} {...props}>
                {t('tooltip_show_vehicles')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <Badge
            key={"badge_vehicles" + item.id}
            role="button"
            onClick={() => {
              store.dispatch(clearAndAddListFilter({
                namespace: CONSTANTS.namespaces.VEHICLES,
                filterSearchTerm: item.fleetName,
                filterProperty: 'fleetId',
                filterOperator: '=',
                destroyOnOnmount: true
              }));
              navigate("/list/vehicles");
            }}
            bg="primary"
          >
            {val}
          </Badge>
        </OverlayTrigger>
      );
    };
    postTransformations["maintenance-tasks"] = function (val, item) {
      if (!val || val === "") return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_badge_maintenanceTasks' + item.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_badge_maintenanceTasks' + item.id} {...props}>
                {t('tooltip_show_open_maintenance_tasks')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <Badge
            key={"badge_maintenanceTasks" + item.id}
            role="button"
            onClick={() => {
              store.dispatch(clearAndAddListFilter({
                namespace: CONSTANTS.namespaces.MAINTENANCE_TASKS,
                filterSearchTerm: item.fleetName,
                filterProperty: 'fleetId',
                filterOperator: '=',
                destroyOnOnmount: true
              }));
              store.dispatch(addListFilter({
                filter: {
                  filterSearchTerm: CONSTANTS.STATUS.OPEN,
                  filterProperty: 'taskStatus',
                  filterOperator: '=',
                  destroyOnOnmount: true
                },
                namespace: CONSTANTS.namespaces.MAINTENANCE_TASKS,
              }));
              navigate("/list/maintenance-tasks");
            }}
            bg="primary"
          >
            {val}
          </Badge>
        </OverlayTrigger>
      );
    };
    postTransformations.maintenanceCompanyId = function (val, item) {
      if (!val || val === "") return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_maintenanceCompany_Id' + val.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_maintenanceCompany_Id' + val.id} {...props}>
                {t('tooltip_open_maintenance_company_item')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <span
            key={"link_maintenanceCompany_Id" + val.id}
            role="button"
            className="text-decoration-underline"
            onClick={() => {
              store.dispatch(clearAndAddListFilter({
                namespace: CONSTANTS.namespaces.MAINTENANCE_COMPANIES,
                filterSearchTerm: val.id,
                filterProperty: 'id',
                filterOperator: '=',
                destroyOnOnmount: true
              }));
              navigate("/list/maintenance-companies");
            }}
          >
            {val.maintenanceCompanyName}
          </span>
        </OverlayTrigger>
      );
    };

    postTransformations.sortByValues = {
      ...postTransformations.sortByValues,
      vehicles: (val) => val,
      "maintenance-tasks": (val) => {
        return val
      },
      maintenanceCompanyId: (val) => val.maintenanceCompanyName
    }
  }

  if (collectionType === CONSTANTS.namespaces.VEHICLES) {
    postTransformations.fleetId = function (val, item) {
      if (!val || val === "") return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_fleetId_vehicle' + val.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_fleetId_vehicle' + val.id} {...props}>
                {t('tooltip_open_fleets_item')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <span
            key={"link_fleetId" + val.id}
            role="button"
            className="text-decoration-underline"
            onClick={() => {
              navigate("/fleet-dashboard/" + val.id);
            }}
          >
            {val.fleetName}
          </span>
        </OverlayTrigger >
      );
    };
    postTransformations.sortByValues = {
      ...postTransformations.sortByValues,
      fleetId: (val) => val.fleetName
    }
  }

  if (collectionType === CONSTANTS.namespaces.COMPONENTS) {
    postTransformations.componentInstallationState = function (val, item) {
      return val === 'new' ? t('new') : t('used')
    }
    postTransformations.vehicleId = function (val, item) {
      if (!val || val === "") return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_vehicle_Id' + val.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_vehicle_Id' + val.id} {...props}>
                {t('tooltip_open_vehicles_item')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <span
            key={"link_vehicleId" + val.id}
            role="button"
            className="text-decoration-underline"
            onClick={() => {
              navigate("/vehicle-dashboard/" + val.id);
            }}
          >
            {val.serial}
          </span>
        </OverlayTrigger>
      );
    };
    postTransformations.sortByValues = {
      ...postTransformations.sortByValues,
      vehicleId: (val) => val.serial
    }
  }

  if (collectionType === CONSTANTS.namespaces.APP_LOGS) {
    postTransformations.appLogs = function (val, item) {
      if (!val || val === "" || val?.length === 0) return;
      let jsonPretty = val;
      try {
        jsonPretty = JSON.stringify(JSON.parse(val), null, 2);
      } catch (e) {
        console.warn(e);
        try { jsonPretty = JSON.stringify(val) } catch (e) { }
      }
      return (
        <OverlayTrigger
          key={'tooltip_overlay_appLogs' + item?.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_overlay_appLogs_tooltip' + item?.id} {...props}>
                {t('tooltip_show_logs')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <span
            key={"link_fleetCompanyId" + val.id}
            role="button"
            className="text-decoration-underline"
            onClick={() => {
              modal(
                <code style={{ whiteSpace: 'pre', fontFamily: 'monospace', overflow: 'scroll', display: 'block' }}>{jsonPretty}</code>,
                'logs',
                undefined,
                t("ok"),
                () => { },
                () => { }
              );
            }}
          >
            <BsFillEyeFill size={20} />
          </span>
        </OverlayTrigger>)
    }
    postTransformations.createdBy = function (val, item) {
      if (!val || val === "") return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_created_by' + val.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_created_by' + val.id} {...props}>
                {t('tooltip_open_users_item')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <span
            key={"link_created_by" + val.id}
            role="button"
            className="text-decoration-underline"
            onClick={() => {
              navigate("/edit/users/" + val.id + "?action=edit");
            }}
          >
            {val.userEmail}
          </span>
        </OverlayTrigger>
      );
    };
    postTransformations.sortByValues = {
      ...postTransformations.sortByValues,
      createdBy: (val) => val.userEmail
    }
  }

  if (collectionType === CONSTANTS.namespaces.MAINTENANCE_TASKS) {
    postTransformations.componentInstallationState = function (val, item) {
      if (item?.taskAction !== CONSTANTS.ACTIONS.REPLACED) return;
      return val === 'new' ? t('new') : t('used')
    }
    postTransformations.vehicleId = function (val, item) {
      if (!val || val === "") return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_vehicle_Id' + val.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_vehicle_Id' + val.id} {...props}>
                {t('tooltip_open_vehicles_item')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <span
            key={"link_vehicleId" + val.id}
            role="button"
            className="text-decoration-underline"
            onClick={() => {
              navigate("/vehicle-dashboard/" + val.id);
            }}
          >
            {val.serial}
          </span>
        </OverlayTrigger>
      );
    };
    postTransformations.componentId = function (val, item) {
      if (!val || val === "") return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_componentId' + val.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_componentId' + val.id} {...props}>
                {t('tooltip_open_components_item')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <span
            key={"link_componentId" + val.id}
            role="button"
            className="text-decoration-underline"
            onClick={() => {
              navigate(`/edit/components/${val.id}?action=edit`);
            }}
          >
            {t(val.componentName)}
          </span>
        </OverlayTrigger>
      );
    };
    postTransformations.fleetId = function (val, item) {
      if (!val || val === "") return;
      return (
        <OverlayTrigger
          key={'tooltip_overlay_fleetId' + val.id}
          placement="auto"
          overlay={(props) => {
            return (
              <Tooltip id={'tooltip_fleetId' + val.id} {...props}>
                {t('tooltip_open_fleets_item')}
              </Tooltip>
            )
          }}
          delay={{ show: 250, hide: 400 }
          }
        >
          <span
            key={"link_fleetId" + val.id}
            role="button"
            className="text-decoration-underline"
            onClick={() => {
              navigate(`/fleet-dashboard/${val.id}`);
            }}
          >
            {t(val.fleetName)}
          </span>
        </OverlayTrigger>
      );
    };
    postTransformations.sortByValues = {
      ...postTransformations.sortByValues,
      vehicleId: (val) => val?.serial,
      componentId: (val) => t(val?.componentName),
      fleetId: (val) => val?.fleetName
    }
  }
  return postTransformations;
};
export const assignPdfTransformations = (collectionType) => {
  const postTransformations = {
    createdAt: function (val) {
      return dayjs(val).format("DD.MM.YYYY  HH:mm:ss");
    },
    changedAt: function (val) {
      return dayjs(val).format("DD.MM.YYYY  HH:mm:ss");
    },
    reportedAt: function (val) {
      return dayjs(val).format("DD.MM.YYYY  HH:mm:ss");
    },
    plannedAt: function (val) {
      return dayjs(val).format("DD.MM.YYYY  HH:mm:ss");
    },
  };

  if (collectionType === CONSTANTS.namespaces.USERS) {
    postTransformations.attachedRoles = function (val) {
      if (!val || val === "") return;
      return val.map((role, i) => t(role));
    };
    postTransformations.maintenanceCompanyId = function (val) {
      if (!val || val === "") return;
      return val.maintenanceCompanyName;

    };
    postTransformations.fleetCompanyId = function (val) {
      if (!val || val === "") return;
      return val.fleetCompanyName
    };
  }
  if (collectionType === CONSTANTS.namespaces.MAINTENANCE_COMPANIES) {
    postTransformations.fleetCompanyId = function (val) {
      if (!val || val === "" || val?.length === 0) return;
      return val.fleetCompanyName
    };
  }
  if (collectionType === CONSTANTS.namespaces.FLEETS) {
    postTransformations.vehicles = function (val) {
      if (!val || val === "") return;
      return val;
    };
    postTransformations["maintenance-tasks"] = function (val) {
      if (!val || val === "") return;
      return val;
    };
    postTransformations.maintenanceCompanyId = function (val) {
      if (!val || val === "") return;
      return val.maintenanceCompanyName
    };
  }

  if (collectionType === CONSTANTS.namespaces.VEHICLES) {
    postTransformations.fleetId = function (val) {
      if (!val || val === "") return;
      return val.fleetName;
    };
  }

  if (collectionType === CONSTANTS.namespaces.COMPONENTS) {
    postTransformations.vehicleId = function (val) {
      if (!val || val === "") return;
      return val.serial;
    };
    postTransformations.componentInstallationState = function (val) {
      return val?.componentInstallationState === 'new' ? t('new') : t('used')
    }
  }

  if (collectionType === CONSTANTS.namespaces.MAINTENANCE_TASKS) {
    postTransformations.vehicleId = function (val) {
      if (!val || val === "") return;
      return val.serial;
    };
    postTransformations.componentId = function (val) {
      if (!val || val === "") return;
      return t(val.componentName);
    };
    postTransformations.fleetId = function (val) {
      if (!val || val === "") return;
      return val.fleetName;
    };
    postTransformations.componentInstallationState = function (val) {
      if (val?.taskAction !== CONSTANTS.ACTIONS.REPLACED) return;
      return val?.componentInstallationState === 'new' ? t('new') : t('used')
    }
  }
  return postTransformations;
};

export const assignComputedHeads = (collectionType, isAdmin) => {
  let comProps = [];
  if (collectionType === CONSTANTS.namespaces.USERS && isAdmin) {
    comProps.push("cognitoInfos");
  }

  return comProps;
};
