import { CONSTANTS } from "@constants";
import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { get } from "utils/api";
import { SelectField } from "../../../../stories/crud/FieldTypes/SelectField";

export const SelectComponentCategory = (props) => {
  const {
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    value,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData,
    relatedResources,
  } = props;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getOptions = async () => {
      const col = { ...collectionData };
      setLoading(true);
      try {
        const dataset = (
          await get(
            `/${CONSTANTS.namespaces.DATASETS}/components`,
            { queryParams: { vehicleId: formData.vehicleId } }
          )
        )?.datasets;
        col.componentCategory.options = [
          ...new Set(
            dataset
              .map((o) => o.component_category)
              .filter((o) =>
                dataset.find(
                  (d) =>
                    d.component_category === o &&
                    d.component_type === formData.taskCategory
                )
              )
          ),
        ]
          .sort((a, b) => (t(a) < t(b) ? -1 : 1))
          .map((c) => ({ label: t(c), value: c }));
        onChangeCollectionData(col);
      } finally {
        setLoading(false);
      }
    };

    if (
      formData.taskCategory !== "mechanical" &&
      formData.taskCategory !== "electrical"
    )
      return;
    getOptions();
  }, [formData.taskCategory, formData.vehicleId]);
  return <SelectField {...props} loading={loading} />;
};
SelectComponentCategory.propTypes = {
  type: PropTypes.string,
  action: PropTypes.string,
  itemId: PropTypes.any,
  preDefinedProperties: PropTypes.any,
  schemesHandler: PropTypes.func,
  postHandler: PropTypes.func,
  patchHandler: PropTypes.func,
  backHandler: PropTypes.func,
  hidden: PropTypes.array,
  getHandler: PropTypes.func,
  attr: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
  formData: PropTypes.object,
  collectionData: PropTypes.object,
  htmlSchemes: PropTypes.object,
  onChangeFormData: PropTypes.func,
};
