// @ts-nocheck
/* eslint-disable no-new-func */
import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useCallback, useEffect, useState } from "react";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import "../../assets/css/themes/global-override.css";
import "../../assets/css/themes/index.scss";
import { LoadingWrapper } from "../LoadingWrapper";
import { Toast } from "../Toast";
import { generateUUID, isEmpty } from "../utils/common";

import { HistoryModal } from "components/HistoryModal";
import useModal from "components/modal/hook";
import { Colors } from "constants/colors";
import { BiHistory, BiTrash } from "react-icons/bi";
import { useNavigate } from "react-router";
import { AbortManager } from "utils/AbortManager";
import { API, get } from "utils/api";
import Editor from "../../components/JSONEditor";
import { sortByArray } from "../../utils/general";
import { CheckboxField } from "./FieldTypes/CheckboxField";
import { DateField } from "./FieldTypes/DateField";
import { FilesField } from "./FieldTypes/FilesField";
import { MultiselectField } from "./FieldTypes/MultiselectField";
import { SelectField } from "./FieldTypes/SelectField";
import { TextField } from "./FieldTypes/TextField";
import { TextareaField } from "./FieldTypes/TextareaField";

export const EditAndCreate = (props) => {
  const {
    isAdmin,
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    deleteHandler,
    hidden,
    getHandler,
    fieldOverwrites,
    sortBy = [],
    beforeSubmit,
    submitCallback,
    saveDisabled,
    showDelete = true,
    showHistory,
  } = props;
  const [formData, setFormData] = useState({});
  const [isSaveDisabled, setIsSaveDisabled] = useState(false);
  const [loading, setLoading] = useState(true);
  const [showToast, setShowToast] = useState();
  const [saveSuccess, setSaveSuccess] = useState(null);
  const [collectionData, setCollectionData] = useState();
  const [htmlSchemes, setHtmlSchemes] = useState();
  const listId = `${type}_edit_create_${new Date().toISOString()}`;
  const abortManager = { id: listId, abortManager: AbortManager };
  const [relatedResources, setRelatedResources] = useState();
  const { modal } = useModal();
  const navigate = useNavigate();

  const replaceEmptyWithDefault = (data, props, scheme) => {
    const requiredProps = Object.keys(scheme);
    const temp = { ...data };
    for (let n in requiredProps) {
      const i = requiredProps[n];
      const val = data?.[i];
      if (!props?.[i]) continue;
      if (
        !isEmpty(props[i]) &&
        (val === "" || typeof val === "undefined" || val === null)
      ) {
        temp[i] = props[i];
      }
    }
    return temp;
  };
  const getItem = useCallback(async () => {
    let properties = {};
    try {
      const res = await get(`/${type}/properties`, {});
      properties = res?.properties;
    } catch (e) {
      console.error("fetch properties failed", e);
    }
    try {
      let response = await schemesHandler({ abortManager });
      setHtmlSchemes(response);
      for (let r in response) {
        if (response[r].resource) {
          const res = response[r].resource;
          if (!res.isFunction) {
            const items = await API[res?.method](res?.path, {
              abortManager
            });
            if (!relatedResources?.[res.collection])
              setRelatedResources({ [res?.collection]: items[res.collection] });
            response[r].options = res?.map?.groupBy
              ? Object.values(
                items[res.collection].reduce(
                  (acc, item) => ({
                    ...acc,
                    [item[res.map.groupBy]]: {
                      label: t(item[res.map.groupBy]),
                      options: [
                        ...(acc[item[res.map.groupBy]]?.options || []),
                        {
                          label: t(item[res?.map?.label]),
                          value: item[res?.map?.value],
                        },
                      ],
                    },
                  }),
                  {}
                )
              )
              : items[res.collection]?.map((c) => ({
                label: c[res?.map?.label],
                value: c[res?.map?.value],
              }));

            items[res.collection]?.map((c) => ({
              label: c[res?.map?.label],
              value: c[res?.map?.value],
            }));
          }
        }
      }

      if (action === "create") {
        let temp = {};
        let preDefProps;
        if (preDefinedProperties)
          try {
            preDefProps = JSON.parse(preDefinedProperties);
          } catch { }
        for (let i in response) {
          if (preDefProps && preDefProps[i]) temp[i] = preDefProps[i];
          else temp[i] = response[i]?.value || "";
        }
        setCollectionData(response);
        setFormData(replaceEmptyWithDefault(temp, properties, response));

        setLoading(false);
      } else {
        let res = await getHandler(itemId, { abortManager });
        if (saveDisabled) {
          setIsSaveDisabled(saveDisabled(res[type]));
        }
        setCollectionData(response);
        setFormData(replaceEmptyWithDefault(res[type], properties, response));
      }
    } finally {
      setLoading(false);
    }
  }, [
    schemesHandler,
    action,
    preDefinedProperties,
    getHandler,
    itemId,
    type,
  ]);

  useEffect(() => {
    getItem();
    return () => {
      abortManager.abortManager.cancel(listId);
    };
  }, []);

  async function handleSubmit(event) {
    event.preventDefault();
    const { id, files, ...data } = formData;
    if (formData?.fleetId) {
      const fleet = (await get(`/fleets/${formData.fleetId}`))?.fleets;
      if (fleet?.lockedAt) {
        modal(
          <p>{t("fleet_is_locked_description")}</p>,
          t("fleet_is_locked"),
          t("close"),
          undefined,
          () => { },
          () => { }
        );
        return;
      }
    }
    try {
      if (beforeSubmit) await beforeSubmit(itemId, formData);
    } catch (e) {
      setSaveSuccess(false);
      setShowToast(true);
      return;
    }
    setLoading(true);
    //exclude id from data
    let successful = false;
    let res;
    try {
      if (action === "edit") {
        res = await patchHandler(
          itemId,
          { ...data },
          { abortManager }
        );
      } else {
        res = await postHandler({ ...data }, { abortManager })
          ?.id;
      }
      successful = true;
      setSaveSuccess(successful);
    } catch (e) {
      console.error(e);
      successful = false;
      setSaveSuccess(successful);
      setLoading(false);
    } finally {
      setShowToast(true);
      console.log('successful Edit', successful);
      if (successful === true)
        setTimeout(() => {
          setLoading(false);
          if (submitCallback) {
            submitCallback(res);
          } else {
            backHandler();
          }
        }, 1500);
    }
  }

  function onChangeFormData(data) {
    setFormData({ ...formData, ...data });
  }
  function onChangeCollectionData(data) {
    setCollectionData({ ...collectionData, ...data });
  }
  async function handleDelete() {
    if (window.confirm(t("delete_confirmation")) == false) return;
    await deleteHandler(itemId, { abortManager });
    backHandler();
  }

  const showHistoryModal = (id) => {
    modal(
      <HistoryModal id={id} navigate={navigate} />,
      t("item_history"),
      t("close"),
      undefined,
      () => { },
      () => { }
    );
  }

  return (
    <>
      {action === "edit" && (showDelete || showHistory) && (
        <Row className="justify-content-md-center">
          <Col xs={12} md={10} key="rowAction" className="pt-3 d-flex justify-content-start">
            {showDelete && <div>
              <Button
                key={"fleet_dash_button_edit"}
                onClick={() => handleDelete()}
                variant="link"
                style={{
                  textDecoration: "none",
                  paddingLeft: 0,
                  color: Colors.danger,
                }}
              >
                <BiTrash size={20} />
              </Button>
            </div>}
            {showHistory && <div>
              <Button
                key={"fleet_dash_button_edit"}
                onClick={() => showHistoryModal(itemId)}
                variant="link"
                style={{
                  textDecoration: "none",
                  paddingLeft: 0,
                  color: Colors.primary,
                }}
              >
                <BiHistory size={20} />
              </Button>
            </div>}
          </Col>
        </Row>
      )}
      <Form key={"form_edit_and_create_" + type} onSubmit={handleSubmit}>
        <LoadingWrapper loading={loading} />
        <Row key="row" className="pt-3 justify-content-md-center">
          <Form.Group as={Col} xs={12} md={10}>
            {collectionData &&
              sortByArray(Object.keys(collectionData), sortBy).map(function (
                key,
                index
              ) {
                if (hidden?.includes(key)) return null;
                if (!collectionData[key]) return null;
                if (collectionData[key].type === "select")
                  return fieldOverwrites?.select ? (
                    <fieldOverwrites.select
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      formData={formData}
                      key={"selectField_" + key + type}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  ) : (
                    <SelectField
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      key={"selectField_" + key + type}
                      formData={formData}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  );

                if (collectionData[key].type === "multiselect")
                  return fieldOverwrites?.multiselect ? (
                    <fieldOverwrites.multiselect
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      formData={formData}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      key={"multiSelectField_" + key + type}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  ) : (
                    <MultiselectField
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      key={"multiSelectField_" + key + type}
                      formData={formData}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  );
                if (collectionData[key].type === "date")
                  return fieldOverwrites?.date ? (
                    <fieldOverwrites.date
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      formData={formData}
                      key={"dateField_" + key + type}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  ) : (
                    <DateField
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      formData={formData}
                      key={"dateField_" + key + type}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  );
                if (collectionData[key].type === "checkbox")
                  return fieldOverwrites?.checkbox ? (
                    <fieldOverwrites.checkbox
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      formData={formData}
                      key={"dateField_" + key + type}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  ) : (
                    <CheckboxField
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      formData={formData}
                      key={"dateField_" + key + type}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  );
                if (collectionData[key].type === "textarea")
                  return fieldOverwrites?.textarea ? (
                    <fieldOverwrites.textarea
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      formData={formData}
                      key={"textareaField_" + key + type}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  ) : (
                    <TextareaField
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      key={"textareaField_" + key + type}
                      formData={formData}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  );
                if (collectionData[key].type === "json")
                  return fieldOverwrites?.json ? (
                    <fieldOverwrites.json
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      formData={formData}
                      key={"jsonField_" + key + type}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  ) : (
                    <Editor
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      key={"jsonField_" + key + type}
                      formData={formData}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      onChangeCollectionData={onChangeCollectionData}
                      relatedResources={relatedResources}
                      isAdmin={isAdmin}
                    />
                  );
                if (collectionData[key].type === "files")
                  return fieldOverwrites?.files ? (
                    <fieldOverwrites.files
                      {...props}
                      attr={key}
                      value={formData[key]}
                      index={index}
                      key={"filesField_" + key + type}
                      formData={formData}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      relatedResources={relatedResources}
                      onChangeCollectionData={onChangeCollectionData}
                      isAdmin={isAdmin}
                    />
                  ) : (
                    <FilesField
                      {...props}
                      attr={key}
                      key={"filesField_" + key + type}
                      value={formData[key]}
                      index={index}
                      formData={formData}
                      collectionData={collectionData}
                      htmlSchemes={htmlSchemes}
                      onChangeFormData={onChangeFormData}
                      relatedResources={relatedResources}
                      onChangeCollectionData={onChangeCollectionData}
                      isAdmin={isAdmin}
                    />
                  );

                return fieldOverwrites?.text ? (
                  <fieldOverwrites.text
                    {...props}
                    key={"textField_" + key + type}
                    attr={key}
                    value={formData[key]}
                    index={index}
                    formData={formData}
                    collectionData={collectionData}
                    htmlSchemes={htmlSchemes}
                    onChangeFormData={onChangeFormData}
                    relatedResources={relatedResources}
                    onChangeCollectionData={onChangeCollectionData}
                    isAdmin={isAdmin}
                  />
                ) : (
                  <TextField
                    {...props}
                    key={"textField_" + key + type}
                    attr={key}
                    value={formData[key]}
                    index={index}
                    formData={formData}
                    collectionData={collectionData}
                    htmlSchemes={htmlSchemes}
                    onChangeFormData={onChangeFormData}
                    relatedResources={relatedResources}
                    onChangeCollectionData={onChangeCollectionData}
                    isAdmin={isAdmin}
                  />
                );
              })}
            {!loading && (
              <Button
                className="mx-4 mb-3 ms-0"
                size="default"
                type="submit"
                disabled={!isSaveDisabled ? loading || false : true}
              >
                {t("save")}
              </Button>
            )}
            <Toast
              id={generateUUID()}
              show={showToast}
              onClose={() => {
                setShowToast(false);
              }}
              variant={saveSuccess ? "success" : "danger"}
              message={saveSuccess ? t("success") : t("error")}
              position={"bottom-center"}
            />
          </Form.Group>
        </Row>
      </Form>
    </>
  );
};

EditAndCreate.propTypes = {
  itemId: PropTypes.string,
  type: PropTypes.string,
  navTitle: PropTypes.string,
  action: PropTypes.string,
  preDefinedProperties: PropTypes.any,
  fieldOverwrites: PropTypes.object,
  sortBy: PropTypes.array,
  beforeSubmit: PropTypes.func,
  submitCallback: PropTypes.func,
  saveDisabled: PropTypes.func,
  showDelete: PropTypes.bool,
};

EditAndCreate.defaultProps = {
  itemId: "0",
  type: "vehicles",
  navTitle: "vehicle",
  action: "Edit",
};
