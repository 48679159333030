import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import i18n from "../i18n";

const initialState = {
  lang: 'de',
  languages:i18n.languages
};

const settingsSlice = createSlice({
  name: "settings",
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(setLang.fulfilled, (state, action) => {
      state.lang = action.payload;
    });
    builder.addCase(setLang.rejected, (state, action) => {
      state.lang = 'de';
    });
   
  },
});

export const setLang = createAsyncThunk("settings/setLang", async (args) => {
  await i18n.changeLanguage(args);
  return args;
});


export const settingsReducer = settingsSlice.reducer;
export const settingsActions = settingsSlice.actions;
