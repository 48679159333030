import { CONSTANTS } from "@constants";
import AnimatedCounter from "components/AnimatedCounter";
import DateRangeFilter from "components/DateRangePicker";
import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useRef, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router";
import { CollectionList } from "stories/pages/CollectionList";
import { isEmpty } from "stories/utils/common";
import { AbortManager } from "utils/AbortManager";
import { get } from "utils/api";
import { assignPostTransformations } from "utils/postTransformations";

export default function StatisticsDashboard({ mandatoryParams }) {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const fleetCompanyId = mandatoryParams.fleetCompanyId;
    const listId = `StatisticsDashboard_${new Date().toISOString()}`;
    const abortManager = { id: listId, abortManager: AbortManager };
    const [loading, setLoading] = useState(true);
    const [vehiclesWithoutUVV, setVehiclesWithoutUVV] = useState([]);
    const [vehicles, setVehicles] = useState([]);
    const [vehiclesWithoutInspections, setVehiclesWithoutInspections] = useState([]);
    const [chosenStatistic, setChosenStatistic] = useState();
    const [dates, setDates] = useState({});
    const [mounted, setMounted] = useState(false);
    const datesRef = useRef(dates);

    const getStatistics = async () => {
        setLoading(true);
        const dateVal = datesRef.current;
        const vehicles = (await get(`/${CONSTANTS.namespaces.VEHICLES}`, {
            abortManager,
            queryParams: { fleetCompanyId, createdAt: `_between(${dateVal.start},${dateVal.end})` }
        }))?.vehicles;
        const tasks = (await get(`/${CONSTANTS.namespaces.MAINTENANCE_TASKS}`, {
            abortManager,
            queryParams: {
                fleetCompanyId,
                taskStatus: CONSTANTS.STATUS.CLOSED,
                taskCategory: `_in(${CONSTANTS.CATEGORIES.UVV},${CONSTANTS.CATEGORIES.INSPECTION})`
            }
        }))?.['maintenance-tasks'];

        setVehicles(vehicles);
        setVehiclesWithoutUVV(
            vehicles.filter(v => !tasks?.find(t =>
                t.vehicleId === v.id &&
                t.taskCategory === CONSTANTS.CATEGORIES.UVV))
        );
        setVehiclesWithoutInspections(
            vehicles.filter(v => !tasks?.find(t =>
                t.vehicleId === v.id &&
                t.taskCategory === CONSTANTS.CATEGORIES.INSPECTION))
        );

        setLoading(false);
    }

    useEffect(() => {
        if (!fleetCompanyId || mounted) return;
        const day = dayjs();
        const initialDates = {
            start: day.subtract(1, 'year').format('YYYY-MM-DD'),
            end: day.add(1, 'day').format('YYYY-MM-DD')
        }
        setDates(initialDates);
        datesRef.current = initialDates;
        getStatistics();
        setMounted(true);
    }, []);

    useEffect(() => {
        if (!mounted) return;
        setChosenStatistic();
        datesRef.current = dates;
        getStatistics();
    }, [dates]);
    useEffect(() => {
        if (!mounted) return;
        setChosenStatistic();
        setVehicles([]);
        setVehiclesWithoutInspections([]);
        setVehiclesWithoutUVV([]);
        getStatistics();
    }, [mandatoryParams]);

    const handleOnShow = (id) => {
        if (id) navigate(`/vehicle-dashboard/${id}`)
    }

    return (
        <Container>
            <Row className="g-2 mb-3 d-flex justify-content-center align-items-center">
                {!loading && mounted && <DateRangeFilter
                    defaultStartDate={dates?.start}
                    defaultEndDate={dates?.end}
                    onDateRangeSelected={(start, end) => {
                        if (isEmpty(start) || isEmpty(end)) {
                            setDates();
                            return;
                        }
                        setDates({ start, end })
                    }}
                />}
            </Row>
            <Row>
                <Col>
                    <AnimatedCounter
                        onClick={() => setChosenStatistic(vehiclesWithoutUVV)}
                        max={vehicles.length}
                        loading={loading}
                        count={vehiclesWithoutUVV.length}
                        label={t('vehicles_without_uvv')} />
                </Col>
                <Col>
                    <AnimatedCounter
                        onClick={() => setChosenStatistic(vehiclesWithoutInspections)}
                        max={vehicles.length}
                        loading={loading}
                        count={vehiclesWithoutInspections.length}
                        label={t('vehicles_without_inspection')} />
                </Col>
            </Row>
            <Row>
                {chosenStatistic?.length > 0 && !loading ? <CollectionList
                    showProgress={false}
                    mandatoryParams={mandatoryParams}
                    showExpand={true}
                    recognizeFilter={false}
                    tableRowClasses={(row) => {
                        switch (row?.['vehicleStatus']) {
                            case "maintenance":
                                return "border-start border-start-4 border-info";
                            case "defect":
                                return "border-start border-start-4 border-danger";
                            case "operational":
                                return "border-start border-start-4 border-success";
                            case "retired":
                                return "border-start border-start-4 border-dark";
                            case "low_battery":
                                return "border-start border-start-4 border-warning";
                            default:
                                return "";
                        }
                    }}
                    tableCellClasses={"p-2 fs-6"}
                    tableClasses={" px-2 overflow-scroll w-100 minh-25 maxh-50 mt-3"}
                    schemeNamePlural={CONSTANTS.namespaces.VEHICLES}
                    include={[
                        "serial",
                        "vehicleStatus",
                        "fleetId",
                        "vehicleName",
                        "changedAt",
                        "createdAt",
                    ]}
                    staticData={chosenStatistic}
                    getHandler={async (path, init) => {
                        return await get(
                            `/${CONSTANTS.namespaces.VEHICLES}${path || ''}`,
                            init
                        );
                    }}
                    postTransformations={
                        assignPostTransformations(
                            CONSTANTS.namespaces.VEHICLES,
                            navigate
                        )
                    }
                    defaultSortBy={{ fleetId: 'asc' }}
                    exclude={undefined}
                    showCheckboxes={false}
                    showClone={false}
                    showImporter={false}
                    showDelete={false}
                    showCreate={false}
                    showEdit={false}
                    showFilter={false}
                    showSort={false}
                    onClickShow={handleOnShow}
                /> : <></>}
            </Row>
        </Container>


    )


}