import { CONSTANTS } from '@constants';
import DateRangeFilter from 'components/DateRangePicker';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import Dropdown from "react-bootstrap/Dropdown";
import DropdownButton from "react-bootstrap/DropdownButton";
import Form from "react-bootstrap/Form";
import InputGroup from "react-bootstrap/InputGroup";
import { MdOutlineCancel } from "react-icons/md";
import { usePrevious } from './utils/hooks';


export const Filter = ({ searchProperty, defaultOperator, filterId, value = '', head, delay = undefined, onFilter, onLoadingChange, disabled = false }) => {

    const operators = [
        { value: '_contains', label: 'contains' },
        { value: '_not_contains', label: 'not_contains' },
        { value: '=', label: 'is_equal' },
        { value: '<>', label: 'unequal' },
        { value: '>', label: 'greater_than' },
        { value: '<', label: 'less_than' },
        { value: '_not_exists', label: 'not_exists' },
        { value: '_exists', label: 'exists' },
        { value: '_begins_with', label: 'begins_with' },
        { value: '_between', label: 'between' },
        { value: '_is_true', label: 'is_true' },
        { value: '_is_false', label: 'is_false' },
    ]
    const op = operators.find((o) => defaultOperator === o.value)
    const [searchTerm, setSearchTerm] = useState(value);
    const [property, setProperty] = useState(searchProperty);
    const [mounted, setMounted] = useState(false);
    const [operator, setOperator] = useState(op || operators[1]);
    const prevSearchTerm = usePrevious(searchTerm);
    const prevOperator = usePrevious(operator);
    const prevProperty = usePrevious(property);

    const preDefinedProperties = [
        {
            name: "componentStatus", options: [
                { value: CONSTANTS.STATUS.OPERATIONAL, label: t(CONSTANTS.STATUS.OPERATIONAL) },
                { value: CONSTANTS.STATUS.MAINTENANCE, label: t(CONSTANTS.STATUS.MAINTENANCE) },
                { value: CONSTANTS.STATUS.DEFECT, label: t(CONSTANTS.STATUS.DEFECT) },
                { value: CONSTANTS.STATUS.RETIRED, label: t(CONSTANTS.STATUS.RETIRED) },
                { value: CONSTANTS.STATUS.REMOVED, label: t(CONSTANTS.STATUS.REMOVED) },
            ]
        },
        {
            name: "componentType",
            options: [
                { value: CONSTANTS.CATEGORIES.ELECTRICAL, label: t(CONSTANTS.CATEGORIES.ELECTRICAL) },
                { value: CONSTANTS.CATEGORIES.MECHANICAL, label: t(CONSTANTS.CATEGORIES.MECHANICAL) },
            ]
        },
        {
            name: "taskCategory", options: [
                { value: CONSTANTS.CATEGORIES.ELECTRICAL, label: t(CONSTANTS.CATEGORIES.ELECTRICAL) },
                { value: CONSTANTS.CATEGORIES.MECHANICAL, label: t(CONSTANTS.CATEGORIES.MECHANICAL) },
                { value: CONSTANTS.CATEGORIES.UVV, label: t(CONSTANTS.CATEGORIES.UVV) },
                { value: CONSTANTS.CATEGORIES.INSPECTION, label: t(CONSTANTS.CATEGORIES.INSPECTION) },
            ]
        },
        {
            name: "taskStatus", options: [
                { value: CONSTANTS.STATUS.OPEN, label: t(CONSTANTS.STATUS.OPEN) },
                { value: CONSTANTS.STATUS.PLANNED, label: t(CONSTANTS.STATUS.PLANNED) },
                { value: CONSTANTS.STATUS.IN_PROGRESS, label: t(CONSTANTS.STATUS.IN_PROGRESS) },
                { value: CONSTANTS.STATUS.CLOSED, label: t(CONSTANTS.STATUS.CLOSED) },
            ]
        },
        {
            name: "taskAction", options: [
                { value: CONSTANTS.ACTIONS.REPAIRED, label: t(CONSTANTS.ACTIONS.REPAIRED) },
                { value: CONSTANTS.ACTIONS.REPLACED, label: t(CONSTANTS.ACTIONS.REPLACED) },
                { value: CONSTANTS.ACTIONS.NO_ACTION, label: t(CONSTANTS.ACTIONS.NO_ACTION) },
                { value: CONSTANTS.ACTIONS.VEHICLE_NOT_PRESENT, label: t(CONSTANTS.ACTIONS.VEHICLE_NOT_PRESENT) },
            ]
        },
        {
            name: "vehicleStatus", options: [
                { value: CONSTANTS.STATUS.OPERATIONAL, label: t(CONSTANTS.STATUS.OPERATIONAL) },
                { value: CONSTANTS.STATUS.MAINTENANCE, label: t(CONSTANTS.STATUS.MAINTENANCE) },
                { value: CONSTANTS.STATUS.DEFECT, label: t(CONSTANTS.STATUS.DEFECT) },
                { value: CONSTANTS.STATUS.INACTIVE, label: t(CONSTANTS.STATUS.INACTIVE) },
            ]
        },
        {
            name: "vehicleType", options: [
                { value: CONSTANTS.TYPES.MECHANICAL, label: t(CONSTANTS.TYPES.MECHANICAL) },
                { value: CONSTANTS.TYPES.ELECTRICAL, label: t(CONSTANTS.TYPES.ELECTRICAL) },

            ]
        },
        {
            name: "componentInstallationState", options: [
                { value: 'new', label: t('new') },
                { value: 'used', label: t('used') },

            ]
        }
    ]



    useEffect(() => {
        setMounted(true)
    }, []);

    useEffect(() => {
        if (!mounted) return;
        if (searchTerm === prevSearchTerm) return;
        try {
            onFilter(property?.trim(), searchTerm, operator?.value);
        } catch (e) {
            onLoadingChange(false);
        }
    }, [searchTerm])

    useEffect(() => {
        if (!mounted) return;
        if (operator === prevOperator) return;
        setSearchTerm('');
        onFilter(property?.trim(), '', operator?.value);
    }, [operator])

    useEffect(() => {
        if (!mounted) return;
        if (property === prevProperty) return;
        setSearchTerm('')
        onFilter(property?.trim(), searchTerm, operator?.value);
    }, [property])

    const filter = async (filterVal) => {
        setSearchTerm(filterVal);
    }

    return (
        <InputGroup size='sm' key={"inp_group" + filterId} className='ms-4 input-group-Filter w-100'>
            <DropdownButton
                size='sm'
                disabled={disabled}
                key={"drop_down_btn" + filterId}
                variant="outline-primary"
                title={t(property) || t('choose')}
                id="input-group-dropdown-Filter-1"
                onSelect={(e) => {
                    setProperty(e)
                }}
            >

                {head.map((h, i) => (
                    <Dropdown.Item
                        eventKey={h}
                        key={'filter_dropdown_item_' + filterId + i}>
                        {t(h)}
                    </Dropdown.Item>
                ))}

            </DropdownButton>
            {property && <DropdownButton
                size='sm'
                className='border-start-0'
                disabled={disabled}
                variant="outline-primary"
                title={operator?.label ? t(operator.label) : t('choose')}
                id="input-group-dropdown-Filter-2"
                key={"drop_down_btn_2" + filterId}
                onSelect={(e) => {
                    setOperator(operators.find((o) => o.value === e))
                }}
            >

                {operators.filter(o => {
                    if (property === 'createdAt' || property === 'changedAt' || property === 'reportedAt') {
                        return o.label === 'between' ||
                            o.label === 'is_equal' ||
                            o.label === 'exists' ||
                            o.label === 'not_exists'
                    }
                    if (preDefinedProperties.find(p => p.name === property)) {
                        if (property === 'componentInstallationState') return o.label === 'is_equal' || o.label === 'unequal';
                        return o.label === 'is_equal' ||
                            o.label === 'unequal' ||
                            o.label === 'contains' ||
                            o.label === 'not_contains'
                    }
                    return o.label !== 'between';
                }).map((h, i) => (
                    <Dropdown.Item
                        eventKey={h.value}
                        key={'filter_operator_dropdown_item_' + filterId + i}>
                        {t(h.label)}
                    </Dropdown.Item>
                ))}

            </DropdownButton>}
            {
                operator.label !== 'between' ?
                    preDefinedProperties.find(p => p.name === property) ?
                        <DropdownButton
                            size='sm'
                            className='border-start-0'
                            disabled={disabled}
                            variant="outline-primary"
                            title={
                                preDefinedProperties.find(p => p.name === property)
                                    ?.options
                                    ?.find(o => o.value === searchTerm)?.label || t('choose')
                            }
                            id="input-group-dropdown-Filter-Value"
                            key={"drop_down_btn_3" + filterId}
                            onSelect={(e) => {
                                filter(e)
                            }}
                        >
                            {preDefinedProperties.find(p => p.name === property).options.map((h, i) => (
                                <Dropdown.Item
                                    eventKey={h.value}
                                    key={'filter_search_term_dropdown_item_' + filterId + i}>
                                    {h.label}
                                </Dropdown.Item>
                            ))}

                        </DropdownButton>
                        : <Form.Floating>
                            <Form.Control
                                key={"inpt_search_" + filterId}
                                disabled={disabled || !property || !operator || operator.label.includes('exists')}
                                className='border-2 filter-input-text'
                                id="inputSearch-Filter"
                                type="text"
                                placeholder={"Search for Items"}
                                value={searchTerm}
                                onChange={(event) => { filter(event.target.value) }}
                                onSubmit={(ev) => { return false }}
                            />
                            {searchTerm && searchTerm !== '' && <span onClick={() => { if (!disabled) setSearchTerm('') }} role="button" id="clear-button" className='position-absolute'><MdOutlineCancel /></span>}
                            <label htmlFor="inputSearch-Filter">{t('Filter')}</label>
                        </Form.Floating>
                    :
                    <DateRangeFilter onDateRangeSelected={(start, end) => { filter(start && end ? `_between(${[start, end].join(',')})` : '') }} />
            }
        </InputGroup>
    );
};

Filter.propTypes = {
    onLoadingChange: PropTypes.func,
    onDataChange: PropTypes.func,
    value: PropTypes.any,
    property: PropTypes.any,
    size: PropTypes.number
};

Filter.defaultProps = {
    size: 12,
    value: "",
    label: 'search',
    property: undefined,
    head: ['id', 'title']
};


