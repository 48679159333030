import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Form from "react-bootstrap/Form";


export const TextTag = ({
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    value,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData
}) => {

    useEffect(() => {
        if (!formData?.fleetName || action === 'edit') {
            return;
        }

        let temp = { ...formData };
        const names = formData.fleetName.split(" ");
        let val;
        if (names.length > 1) {
            //GET the INITIALS
            if (names.length === 2 && !isNaN(names[1])) {
                val = `${names[0].substring(0, 3)}${names[1]}`.toUpperCase();
            } else {
                val = formData.fleetName.match(/(\b\S)?/g).join("").toUpperCase();
            }

        } else if (names.length === 1) {
            if (names[0].length <= 6) {
                const name = names[0].substring(0, 6);
                val = name.toUpperCase();
            } else {
                const name = names[0].substring(0, 3);
                val = name.toUpperCase();
            }
        }
        if (val) {
            temp[attr] = val;
            onChangeFormData(temp)
        }


    }, [formData.fleetName])
    return (
        <FloatingLabel
            hidden={collectionData[attr].hidden || false}
            key={"floatingTextKey_" + attr} controlId={"floatingTextInput_" + attr}
            label={t(attr) + (collectionData[attr]?.required ? '*' : '')} className="mb-3">
            <Form.Control

                required={collectionData[attr].required}
                hidden={collectionData[attr].hidden || false}
                readOnly={collectionData[attr].readonly || action === 'edit' || false}
                value={formData[attr] || ''}
                onChange={(ev) => {
                    let temp = { ...formData };
                    temp[attr] = ev.target.value
                    onChangeFormData(temp)
                }}
                type={collectionData[attr]?.type || 'text'} />
        </FloatingLabel>
    )
}
TextTag.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
    onChangeCollectionData: PropTypes.func,
};