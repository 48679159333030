import { Authenticator, translations } from "@aws-amplify/ui-react";
import { Amplify } from "aws-amplify";
import { fetchAuthSession } from "aws-amplify/auth";
import { I18n } from "aws-amplify/utils";

import '@aws-amplify/ui-react/styles.css';
import ModalProvider from "components/modal/provider";
import dayjs from 'dayjs';
import 'dayjs/locale/de';
import 'dayjs/locale/en';
import relativeTime from 'dayjs/plugin/relativeTime';
import React from "react";
import ReactDOM from "react-dom";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { PersistGate } from "redux-persist/es/integration/react";
import App from "./App";
import "./i18n";
import reportWebVitals from "./reportWebVitals";
import { persistor, store } from "./store";

I18n.putVocabularies(translations);
I18n.setLanguage('de');
dayjs.locale('de');
dayjs.extend(relativeTime);

export const APIS = JSON.parse(process.env.REACT_APP_APIS);
export const roles = JSON.parse(process.env.REACT_APP_ROLES);
const REST = {
  AWSApi: {
    endpoint: process.env.REACT_APP_API_URL,
    region: process.env.REACT_APP_REGION,
  }
};
if (process.env?.REACT_APP_DEMO_API_URL) {
  REST.AWSDemoApi = {
    endpoint: process.env.REACT_APP_DEMO_API_URL,
    region: process.env.REACT_APP_REGION,
  }
}
Amplify.configure({
  Auth: {
    Cognito: {
      userPoolId: process.env.REACT_APP_USER_POOL_ID,
      userPoolClientId: process.env.REACT_APP_USER_POOL_CLIENT_ID,
      identityPoolId: process.env.REACT_APP_IDENTITY_POOL_ID,
    },
  },
  API: {
    REST
  }
}, {
  API: {
    REST: {
      headers: async () => {
        const authToken = (await fetchAuthSession()).tokens?.accessToken?.toString();
        return { Authorization: `Bearer ${authToken}` };
      },
    }
  }
});

ReactDOM.render(
  <React.StrictMode>
    <Authenticator.Provider>
      <Provider store={store}>
        <PersistGate persistor={persistor}>
          <ModalProvider>
            <BrowserRouter>
              <App />
            </BrowserRouter>
          </ModalProvider>
        </PersistGate>
      </Provider>
    </Authenticator.Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
