import { cacheReducer as cache } from "../store/cacheSlice";
import { globalsReducer as globals } from "../store/globalsSlice";
import { pagesReducer as pages } from "../store/pagesSlice";
import { settingsReducer as settings } from "../store/settingsSlice";
import { userReducer as user } from "../store/userSlice";

export default {
  globals,
  settings,
  user,
  pages,
  cache
};
