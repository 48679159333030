import { t } from "i18next";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { get } from "utils/api";
import { store } from "../../store";
import { setNavTitle } from "../../store/globalsSlice";
import { VehicleDashboard as Dashboard } from "../../stories/pages/VehicleDashboard";

export default function VehicleDashboard() {

    const params = useParams();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    useEffect(() => {
        dispatch(setNavTitle(t('vehicle') + ' ' + t('dashboard')))
    }, [params])

    return (
        <Dashboard
            redirect={(path) => navigate(path)}
            getHandler={(path, init) => get(path, init, [], store.dispatch)}
            onShow={(url) => navigate(url)}
            id={params.id}
        />
    );
}
