import { createSlice } from "@reduxjs/toolkit";
import { generateUUID } from "stories/utils/common";

const initialState = {
    admin: {
        fleetCompanyId: null
    },
    list: {
        'fleets': [],
        'vehicles': [],
        'fleet-companies': [],
        'maintenance-companies': [],
        'maintenance-tasks': [],
        'components': [],
        'users': [],
        'errors': [],
        'translations': [],
        'datasets': [],
        'histories': [],
        'reminders': [],
        'app-logs': [],
    }
};

const pagesSlice = createSlice({
    name: "pages",
    initialState,
    reducers: {
        changeListFilter(state, action) {
            if (state.list?.[action.payload.namespace]) {
                if (action.payload.filter?.id) {
                    const index = state.list[action.payload.namespace].findIndex(l => l.id === action.payload.filter?.id);
                    if (index !== -1) {
                        state.list[action.payload.namespace][index] = action.payload.filter;
                    }
                }
            }
        },
        addListFilter(state, action) {
            if (state.list?.[action.payload.namespace]) {
                const existing = state.list?.[action.payload.namespace].find(f => {
                    return f.filterSearchTerm === action.payload.filter.filterSearchTerm &&
                        f.filterProperty === action.payload.filter.filterProperty &&
                        f.filterOperator === action.payload.filter.filterOperator
                })
                if (existing) return;
                state.list[action.payload.namespace].push({ ...action.payload.filter, id: generateUUID() });
            }
        },
        clearListFilter(state, action) {
            if (state.list?.[action.payload.namespace]) {
                state.list[action.payload.namespace] = [{ id: generateUUID() }];
            }
        },
        clearAndAddListFilter(state, action) {
            const pl = action.payload;
            if (state.list?.[pl.namespace]) {
                state.list[pl.namespace] = [{
                    id: generateUUID(),
                    filterSearchTerm: pl.filterSearchTerm,
                    filterProperty: pl.filterProperty,
                    filterOperator: pl.filterOperator,
                    destroyOnOnmount: pl?.destroyOnOnmount || false
                }];
            }
        },
        removeListFilter(state, action) {
            if (state.list?.[action.payload.namespace]) {
                state.list[action.payload.namespace] = state.list[action.payload.namespace].filter(l => l?.id !== action.payload.id);
            }
        },
        setAdminFleetCompany(state, action) {
            state.admin.fleetCompanyId = action.payload;
        },
        initListFilters(state, action) {
            state.list = action.payload;
        },
        destroyFiltersOnOnmount(state, action) {
            const namespace = action.payload;
            if (state.list?.[namespace]?.length > 0) {
                const filters = [...state.list?.[namespace]];
                for (let i in filters) {
                    const filter = filters[i];
                    if (filter?.destroyOnOnmount === true) {
                        state.list[namespace] = [{ id: generateUUID() }];
                    }
                }
            }
        }
    },
});

export const selectListFilters = (state, namespace) => {
    return state.pages.list?.[namespace];
};
export const selectAdminChosenFleetCompanyId = (state) => {
    return state.pages.admin.fleetCompanyId;
};

export const pagesReducer = pagesSlice.reducer;
export const { addListFilter, changeListFilter, clearListFilter, clearAndAddListFilter, removeListFilter, setAdminFleetCompany, initListFilters, destroyFiltersOnOnmount } = pagesSlice.actions;
