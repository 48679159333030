import { CONSTANTS } from '@constants';
import dayjs from 'dayjs';
import PropTypes from 'prop-types';
import React, { useEffect, useState } from 'react';
import { DateField } from 'stories/crud/FieldTypes/DateField';

export const DateNextPlannedAt = (props) => {
    const {
        type,
        action,
        itemId,
        preDefinedProperties,
        schemesHandler,
        postHandler,
        patchHandler,
        backHandler,
        hidden,
        getHandler,
        attr,
        value,
        index,
        formData,
        collectionData,
        htmlSchemes,
        onChangeFormData,
        onChangeCollectionData
    } = props;
    const [loading, setLoading] = useState(false)

    useEffect(() => {
       if(formData.taskStatus === CONSTANTS.STATUS.CLOSED && formData.taskCategory === 'uvv'){
        onChangeFormData({...formData, nextPlannedAt:dayjs().add(1,'year').toISOString()});
       }

    }, [formData.taskStatus])

    const handleOnChange = async (val) => {
        let data = { ...val };
        onChangeFormData(data)
    }
    return (
        <DateField {...props} onChangeFormData={handleOnChange} loading={loading} />
    )
}
DateNextPlannedAt.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
};