import PropTypes from 'prop-types';
import React from 'react';
import { SelectField } from '../../../../stories/crud/FieldTypes/SelectField';
import { isEmpty } from '../../../../stories/utils/common';
import { SelectModelProperty } from './SelectModelProperty';
import { SelectModelType } from './SelectModelType';

export const SelectTranslations = (props) => {
    switch (props?.attr) {
        case 'modelType': {
            return <SelectModelType {...props} />;
        }
        case 'modelProperty': {
            const data = props.formData;
            return !isEmpty(data.modelType) ?
                <SelectModelProperty {...props} /> : <></>
        }
        default: {
            return <SelectField {...props} />
        }
    }
}
SelectTranslations.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
};