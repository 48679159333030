import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { get } from "utils/api";
import { SelectField } from "../../../../stories/crud/FieldTypes/SelectField";
import { isEmpty } from "../../../../stories/utils/common";

export const SelectComponentId = (props) => {
  const {
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    value,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData,
  } = props;

  const [loading, setLoading] = useState(false);

  useEffect(() => {
    const getOptions = async () => {
      const col = { ...collectionData };
      setLoading(true);
      try {
        const result = await get(`/components`, {
          queryParams: {
            vehicleId: formData.vehicleId,
            componentType: formData.taskCategory,
            componentCategory: formData.componentCategory,
          },
        });
        col.componentId.options = result.components?.map((c) => ({
          label: t(c.componentName),
          value: c.id,
        }));
        onChangeCollectionData(col);
      } finally {
        setLoading(false);
      }
    };
    if (
      !isEmpty(formData?.vehicleId) &&
      !isEmpty(formData?.taskCategory) &&
      !isEmpty(formData?.componentCategory) &&
      (formData?.taskCategory === "mechanical" ||
        formData?.taskCategory === "electrical")
    ) {
      getOptions();
    }
  }, [formData.taskCategory, formData.componentCategory, formData.vehicleId]);
  return <SelectField {...props} loading={loading} />;
};
SelectComponentId.propTypes = {
  type: PropTypes.string,
  action: PropTypes.string,
  itemId: PropTypes.any,
  preDefinedProperties: PropTypes.any,
  schemesHandler: PropTypes.func,
  postHandler: PropTypes.func,
  patchHandler: PropTypes.func,
  backHandler: PropTypes.func,
  hidden: PropTypes.array,
  getHandler: PropTypes.func,
  attr: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
  formData: PropTypes.object,
  collectionData: PropTypes.object,
  htmlSchemes: PropTypes.object,
  onChangeFormData: PropTypes.func,
};
