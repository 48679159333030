import PropTypes from 'prop-types';
import React from 'react';
import { selectUseDemoBackend } from 'store/userSlice';
import { CheckboxField } from 'stories/crud/FieldTypes/CheckboxField';
import { store } from '../../../../store';

export const CheckboxFleetCompanies = (props) => {
    switch (props?.attr) {
        case 'createDemoData': {
            const isDemoBackend = selectUseDemoBackend(store.getState());
            if (props.action !== 'create') return <></>;
            return isDemoBackend ? <CheckboxField {...props} /> : <></>;
        }
        default: {
            return <CheckboxField {...props} />
        }
    }
}
CheckboxFleetCompanies.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
};