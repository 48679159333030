import React, { useEffect } from "react";
import "./App.css";
import "./assets/css/themes/global-override.css";
import "./assets/css/themes/index.scss";


import { CONSTANTS } from "@constants";
import { useDispatch, useSelector } from "react-redux";
import { initListFilters } from "store/pagesSlice";
import { selectUseDemoBackend } from "store/userSlice";
import { generateUUID } from "stories/utils/common";
import { Router } from "./Router";


function App() {
  const dispatch = useDispatch();
  const isDemoBackend = useSelector(selectUseDemoBackend);
  const usersFilter = isDemoBackend ?
    [{ id: generateUUID() }] :
    [{
      id: generateUUID(),
      filterSearchTerm: '',
      filterProperty: "isDemoUser",
      filterOperator: "_not_exists",
    }]
  useEffect(() => {
    dispatch(initListFilters({
      'fleets': [{ id: generateUUID() }],
      'vehicles': [{
        id: generateUUID(),
        filterSearchTerm: CONSTANTS.STATUS.MAINTENANCE,
        filterProperty: "vehicleStatus",
        filterOperator: "=",
      }],
      'fleet-companies': [{ id: generateUUID() }],
      'maintenance-companies': [{ id: generateUUID() }],
      'maintenance-tasks': [{
        id: generateUUID(),
        filterSearchTerm: CONSTANTS.STATUS.OPEN,
        filterProperty: "taskStatus",
        filterOperator: "=",
      }],
      'components': [{
        id: generateUUID(),
        filterSearchTerm: CONSTANTS.STATUS.DEFECT,
        filterProperty: "componentStatus",
        filterOperator: "=",
      }],
      'users': usersFilter,
      'errors': [{ id: generateUUID() }],
      'translations': [{ id: generateUUID() }],
      'datasets': [{ id: generateUUID() }],
      'histories': [{ id: generateUUID() }],
      'reminders': [{ id: generateUUID() }],
      'app-logs': [{ id: generateUUID() }],
    }))
  }, [])


  return <Router />
}

export default App;
