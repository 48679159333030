import { t } from 'i18next';
import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { Importer, ImporterField } from 'react-csv-importer';
import 'react-csv-importer/dist/index.css';

export const CSVImporter = ({ fields = [], onRowProcessed, onClose, className }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => {
        onClose()
        setShow(false)
    };
    const handleShow = () => setShow(true);

    return (
        <>
            <Button variant="secondary" className={"shadow me-2 " + className }  onClick={handleShow}>
                 {t('import_csv')}
            </Button>
            <Modal
                show={show}
                onHide={handleClose}
                backdrop="static"
                keyboard={false}
                size="lg"
            >
                <Modal.Header closeButton>
                    <Modal.Title>import CSV File</Modal.Title>
                </Modal.Header>
                <Modal.Body >
                    <Importer
                        assumeNoHeaders={false}
                        restartable={true}
                        onStart={({ file, preview, fields, columnFields }) => { }}
                        processChunk={async (rows, { startIndex }) => {
                            // required, may be called several times
                            // receives a list of parsed objects based on defined fields and user column mapping;
                            // (if this callback returns a promise, the widget will wait for it before parsing more data)
                            for (let i in rows) {
                              await onRowProcessed(rows[i]);
                            }
                          }}
                    >
                        {fields.map((field,i) => {
                            return (
                                <ImporterField key={'importer_field_' + i} name={field} label={field} optional />
                            )
                        })}
                    </Importer>
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="primary" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>

        </>

    )
};