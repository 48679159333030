import i18next, { t } from "i18next";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { get } from "utils/api";
import { SelectField } from "../../../../stories/crud/FieldTypes/SelectField";
import { isEmpty } from "../../../../stories/utils/common";
import "./SelectComponentDefect.css";

export const SelectComponentDefect = (props) => {
  const {
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    value,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData,
  } = props;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getOptions = async () => {
      const col = { ...collectionData };
      setLoading(true);
      try {
        const result = await get(
          `/components/${formData.componentId}`
        );
        if (!result?.components?.defects) return;

        if (col?.componentDefect) {
          col.componentDefect.options = result.components?.defects?.map((tr) => ({
            label: t(tr.component_defect_name),
            value: tr.component_defect_name,
          }));
          col.componentDefect.component = result.components;
        }
        onChangeCollectionData(col);
      } finally {
        setLoading(false);
      }
    };
    if (!isEmpty(formData.componentId)) getOptions();
  }, [formData.componentId]);

  const handleOnChange = async (val) => {
    let data = { ...val };
    onChangeFormData(data);
  };
  return (
    <>
      <SelectField
        {...props}
        onChangeFormData={handleOnChange}
        loading={loading}
      />
      {i18next.exists(`${value}_description`) ? (
        <pre className="pre-error-desc">
          {i18next.t(`${value}_description`)}
        </pre>
      ) : null}
    </>
  );
};
SelectComponentDefect.propTypes = {
  type: PropTypes.string,
  action: PropTypes.string,
  itemId: PropTypes.any,
  preDefinedProperties: PropTypes.any,
  schemesHandler: PropTypes.func,
  postHandler: PropTypes.func,
  patchHandler: PropTypes.func,
  backHandler: PropTypes.func,
  hidden: PropTypes.array,
  getHandler: PropTypes.func,
  attr: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
  formData: PropTypes.object,
  collectionData: PropTypes.object,
  htmlSchemes: PropTypes.object,
  onChangeFormData: PropTypes.func,
  relatedResources: PropTypes.any,
};
