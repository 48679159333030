import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import DateTimePicker from 'react-datetime-picker';
export const DatePlannedAt = ({
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    value,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData
}) => {
    return (
        <FloatingLabel key={"floatingTextKey_" + attr} id={"floatingDate_" + attr} controlId={"floatingDate_" + attr}
            label={t(attr) + (collectionData[attr].required ? '*' : '')}
            hidden={!!collectionData[attr].hidden}
            className="mb-3">
            <DateTimePicker
                hidden={!!collectionData[attr].hidden}
                required={collectionData[attr].required}
                disabled={formData?.immutableByInitialTask ? true : collectionData[attr]?.readonly}
                min={collectionData[attr].min || undefined}
                className="form-control py-0 px-0 mt-0"
                key={'timePicker_' + attr}
                onChange={(date) => {

                    let temp = { ...formData };
                    temp[attr] = date && date !== null ? date.toISOString() : undefined;

                    onChangeFormData(temp);
                }}
                value={formData[attr] && formData[attr] !== '' ? new Date(formData[attr]) : ''} />
        </FloatingLabel>
    )
}
DatePlannedAt.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
    onChangeCollectionData: PropTypes.func
};