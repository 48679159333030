import React, { useEffect, useState } from "react";
import ModalComponent from "./Modal";
import ModalContext from "./context";

const ModalProvider = ({ children }) => {
  const [show, setShow] = useState(false);
  const [modalProps, setModalProps] = useState(null);
  const [handlers, setHandlers] = useState(null);
  const [disable, setDisable] = useState(false);

  function modal(body, title, closeText, saveText, close, confirm) {
    setHandlers({ close, confirm });
    setModalProps({
      title,
      body,
      closeText,
      saveText,
    });
  }

  function onCloseModal() {
    setShow(false);
    setDisable(false);
  }
  function onConfirmModal() {
    if (handlers?.confirm) {
      setDisable(true);
      handlers.confirm();
      onCloseModal();
    } else onCloseModal(); // close anyway; no confirm handler
  }

  useEffect(() => {
    if (!modalProps || !handlers) return;
    setShow(true);
  }, [modalProps, handlers]);

  useEffect(() => {
    if (!show) {
      // NOTE: closed, call close handler if avail.
      if (handlers?.close) {
        handlers.close(); // may be async, we'll push through this one. Not expecting a result
      }

      // NOTE: clear state
      setModalProps(null);
      setHandlers(null);
    }
  }, [show]);

  return (
    <ModalContext.Provider
      value={{
        modal,
      }}
    >
      <>
        {children}
        <ModalComponent
          show={show}
          disabled={disable}
          close={onCloseModal}
          confirm={onConfirmModal}
          {...modalProps}
        />
      </>
    </ModalContext.Provider>
  );
};

export default ModalProvider;
