import { CONSTANTS } from "@constants";
import useModal from "components/modal/hook";
import { t } from "i18next";
import pluralize from "pluralize";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router";
import { selectIsAdmin, selectUserPermissions } from "store/userSlice";
import { del, get, post, put } from "utils/api";
import { setNavTitle } from "../../store/globalsSlice";
import { EditAndCreate } from "../../stories/crud/EditAndCreate";
import { SelectFieldComponents } from "./CustomFields/Components/SelectComponents";
import { TextFieldComponents } from "./CustomFields/Components/TextComponents";
import { CheckboxFleetCompanies } from "./CustomFields/FleetCompanies/CheckboxFleetCompanies";
import { TextFleetCompanies } from "./CustomFields/FleetCompanies/TextFleetCompanies";
import { TextFleets } from "./CustomFields/Fleets/TextFleets";
import { DateMaintenanceTasks } from "./CustomFields/MaintenanceTasks/DateMaintenanceTasks";
import { SelectFieldMaintenanceTasks } from "./CustomFields/MaintenanceTasks/SelectMaintenanceTasks";
import { TextMaintenanceTasks } from "./CustomFields/MaintenanceTasks/TextMaintenanceTasks";
import { SelectTranslations } from "./CustomFields/Translations/SelectTranslations";
import { MultiselectUsers } from "./CustomFields/Users/MultiselectUsers";
import { SelectUsers } from "./CustomFields/Users/SelectUsers";

export default function EditOrCreate() {
  function useQuery() {
    const { search } = useLocation();

    return React.useMemo(() => new URLSearchParams(search), [search]);
  }

  const params = useParams();
  const query = useQuery();
  const navigate = useNavigate();
  const { modal } = useModal();
  let fieldOverwrites = {};
  let schemeHandler = (init) =>
    get(`/${params.type}/html-schemes`, init);
  let beforeSubmit, submitCallback, saveDisabled;
  let sortBy = [];
  const queryParams = {};
  const userPermissions = useSelector(selectUserPermissions);
  const isAdmin = useSelector(selectIsAdmin);
  const userRoutePermissions = userPermissions?.permissions?.find(
    (p) => p.route === params?.type
  );

  query.forEach((val, key) => {
    queryParams[key] = val;
  });

  const action = queryParams?.action;

  const dispatch = useDispatch();

  const hidden = ["id"];

  useEffect(() => {
    dispatch(
      setNavTitle(
        t(action + "_" + pluralize.singular(params.type), { name: "" })
      )
    );
  }, [params]);

  if (params.type === CONSTANTS.namespaces.FLEETS) {
    fieldOverwrites.text = (props) => {
      return <TextFleets {...props} />;
    };
  }
  if (params.type === CONSTANTS.namespaces.MAINTENANCE_TASKS) {
    fieldOverwrites.select = (props) => {
      return <SelectFieldMaintenanceTasks {...props} />;
    };
    fieldOverwrites.date = (props) => {
      return <DateMaintenanceTasks {...props} />;
    };
    fieldOverwrites.text = (props) => {
      return <TextMaintenanceTasks {...props} />;
    };
    sortBy = [
      "tag",
      "fleetId",
      "vehicleId",
      "taskCategory",
      "componentCategory",
      "componentId",
      "componentDefect",
      "description",
      "taskStatus",
      "taskAction",
      "componentInstallationState",
      "files",
      "accidentPreventionRegulationAccepted",
    ];
    if (action === "edit") {
      submitCallback = async (result) => {
        const compId = result.newComponentId;
        if (compId && result?.taskCategory !== "uvv") {
          try {
            await new Promise((resolve, reject) => {
              modal(
                <p>{t("edit_changed_component_desc")}</p>,
                t("edit_changed_component_title"),
                t("cancel"),
                t("yes"),
                () => {
                  reject();
                },
                () => {
                  resolve();
                }
              );
            });
            navigate(
              `/edit/${CONSTANTS.namespaces.COMPONENTS}/${compId}?action=edit`
            );
          } catch (e) {
            navigate("/list/" + params.type);
          }
        } else {
          navigate("/list/" + params.type);
        }
      };
      saveDisabled = (info) => {
        if (info.taskStatus === CONSTANTS.STATUS.CLOSED) {
          return true;
        }
        return false;
      };
    }
  }
  if (params.type === CONSTANTS.namespaces.TRANSLATIONS) {
    fieldOverwrites.select = (props) => {
      return <SelectTranslations {...props} />;
    };
    sortBy = [
      "modelType",
      "modelProperty",
      "translationKey",
      "translationDE",
      "translationEN",
    ];
  }

  if (params.type === CONSTANTS.namespaces.FLEET_COMPANIES) {
    fieldOverwrites.text = (props) => {
      return <TextFleetCompanies {...props} />;
    };
    fieldOverwrites.checkbox = (props) => {
      return <CheckboxFleetCompanies {...props} />;
    };
    sortBy = [
      "fleetCompanyName",
      "fleetCompanyImportFolder",
      "createDemoData",
      "userEmail",
      "maintenanceCompanyName",
      "fleetCompanyInspectionInterval",
    ];
  }
  if (params.type === CONSTANTS.namespaces.USERS) {
    fieldOverwrites.select = (props) => {
      return <SelectUsers {...props} />;
    };
    fieldOverwrites.multiselect = (props) => {
      return <MultiselectUsers {...props} />;
    };
  }

  if (params.type === CONSTANTS.namespaces.COMPONENTS) {
    schemeHandler = () =>
      get(
        `/${params.type}/html-schemes${params.id ? `/${params.id}` : ""}`,
        {}
      );
    fieldOverwrites.select = (props) => {
      return <SelectFieldComponents {...props} />;
    };
    fieldOverwrites.text = (props) => {
      return <TextFieldComponents {...props} />;
    };
    sortBy = [
      "vehicleId",
      "componentCategory",
      "componentName",
      "componentModel",
      "serial",
      "inventoryNumber",
      "componentType",
      "description",
    ];
  }

  return (
    <EditAndCreate
      key={"edit_and_create_" + params.type}
      sortBy={sortBy}
      isAdmin={isAdmin}
      preDefinedProperties={queryParams?.preDefinedProperties}
      fieldOverwrites={fieldOverwrites}
      type={params.type}
      action={action}
      itemId={params.id || undefined}
      queryParams={queryParams}
      hidden={hidden}
      beforeSubmit={beforeSubmit}
      submitCallback={submitCallback}
      schemesHandler={schemeHandler}
      saveDisabled={saveDisabled}
      showDelete={
        userRoutePermissions?.DELETE === CONSTANTS.CRUD_PERMISSIONS.ALL
      }
      showHistory={isAdmin}
      backHandler={() => {
        setTimeout(() => {
          window.history.back();
        }, 1000);
      }}
      postHandler={
        action === "create"
          ? (body, init) =>
            post("/" + params.type, { body }, init)
          : undefined
      }
      patchHandler={
        action === "edit"
          ? (id, body, init) =>
            put("/" + params.type + "/" + id, { body }, init)
          : undefined
      }
      getHandler={
        action === "edit"
          ? (id, init) =>
            get("/" + params.type + "/" + id, init, [], dispatch)
          : undefined
      }
      deleteHandler={
        action === "edit"
          ? (id, init) =>
            del("/" + params.type + "/" + id, init)
          : undefined
      }
    />
  );
}
