import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Select from 'react-select';
import { VSCLoader } from 'stories/VSCLoader';
import './SelectField.css';

export const SelectField = ({
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData,
    loading = false,
    isAdmin
}) => {

    const customStyles = {
        control: (base, state) => ({
            ...base,
            minHeight: '48px',
            fontSize: '16px',
            paddingTop: '1.625rem',
            paddingBottom: '0.625rem',
            paddingLeft: '1rem',

            borderColor: '#0000002b',
            borderRadius: 0,
            boxShadow: 'none',
            '&:hover': {
                borderColor: state.isFocused ? '#a8d798' : '#0000002b'
            },
            backgroundColor: '#f7f7f9',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 9999,
            // Stellen Sie sicher, dass das Dropdown-Menü über anderen Elementen steht.
        }),
    }

    const obj = collectionData[attr]?.options?.filter(o => {
        if (!o.optgroup) return o.value === formData[attr];
        return o.optgroup.options.find(op => op?.value === formData[attr])
    }).map(o => {
        if (!o.optgroup) return o;
        return o.optgroup.options.find(op => op?.value === formData[attr])
    })?.[0]
    const value = obj ? { ...obj, label: t(obj.label, obj?.translationVariables) } : undefined;

    return (
        <>
            <FloatingLabel hidden={collectionData[attr]?.hidden || false} key={"floatingSelectKey_" + attr + itemId} controlId={"floatingTextSelect_" + attr} label={t(attr) + (collectionData[attr]?.required ? '*' : '')} className="mb-3 select-form-field">
                {loading && <VSCLoader style={{ zIndex: 3, position: 'absolute', top: "50%", left: "2rem" }} />}
                <Select
                    styles={customStyles}
                    isSearchable
                    isClearable
                    key={"SelectKey_" + attr + itemId}
                    required={collectionData[attr]?.required}
                    onChange={(ev) => {
                        let temp = { ...formData };
                        temp[attr] = ev?.value;
                        onChangeFormData(temp);
                    }}
                    placeholder=""

                    isDisabled={loading || (!isAdmin && collectionData[attr]?.readonly)}
                    hidden={collectionData[attr]?.hidden || false}
                    value={value || ''}
                    options={collectionData[attr]?.options?.map((item) => {
                        if (!item.optgroup) return {
                            ...item, label: item.translationVariables ?
                                t(item.label, item.translationVariables) :
                                t(item.label)
                        };
                        return {
                            label: t(item.optgroup.label),
                            options: item.optgroup.options.map(o => ({
                                ...o, label: o.translationVariables ?
                                    t(o.label, o.translationVariables) :
                                    t(o.label)
                            }))
                        };
                    })}
                />
            </FloatingLabel>
        </>
    )
}
SelectField.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
    onChangeCollectionData: PropTypes.func,
};