import { CONSTANTS } from "@constants";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { selectAdminChosenFleetCompanyId, setAdminFleetCompany } from "store/pagesSlice";
import { VSCLoader } from "stories/VSCLoader";
import { isEmpty } from "stories/utils/common";
import { AbortManager } from "utils/AbortManager";
import { get } from "utils/api";

export const AdminSelectFleetCompany = () => {
    const [loading, setLoading] = useState(true);
    const [fleetCompanies, setFleetCompanies] = useState();
    const fleetCompanyId = useSelector(selectAdminChosenFleetCompanyId);
    const dispatch = useDispatch();
    const listId = `AdminSelectFleetCompany_${new Date().toISOString()}`;
    const abortManager = { id: listId, abortManager: AbortManager };

    useEffect(() => {
        const getCompanies = async () => {
            try {
                const res = await get(`/${CONSTANTS.namespaces.FLEET_COMPANIES}`, { abortManager });
                const companies = res?.[CONSTANTS.namespaces.FLEET_COMPANIES];
                if (companies) setFleetCompanies(companies);
                setLoading(false)
            } catch (e) {

            }
        }
        getCompanies();
        return () => {
            abortManager.abortManager.cancel(listId)
        }
    }, [])

    return (
        <Container>
            <Row xs={"auto"}>
                <Col xs={12} md={{ span: 6, offset: 3 }}>
                    {isEmpty(fleetCompanyId) && <Form.Label htmlFor="choose-fleetCompany">{t('choose_fleet_company')}</Form.Label>}
                    <FloatingLabel key={"AdminSelectFleetCompanyfloatingSelectKey"} controlId={"AdminSelectFleetCompany"} label={t('fleetCompanyId')} className="mb-3">
                        {loading && <VSCLoader style={{ zIndex: 3, position: 'absolute', top: "50%", left: "2rem" }} />}
                        <Form.Select
                            id="choose-fleetCompany"
                            key={"AdminSelectFleetCompany_select"}
                            required={true}
                            onChange={(ev) => {
                                if (!isEmpty(ev?.target?.value)) dispatch(setAdminFleetCompany(ev.target.value))
                            }}
                            disabled={loading}

                            value={fleetCompanyId || ''}
                            aria-label="Floating label select example">
                            <option></option>
                            {fleetCompanies?.map((item, i) => {
                                return (
                                    <option
                                        key={"AdminSelectFleetCompany_option" + item.id} value={item.id}>
                                        {item.fleetCompanyName}
                                    </option>
                                )


                            })}
                        </Form.Select>
                    </FloatingLabel>
                </Col>
            </Row>
        </Container>
    )
}