import { del as DEL, get as GET, post as POST, put as PUT } from "@aws-amplify/api";
import { CONSTANTS } from "@constants";
import { setProgressItems } from "store/globalsSlice";
import { selectApi } from "store/userSlice";
import { store } from "../store";


export const get = async (path, options, items = [], dispatch) => {
    const apiName = selectApi(store.getState());
    let res;
    const frontendLimit = options?.queryParams?.frontendLimit;
    const abortManager = options?.abortManager;
    const getRequest = GET({
        apiName,
        path,
        options
    });
    if (abortManager) abortManager.abortManager.initCancel(abortManager.id, getRequest.cancel);
    const raw = (await getRequest.response).body;

    try {
        res = await raw.text();
        res = JSON.parse(res);
    }
    catch (e) {

    }

    const collectionName = Object.keys(res)?.find(r => Object.values(CONSTANTS.namespaces).includes(r));

    if (res?.[collectionName] && Array.isArray(res?.[collectionName])) {
        items = items.concat(res[collectionName]);
    } else {
        items = res[collectionName];
    }
    const limitReached = options?.queryParams?.limit > 0 ? items.length >= options?.queryParams?.limit : false;
    const amountReached = frontendLimit ? items.length >= frontendLimit : false;
    let oldExclusiveStartKey, LastEvaluatedKey;
    try {
        oldExclusiveStartKey = JSON.stringify(options?.queryParams?.ExclusiveStartKey);
        LastEvaluatedKey = JSON.stringify(res?.LastEvaluatedKey)
    } catch (e) { }

    if (LastEvaluatedKey && res?.[collectionName] && !limitReached && oldExclusiveStartKey !== LastEvaluatedKey && !amountReached) {
        if (dispatch) dispatch(setProgressItems(items?.length))
        return await get(path, {
            ...options, queryParams: {
                ...options?.queryParams, ExclusiveStartKey: LastEvaluatedKey
            }
        }, items, dispatch)
    } else {
        if (res?.[collectionName]) res[collectionName] = items;
        if (dispatch) dispatch(setProgressItems(null));
        if (abortManager) abortManager.abortManager.success(abortManager.id);
        return res;
    }

}
export const post = async (path, options) => {
    const apiName = selectApi(store.getState());
    const abortManager = options?.abortManager;
    const postRequest = POST({
        apiName,
        path,
        options
    });
    if (abortManager) abortManager.abortManager.initCancel(abortManager.id, postRequest.cancel);
    const res = await postRequest.response;
    const result = await getBody(res.body);
    if (abortManager) abortManager.abortManager.success(abortManager.id);
    return result;
}
export const put = async (path, options) => {
    const apiName = selectApi(store.getState());
    const abortManager = options?.abortManager;
    try {
        const putRequest = PUT({
            apiName,
            path,
            options
        });
        if (abortManager) abortManager.abortManager.initCancel(abortManager.id, putRequest.cancel);

        const res = await putRequest.response;
        const result = await getBody(res.body);
        if (abortManager) abortManager.abortManager.success(abortManager.id);
        return result;
    } catch (e) {
        console.error('putRequest', e);
        throw e;
    }

}
export const del = async (path, options) => {
    const apiName = selectApi(store.getState());
    const abortManager = options?.abortManager;
    const deleteRequest = DEL({
        apiName,
        path,
        options
    });
    if (abortManager) abortManager.abortManager.initCancel(abortManager.id, deleteRequest.cancel);
    const res = await deleteRequest.response;
    const result = getBody(res.body);
    if (abortManager) abortManager.abortManager.success(abortManager.id);
    return result;
}

const getBody = async (body) => {
    let res;
    try {
        res = await body.text();
        res = JSON.parse(res);
    } catch (e) {

    }
    return res;
}

export const API = {
    get,
    post,
    put,
    del
}