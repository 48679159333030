import { t } from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { get } from "utils/api";
import { store } from "../../store";
import { setNavTitle } from "../../store/globalsSlice";
import { selectIsAdmin, selectIsFleetManager } from "../../store/userSlice";
import { FleetDashboard as Dashboard } from "../../stories/pages/FleetDashboard";

export default function FleetDashboard() {
  const params = useParams();
  const navigate = useNavigate();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setNavTitle(t("fleet") + " " + t("dashboard")));
    return () => { };
  }, []);
  const isAdmin = useSelector(selectIsAdmin);
  const isFleetManager = useSelector(selectIsFleetManager);
  return (
    <Dashboard
      showEditButton={isAdmin || isFleetManager}
      getHandler={(path, init) => get(path, init, [], store.dispatch)}
      onShow={(url) => navigate(url)}
      id={params.id}
    />
  );
}
