import { Colors } from 'constants/colors';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Select, { components } from 'react-select';

export const MultiselectField = ({
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    value,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData
}) => {
    const getValue = (formData) => {
        if (!formData?.[attr]) return null;

        return Array.isArray(formData?.[attr]) ?
            (formData?.[attr] && formData?.[attr]?.map((s) => ({ value: s, label: t(s) }))) || [] :
            { label: t(formData?.[attr]), value: formData?.[attr] }
    }
    return (
        <div key={"div_multi_" + index} className='mb-3'>
            <label style={{ opacity: '0.65', transform: 'scale(0.85) translateY(-0.5rem) translateX(0.15rem)' }} key={"LabelMultiSelect_" + attr} htmlFor={"multiSelect_" + attr} id={"multiSelectLabel_" + attr}>{attr}</label>
            {formData && !collectionData[attr]?.grouped &&

                <Select
                    onChange={(newValue) => {
                        if (!newValue) return;
                        const temp = { ...formData };
                        temp[attr] = Array.isArray(newValue) ? newValue?.map((n) => n.value) : newValue.value;
                        onChangeFormData(temp)
                    }}
                    value={getValue(formData)}
                    isMulti={typeof collectionData[attr]?.multiple !== 'undefined' ? collectionData[attr].multiple : true}
                    name={"multiselect_" + attr}
                    options={collectionData[attr]?.options.map(o => ({ label: t(o.label), value: o.value }))}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    id={"multiSelect_" + attr}
                    key={"attrMultiSelect_" + attr}
                    hidden={collectionData[attr]?.hidden || false}
                />
            }
            {formData && collectionData[attr]?.grouped &&

                <Select
                    onChange={(newValue) => {
                        if (!newValue) return;
                        const temp = { ...formData };
                        temp[attr] = Array.isArray(newValue) ? newValue?.map((n) => n.value) : newValue.value;
                        onChangeFormData(temp)
                    }}
                    value={getValue(formData)}
                    isMulti={typeof collectionData[attr]?.multiple !== 'undefined' ? collectionData[attr].multiple : true}
                    name={"multiselect_" + attr}
                    options={collectionData[attr]?.options}
                    components={components}
                    className="basic-multi-select"
                    classNamePrefix="select"
                    id={"multiSelect_" + attr}
                    key={"attrMultiSelect_" + attr}
                    hidden={collectionData[attr]?.hidden || false}
                    styles={{
                        groupHeading: (base) => ({
                            ...base,
                            flex: '1 1',
                            color: 'white',
                            backgroundColor: Colors.primary,
                            margin: 0,
                            fontSize: 16
                        }),
                    }}
                />
            }
        </div>
    )
}
MultiselectField.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
    onChangeCollectionData: PropTypes.func,
};