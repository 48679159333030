import React from "react";
import Lottie from "react-lottie";
import * as animationData from '../assets/vsc-animation.json';

export const VSCLoader = (props) => {
    const size = props?.size ? props?.size : 'sm';
    const widthAndHeight = size === 'sm' ? 30 : 75;
    return (
        <div className="d-inline" {...props} style={{ ...props?.style, marginLeft: -15 }}>
            <Lottie speed={1.5} width={widthAndHeight} height={widthAndHeight} options={{ loop: true, autoplay: true, animationData }} />
        </div>
    );
};