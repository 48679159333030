import React from 'react';
import Pagination from 'react-bootstrap/Pagination';

const Paginator = ({ currentPage, totalPages, onPageChange }) => {
    const paginationItems = [];

    if (totalPages <= 6) {
        for (let number = 1; number <= totalPages; number++) {
            paginationItems.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => onPageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }
    } else {
        paginationItems.push(
            <Pagination.Item key={1} active={1 === currentPage} onClick={() => onPageChange(1)}>
                1
            </Pagination.Item>
        );

        if (currentPage > 3) {
            paginationItems.push(<Pagination.Ellipsis key="ellipsis1" />);
        }

        let startPage = Math.max(2, currentPage - 1);
        let endPage = Math.min(totalPages - 1, currentPage + 1);

        for (let number = startPage; number <= endPage; number++) {
            paginationItems.push(
                <Pagination.Item key={number} active={number === currentPage} onClick={() => onPageChange(number)}>
                    {number}
                </Pagination.Item>
            );
        }

        if (currentPage < totalPages - 2) {
            paginationItems.push(<Pagination.Ellipsis key="ellipsis2" />);
        }

        paginationItems.push(
            <Pagination.Item key={totalPages} active={totalPages === currentPage} onClick={() => onPageChange(totalPages)}>
                {totalPages}
            </Pagination.Item>
        );
    }

    return (
        <Pagination className='m-0 p-0' >{paginationItems}</Pagination>
    );
};

export default Paginator;
