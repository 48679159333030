export const en = {
  translation: {
    nextPlannedAt: 'Recurring task planned at',
    attachedRoles: 'attached roles',
    handleUVVAsInspection: "Handle UVV as inspection",
    lockedAt: 'locked',
    used: 'gebraucht',
    componentInstallationState: 'Installation state',
    vehicleManufacturer: 'Manufacturer',
    vehicleConstructionYear: 'Construction year',
    fleetCompanyImportFolder: 'S3 Import folder name',
    'create_fleet-company': 'Create a fleet company',
    model: 'Model',
    no_interval: 'No interval',
    fleetCompanyInspectionInterval: 'Inspection interval (within 365 days)',
    every_singular_month: 'Every first month',
    every_plural_month: 'Every {{month}} month',
    admin_sidebar_items: 'Admin',
    user_sidebar_items: 'Customers',
    tooltip_create_csv: 'Create CSV file',
    statistics: 'Statistics',
    vehicles_without_uvv: 'Vehicles without closed UVV',
    vehicles_without_inspection: 'Vehicles without closed inspections',
    getLogs: 'Activate app logging',
    yes: 'Yes',
    no: 'No',
    attachedRoles: 'Attached roles',
    admin_unlock_fleet: 'Do you really want to unlock the fleet ?\n\nAs a result, all non -synchronized data of the user ({{user}}) will be rejected.',
    planned: 'planned',
    plannedAt: 'planned at',
    total: 'Total',
    locked: 'Locked',
    fleet_is_locked: 'Fleet locked',
    fleet_is_locked_description: 'The fleet is currently locked by an user. Please try again later.',
    item_history: 'Object history',
    manual: 'created manually',
    createDemoData: ' create demo data?',
    imported: 'imported',
    MODIFY: 'CHANGED',
    REMOVE: 'REMOVED',
    INSERT: 'CREATED',
    isDemoUser: "Demo account",
    createdBy: 'User',
    createdTasks: 'Created tasks',
    manually: 'manually',
    automatically: 'automatically',
    contentBefore: 'Content before',
    contentChanged: 'Content changed',
    demo_system_on: "Demo system",
    demo_system_off: "Live system",
    demo_mode: "Demo mode",
    filter_error: "Please concretize your search to get results. ",
    vehicles_from_to: 'Selected vehicles by <span class="fw-bold">"{{status}}"</span> from <span class="fw-bold">{{from}}</span> to <span class="fw-bold">{{to}}</span>',
    all_vehicles_from_to: 'All selected vehicles from <span class="fw-bold">{{from}}</span> to <span class="fw-bold">{{to}}</span>',
    components_from_to: 'Selected components by <span class="fw-bold">"{{status}}"</span> from <span class="fw-bold">{{from}}</span> to <span class="fw-bold">{{to}}</span>',
    all_components_from_to: 'All selected components from <span class="fw-bold">{{from}}</span> to <span class="fw-bold">{{to}}</span>',
    'maintenance-tasks_from_to': 'Selected tasks by <span class="fw-bold">"{{status}}"</span> from <span class="fw-bold">{{from}}</span> to <span class="fw-bold">{{to}}</span>',
    'all_maintenance-tasks_from_to': 'All selected tasks from <span class="fw-bold">{{from}}</span> to <span class="fw-bold">{{to}}</span>',
    actualComponentStatus: 'Actual components status',
    actualVehicleStatus: 'actual vehicle status',
    actualTaskStatus: 'actual task status',
    commissioningDate: 'Commissioning date',
    full_period: 'Full period',
    exists: "exists",
    not_exists: "not exists",
    day: 'day',
    days: 'days',
    apply: 'Apply',
    last_week: 'Last week',
    last_month: 'Last month',
    last_year: 'Last year',
    execute: "execute",
    reset: "reset",
    cancel: "cancel",
    between: 'is between',
    inventoryNumber: 'inventory number',
    reminders: 'Reminder',
    "app-logs": 'App logs',
    logout: 'Logout',
    fetched: 'fetched at',
    refresh: 'refresh',
    tooltip_show_vehicles: "Show vehicles",
    tooltip_show_open_maintenance_tasks: "Show open maintenance tasks",
    tooltip_open_fleets_item: "Goto fleet",
    tooltip_open_vehicles_item: "Goto vehicle",
    tooltip_open_components_item: "Goto component",
    'tooltip_open_maintenance-company_item': "Goto maintenance company",
    tooltip_open_maintenance_company_item: "Goto maintenance company",
    tooltip_open_maintenance_tasks_item: "Goto maintenance task",
    'tooltip_open_maintenance-tasks_item': "Goto maintenance task",
    tooltip_open_fleet_companies_item: "Goto fleet company",
    tooltip_expand_list: "Maximize/minimize list",
    tooltip_create_pdf: "Download PDF",
    tooltip_create_qr_code_list: "Create QR code list",
    create_list: "Create list",
    large_request_warning: "This query can take a little longer than usual because there are many entries. Do you want to continue?",
    language: 'Language',
    de: 'Deutsch',
    en: 'English',
    items_loaded: 'Entries fetched',
    loading_items: 'Loading entries',
    from: "from",
    choose_fleet_company: "Choose a fleet company to continue",
    enter_your_email: 'Enter your E-Mail address',
    defect_components: 'Defect components',
    reportedAt: 'Reported at',
    inspection: 'Inspection',
    amount: 'Amount',
    company: 'Company',
    company_dashboard: 'company dashboard',
    dashboard: 'Dashboard',
    users: "users",
    fleets: "fleets",
    "fleet-companies": "fleet companies",
    "maintenance-companies": "maintenance companies",
    fleet: "fleet",
    vehicle: "vehicle",
    vehicles: "vehicles",
    "maintenance-task": "maintenance task",
    "maintenance-tasks": "maintenance tasks",
    components: "components",
    component: "component",
    new: "new",
    all: "all ",
    create: "create",
    edit: "edit",
    delete: "delete",
    actions: "actions",
    no_data: "No data",
    create_fleet: "create fleet",
    edit_fleet: "edit fleet {{name}}",
    create_vehicle: "create vehicle",
    edit_vehicle: "edit vehicle {{name}}",
    Filter: "Filter",
    "create_maintenance-task": "create maintenance-task",
    "edit_maintenance-task": "edit maintenance-task {{name}}",
    create_component: "create component",
    edit_component: "edit component {{name}}",
    import_csv: "import csv",
    fleetName: "name",
    fleetCompanyName: "company name",
    maintenanceCompanyName: "company name",
    createdAt: "created at",
    changedAt: "changed at",
    serial: "serial no.",
    vehicleStatus: "vehicle status",
    vehicleName: "name",
    description: "description",
    taskStatus: "task status",
    vehicleId: "vehicle id",
    fleetId: "fleet",
    status: "status",
    type: "type",
    name: "name",
    last_connection: "last connection",
    mileage: "mileage",
    inProgress: "in progress",
    open_link: "show {{item}}",
    all_components: "all components",
    all_vehicles: "all vehicles",
    "all_maintenance-tasks": "all maintenance-tasks",
    all_maintenanceTasks: "all maintenance-tasks",
    show_all: "show all",
    from_date: "from",
    to_date: "to",
    maintenance_history: "Maintenance-tasks history",
    vehicle_history: "vehicle status history",
    hello: "Welcome {{name}}!",
    accidentPreventionRegulationAccepted:
      "Accident prevention regulations were checked",
    attributes: "attributes",
    choose: "choose",
    choose_none: "reset selection",
    reset_filter: "reset filter",
    upload_files: "Upload files",
    upload_file: "Upload file",
    import_file_tasks: "Import tasks file",
    only_utf_8: "Comma-separated, UTF-8 coded CSV file",
    importing_file: 'File imported',
    NotAuthorizedException: "Username and/or password are wrong.",
    UserNotFoundException: "Username not found.",
    save: "Save",
    success: "Changed successfully",
    error: "Error",
    edit_user: "Edit user",
    create_user: "Create user",
    userEmail: "E-mail",
    admin: "Administrator",
    maintenance_manager: "Service-Manager",
    taskAction: "task action",
    fleet_manager: "Fleet-Manager",
    attachedRoles: "User roles",
    datasets: "Datasets",
    errors: "Errors",
    histories: "Changelogs",
    is_not: "is not",
    "fleet-company": "Fleet company",
    "maintenance-company": "Maintenance Company",
    translations: "Translations",
    menu: "Menu",
    privacy_policy: "Privacy policy",
    privacy_policy_link: "https://vsc.bike/en/privacy-policy/portal",
    terms_and_conditions: "Terms of use",
    terms_and_conditions_link:
      "https://vsc.bike/en/general-terms-and-conditions/portal",
    legal_notice: "Legal notice",
    edit_changed_component_desc:
      "Would you like to edit the new, replaced component now?",
    edit_changed_component_title: "Edit exchanged component",
    change_password: "Change password",
    new_password: "New password",
    password_constraints: `Your password must be at least 8 characters long, contain one uppercase letter and one lowercase letter a number and a special character.`,
    try_again_require_new_password: "Please try again",
    hmi: "HMI",
    front_tire: "front tire",
    rear_tire: "rear tire",
    front_rim: "front rim",
    rear_rim: "rear rim",
    chain: "chain",
    pedals: "pedals",
    front_brake: "front brake",
    rear_brake: "rear brake",
    front_brake_pads: "front brake pads",
    rear_brake_pads: "rear brake pads",
    front_brake_lever: "front brake lever",
    rear_brake_lever: "rear brake lever",
    front_brake_rotor: "front brake rotor",
    rear_brake_rotor: "rear brake rotor",
    front_brake_cable: "front brake cable",
    rear_brake_cable: "rear brake cable",
    rear_shifter_cable: "rear shifter cable",
    middle_shifter_cable: "middle shifter cable",
    shift_lever: "shift lever",
    battery: "battery",
    motor: "motor",
    speed_sensor: "speed sensor",
    flat: "flat",
    loosen_spoke: "loosen spoke",
    broken_spoke: "broken spoke",
    broken: "broken",
    demolished: "demolished",
    deformed: "deformed",
    cracked: "cracked",
    loosen: "loosen",
    shaft_broken: "shaft broken",
    bearing_broken: "bearing broken",
    bearing_rough: "bearing rough",
    mounting_part_loosen: "mounting part loosen",
    torn: "torn",
    defect: "defect",
    operational: "operational",
    retired: "retired",
    inactive: "inactive",
    maintenance: "maintenance",
    mechanical: "mechanical",
    electrical: "electrical",
    other: "other",
    open: "open",
    in_progress: "in progress",
    closed: "closed",
    repaired: "repaired",
    replaced: "replaced",
    no_action: "no defect on the vehicle",
    vehicle_not_present: 'vehicle not present',
    settings: "Settings",
    importer: "Importer",
    sync_vehicle_components: "Synchronize vehicle components",
    "componentType": "type",
    "componentName": "component",
    "componentModel": "model",
    "componentStatus": "status",
    "mileageCurrent": "mileage",
    "syncedAt": "synced at",
    "frame": "frame",
    "componentCategory": "part category",
    "controls": "controls",
    "brakes": "brakes",
    "displays": "displays",
    "drive": "drive",
    "fenders": "fenders",
    "handlebar": "handlebar",
    "lights": "lights",
    "reflectors": "reflectors",
    "stands": "stands",
    //PIN AG translations
    "drink_holder": "drink holder",
    "front_basket": "front basket",
    "rear_basket": "rear basket",
    "corrosion_condition": "corrosion condition",
    "key_box": "key box",
    "fairing": "fairing",
    "screws": "screws",
    "front_bumper": "front bumper",
    "rear_bumper": "rear bumper",
    "windshield": "windshield",
    "cargo_space_doors": "cargo space doors",
    "cargo_space_shelving_system": "cargo space shelving system",
    "cargo_box": "cargo box",
    "lid": "lid",
    "drawbar": "drawbar",
    "coupling": "coupling",
    "tension_strap": "tension strap",
    "safety_strap": "safety strap",
    "fork": "fork",
    "saddle": "saddle",
    "seat_post": "seat post",
    "seat": "seat",
    "bell": "bell",
    "grips": "grips",
    "headset": "headset",
    "gear_shift": "gear shift",
    "battery_switch": "battery switch",
    "rear_view_mirror": "rear view mirror",
    "horn": "horn",
    "heated_windshield": "heated windshield",
    "heated_grips": "heated grips",
    "windshield_wiper": "windshield wiper",
    "miscellaneous_switches": "miscellaneous switches",
    "general_speedometer": "general speedometer",
    "fuel_display_range": "fuel display range",
    "oil_display": "oil display",
    "engine_control_light": "engine control light",
    "actuating_lever": "actuating lever",
    "wheels": "wheels",
    "wheel_front": "wheel front",
    "wheel_rear": "wheel rear",
    "spring": "spring",
    "main_stand": "main stand",
    "side_stand": "side stand",
    "front_brakes": "front brakes",
    "rear_brakes": "rear brakes",
    "parking_brake": "parking brake",
    "foot_brake": "foot brake",
    "front_running_light": "front running light",
    "rear_running_light": "rear running light",
    "dynamo": "dynamo",
    "parking_light": "parking light",
    "dimmed_headlight": "dimmed headlight",
    "high_beam": "high beam",
    "tail_light": "tail light",
    "brake_light": "brake light",
    "turn_indicator": "turn indicator",
    "front_reflector": "front reflector",
    "rear_reflector": "rear reflector",
    "spoke_reflectors_front": "spoke reflectors front",
    "spoke_reflectors_rear": "spoke reflectors rear",
    "tread_depth_wear_front": "tread depth wear front",
    "tread_depth_wear_front_left": "tread depth wear front left",
    "tread_depth_wear_front_right": "tread depth wear front right",
    "tread_depth_wear_rear": "tread depth wear rear",
    "tread_depth_wear_rear_left": "tread depth wear rear left",
    "tread_depth_wear_rear_right": "tread depth wear rear right",
    "tire_pressure_front": "tire pressure front",
    "tire_pressure_front_left": "tire pressure front left",
    "tire_pressure_front_right": "tire pressure front right",
    "tire_pressure_rear": "tire pressure rear",
    "tire_pressure_rear_left": "tire pressure rear left",
    "tire_pressure_rear_right": "tire pressure rear right",
    "spokes_front": "spokes front",
    "spokes_rear": "spokes rear",
    "spokes_front_left": "spokes front left",
    "spokes_front_right": "spokes front right",
    "spokes_rear_left": "spokes rear left",
    "spokes_rear_right": "spokes rear right",
    "rims_front": "rims front",
    "rims_rear": "rims rear",
    "rims_front_left": "rims front left",
    "rims_front_right": "rims front right",
    "rims_rear_left": "rims rear left",
    "rims_rear_right": "rims rear right",
    "front_fender": "front fender",
    "rear_fender": "rear fender",
    "chain_belt": "chain/belt",
    "chain_guard_defect": "chain guard defect",
    "tension_defect": "tension faulty",
    "casing_defect": "casing defect",
    "wirings_defect": "wirings defect",
    "contacts_defect": "contact defect",
    "bottom_bracket": "bottom bracket",
    "front_sprocket": "front sprocket",
    "rear_sprocket": "rear sprocket",
    "crank": "crank",
    "engine": "engine",
    "battery_mount": "battery mount",
    "wiring": "wiring",
    "does_not_start": "does not start",
    "shows_error_message": "shows error message",
    "casing_damaged": "casing damaged",
    "fluid_leak": "fluid leak",
    "contacts": "contacts",
    "miscellaneous": "miscellaneous",
    //PIN AG translations
    //PENDIX  translations
    "tire": "tire",
    "puncture": "flat tire",
    "front_tire_tread": "front tire tread",
    "worn": "worn",
    "front_tire_sidewall": "front tire sidewall",
    "damaged": "damaged",
    "rear_tire_tread": "rear tire tread",
    "rear_tire_sidewall": "rear tire sidewall",
    "front_spoke": "front spoke",
    "wheel": "wheel",
    "front_hub_bearing": "front hub bearing",
    "rough": "rough",
    "rear_spoke": "rear spoke",
    "rear_hub_bearing": "rear hub bearing",
    "drive_train": "drive train",
    "bottom_bracket_shaft_crank_thread": "bottom bracket middle shaft crank thread",
    "torn_out": "torn out",
    "bottom_bracket_shaft": "bottom bracket middle shaft",
    "bottom_bracket_bearing": "bottom bracket middle bearing",
    "bottom_bracket_mounting_part": "bottom bracket mounting part",
    "front_disc_brake": "front disc brake",
    "brake": "brake",
    "front_disc_brake_brake_line": "front disc brake brake line",
    "leaking": "leaking",
    "rear_disc_brake": "rear disc brake",
    "rear_disc_brake_brake_line": "rear disc brake brake line",
    "front_disc_brake_pads": "front disc brake pads",
    "rear_disc_brake_pads": "rear disc brake pads",
    "front_disc_brake_lever": "front disc brake lever",
    "rear_disc_brake_lever": "rear disc brake lever",
    "parking_brake_lever_hydraulic": "hydraulic parking brake lever",
    "front_disc_brake_brake_disc": "front disc brake brake disc",
    "rear_disc_brake_brake_disc": "rear disc brake brake disc",
    "rear_shifter_cable_housing": "rear shifter cable housing",
    "shifting": "shifting",
    "front_shifter_cable_housing": "front shifter cable housing",
    "front_shifter_cable": "front shifter cable",
    "front_shift_lever": "front shift lever",
    "rear_shift_lever": "rear shift lever",
    "display_motor": "display motor",
    "electronic": "electronic",
    "luggage_rack_front": "front luggage rack",
    "luggage_rack": "luggage rack",
    "bent": "bent",
    "luggage_rack_front_stand": "front luggage rack stand",
    "stand": "stand",
    "luggage_rack_rear": "rear luggage rack",
    "saddle_surface": "saddle surface",
    "saddle_frame": "saddle frame",
    "seat_clamp": "seat clamp",
    "bar": "handlebar",
    "stem": "stem",
    "front_fender_sheet": "front fender",
    "fender": "fender",
    "front_fender_brace": "front fender brace",
    "rear_fender_sheet": "rear fender",
    "rear_fender_brace": "rear fender brace",
    "frame_single_track_short": "frame single track short",
    "frame_single_track_long": "frame single track long",
    "frame_multi_track_short": "frame multi track short",
    "frame_multi_track_long": "frame multi track long",
    "lighting_front_headlight": "front headlight",
    "lighting": "lighting",
    "lighting_front_lamp": "front lamp",
    "lighting_front_cabel": "front cable",
    "lighting_rear_taillight": "rear taillight",
    "lighting_rear_lamp": "rear lamp",
    "lighting_rear_cabel": "rear cable",
    "lighting_frame_dynamo": "frame dynamo",
    "forkes_shaft": "fork shaft",
    "forkes_stand_pipe": "fork stand pipe",
    "headsets": "headsets",
    "chain_ring_front": "front chainring",
    "crank_arm_left": "left crank arm",
    "crank_arm_right": "right crank arm",
    "chain_ring_rear": "rear chainring",
    "cassette_rear": "rear cassette",
    "front_derailleur": "front derailleur",
    "misadjusted": "misadjusted",
    "rear_derailleur": "rear derailleur",
    "gear_hub_gearbox": "gear hub gearbox",
    "gear_hub_gearshift_chain": "gear hub gearshift chain",
    "gear_hub": "gear hub",
    "mirror": "mirror",
    "center_bike_stand": "center bike stand",
    "center_side_bike_stand": "center side bike stand",
    "side_bike_stand": "side bike stand",
    "motor_rosenberg_plug": "motor rosenberg plug",
    "not_calibratable": "not calibratable",
    "motor_plug": "motor plug",
    "motor_housing": "motor housing",
    "motor_torque_arm": "motor torque arm",
    "motor_bearing": "motor bearing",
    "generator_crank_pedal_thread_right": "generator crank pedal thread right",
    "generator": "generator",
    "generator_crank_pedal_thread_left": "generator crank pedal thread left",
    "generator_housing": "generator housing",
    "generator_crank_square_shaft_thread_right": "generator crank square shaft thread right",
    "generator_crank_square_shaft_thread_left": "generator crank square shaft thread left",
    "generator_motor_bearing": "generator motor bearing",
    "generator_square_mount": "generator square mount",
    "motor_generator_housing": "motor generator housing",
    "coloured_flashing": "coloured flashing",
    "error_code": "error code",
    "battery_shell": "battery shell",
    "battery_rosenberg_plug": "battery rosenberg plug",
    "battery_usb_plug": "battery usb plug",
    "battery_rotary_switch": "battery rotary switch",
    "not_charging": "not charging",
    "not_connecting": "not connecting",
    "overheated": "overheated",
    "bottom_bracket_torque_sensor": "bottom bracket e-torque sensor",
    "front_drum_brake": "front drum brake",
    "front_drum_brake_brake_cable": "front drum brake brake cable",
    "coaster_brake": "coaster brake",
    "front_drum_brake_pads": "front drum brake pads",
    "front_drum_brake_lever": "front drum brake lever",
    "front_rim_brake": "front rim brake",
    "front_rim_brake_brake_cable": "front rim brake brake cable",
    "front_rim_brake_pads": "front rim brake pads",
    "front_rim_brake_lever": "front rim brake lever",
    "parking_brake_lever_mechanical": "mechanical parking brake lever",
    "lighting_hub_dynamo": "lighting hub dynamo"
  },
};
