import React from "react";

const TermsOfUse_DE = () => {
  return (
    <>
      <div className="row">
        <div className="col-6">
          <p>
            <strong>Angaben gemäß § 5 TMG:</strong>
            <br /> VSC Bike GmbH
            <br /> Breite Str. 32
            <br /> 06542 Allstedt
            <br />
            Deutschland
          </p>
          <p>
            <strong>Verantwortlicher im Sinne von § 18 Abs. 2 MStV:</strong>
            <br /> Thomas Herzog
            <br /> Innere Schneeberger Straße 20
            <br /> 08056 Zwickau
            <br /> Deutschland
            <br />
            <br />
            <strong>Die VSC Bike GmbH wird vertreten durch:</strong>
            <br /> Thomas Herzog (CEO)
            <br /> Christian Hennig (CTO)
            <br />
            <br />
            <strong>Kontakt:</strong>
            <br /> Telefon: +49 34652 67 16 - 0<br /> Telefax: +49 34652 67 16 -
            19
            <br /> E-Mail:
            <a
              href="mailto:info@vsc.bike"
              title="info@vsc.bike"
              class="link-mail"
            >
              info(at)vsc.bike
            </a>
          </p>
          <p>
            <br />
            <strong>Registereintrag:</strong>
            <br /> Eintragung im Handelsregister.
            <br /> Registergericht: Amtsgericht&nbsp;Stendal
            <br /> Registernummer: HRB 23979
            <br />
            <br />
            <br /> &nbsp;
          </p>
        </div>
        <div class="col-6">
          <p>
            <strong>Umsatzsteuer:</strong>
            <br /> Umsatzsteuer-Identifikationsnummer gemäß §27 a
            Umsatzsteuergesetz:
            <br /> DE 815673590
          </p>
          <p>
            <strong>Streitschlichtung:</strong>
            <br /> Wir sind nicht bereit oder verpflichtet, an
            Streitbeilegungsverfahren vor einer Verbraucherschlichtungsstelle
            teilzunehmen.{" "}
            <a
              href="https://ec.europa.eu/consumers/odr/main/index.cfm?event=main.home.chooseLanguage"
              target="_blank"
              rel="noreferrer"
            >
              Link
            </a>
          </p>
        </div>
      </div>
    </>
  );
};
export default TermsOfUse_DE;
