import { Colors } from 'constants/colors';
import dayjs from 'dayjs';
import { t } from 'i18next';
import React, { useState } from 'react';
import { Col } from 'react-bootstrap';
import 'react-calendar/dist/Calendar.css';
import { RangePicker } from "react-ease-picker";
import { BiCalendar } from 'react-icons/bi';
import { useSelector } from 'react-redux';
import { capitalize, langIsoMapper } from 'utils/general';

function DateRangeFilter({ onDateRangeSelected, defaultStartDate, defaultEndDate, position = 'right' }) {
    const [startDate, setStartDate] = useState(defaultStartDate || undefined);
    const [endDate, setEndDate] = useState(defaultEndDate || undefined);
    const lang = useSelector(state => state.settings.lang)
    const day = dayjs();

    return (
        <>
            <Col md="auto">
                <div style={{ background: '#f7f7f9' }} className='d-flex border-secondary justify-content-center flex-row align-items-center'>
                    <div style={{ paddingLeft: '1rem' }}><BiCalendar size={26} /></div>
                    <RangePicker
                        css={` :host {
                            --color-fg-primary: ${Colors.primary};
                            --color-btn-primary-bg: ${Colors.primary};
                            --color-btn-primary-border: ${Colors.primary};
                            --color-btn-primary-hover-bg:${Colors.primary};
                            --color-btn-primary-hover-border:${Colors.primary};
                            --color-bg-inrange:${Colors.primary}2a;
                            --color-btn-primary-disabled-bg:${Colors.primary}2a;
                            --color-btn-primary-disabled-border:${Colors.primary}2a;
                        }`}
                        startDate={startDate || undefined}
                        placeholder={t('full_period')}
                        lang={langIsoMapper(lang)}
                        endDate={endDate || undefined}
                        className="border-0 z-index-highest form-control w-100 range-picker"
                        key={'DateTimePickerFilter'}
                        autoApply={false}
                        calendars={1}
                        daysLocale={{ one: t('day'), other: t('days') }}
                        position={position}
                        onSelect={(start, end) => {
                            setStartDate(start);
                            setEndDate(end);
                            onDateRangeSelected(start, end);

                        }}
                        locale={{ apply: t('apply'), cancel: capitalize(t('cancel')) }}

                        presets={[
                            {
                                label: t('last_week'),
                                startDate: day.subtract(1, 'week').format('YYYY-MM-DD'),
                                endDate: day.add(1, 'day'),
                            },
                            {
                                label: t('last_month'),
                                startDate: day.subtract(1, 'month').format('YYYY-MM-DD'),
                                endDate: day.add(1, 'day'),
                            },
                            {
                                label: t('last_year'),
                                startDate: day.subtract(1, 'year').format('YYYY-MM-DD'),
                                endDate: day.add(1, 'day'),
                            },
                        ]}

                    />

                </div>
            </Col>
        </>
    );
}

export default DateRangeFilter;