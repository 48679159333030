import dayjs from "dayjs";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { Accordion, Badge, ListGroup } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { get } from "utils/api";

export const HistoryModal = ({ id, navigate }) => {
    const [history, setHistory] = useState();
    const [users, setUsers] = useState();



    useEffect(() => {
        const initialRequest = async () => {
            const historyRes = await get('/histories', {
                queryParams: {
                    modelId: id
                }
            })
            const userRes = await get('/users');
            if (historyRes?.histories) setHistory(historyRes.histories.sort((a, b) => a.createdAt < b.createdAt ? -1 : a.createdAt > b.createdAt ? 1 : 0)?.reverse());
            if (userRes?.users) setUsers(userRes.users);
        }
        initialRequest();
    }, [])

    const renderContentArea = (content) => {
        return (
            <ListGroup>
                {content && Object.keys(content).map((o) => {
                    return (
                        <Accordion.Body>
                            <div className="ms-2 me-auto">
                                <div className="fw-bold">{t(o)}</div>
                                {typeof content[o] === 'string' || content[o] === 'number' ?
                                    content[o] :
                                    content[o] !== null && typeof content[o] !== 'undefined' ? JSON.stringify(content[o]) : t('no_data')}
                            </div>
                        </Accordion.Body>
                    )
                })
                }
            </ListGroup>
        )
    }


    return (
        <Accordion>
            {history ? history?.map((historyItem, i) =>
                <Accordion.Item key={`acc_history_${i}`} eventKey={i}>
                    <Accordion.Header>{t(historyItem.action)}
                        <div className="d-flex flex-row justify-content-start align-items-center">
                            <Badge className="ms-2" bg="light">{dayjs(historyItem.createdAt).format('DD.MM.YYYY HH:mm:ss')}</Badge>
                            <Badge className="ms-2" bg="dark">{users?.find(u => u?.id === historyItem?.createdBy)?.userEmail}</Badge>
                        </div>
                    </Accordion.Header>
                    <Accordion.Body>
                        <div className="d-flex flex-column justify-content-center align-items-center w-100">
                            <ListGroup className="w-100 border-0" key={`acc_history_key${i}_1`}>
                                <ListGroup.Item>
                                    <div className="ms-2 me-auto">
                                        <div className="fw-bold">{t('createdBy')}</div>
                                        <span role="button" className="text-decoration-underline" onClick={() => { window.open(`/edit/users/${historyItem.createdBy}?action=edit`) }}>
                                            {users?.find(u => u.id === historyItem.createdBy)?.userEmail}
                                        </span>
                                    </div>
                                </ListGroup.Item>
                            </ListGroup>
                            <Accordion className="w-100 m-0 p-0">
                                <Accordion.Item key={`acc_history_nested_${i}_1`} eventKey={'nested_1_' + i}>
                                    <Accordion.Header>{t('contentChanged')}</Accordion.Header>
                                    <Accordion.Body>{renderContentArea(historyItem.contentChanged)}</Accordion.Body>
                                </Accordion.Item>
                                <Accordion.Item key={`acc_history_nested_${i}_2`} eventKey={'nested_2_' + i}>
                                    <Accordion.Header>{t('contentBefore')}</Accordion.Header>
                                    <Accordion.Body>{renderContentArea(historyItem.contentBefore)}</Accordion.Body>
                                </Accordion.Item>
                            </Accordion>


                        </div>
                    </Accordion.Body>
                </Accordion.Item>
            ) :
                <ContentLoader height={250} width={'100%'}>
                    <rect x="2.5%" y="5%" width='95%' height="10%" />
                    <rect x="2.5%" y="17%" width='95%' height="10%" />
                    <rect x="2.5%" y="29%" width='95%' height="10%" />
                    <rect x="2.5%" y="41%" width='95%' height="10%" />
                </ContentLoader>
            }
        </Accordion >
    )
}