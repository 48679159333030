import { CONSTANTS } from '@constants';
import React from 'react';
import { DateField } from '../../../../stories/crud/FieldTypes/DateField';
import { DateNextPlannedAt } from './DateNextPlannedAt';
import { DatePlannedAt } from './DatePlannedAt';

export const DateMaintenanceTasks = (props) => {
    switch (props?.attr) {
        case 'nextPlannedAt': {
            const data = props.formData;
            if (data.taskCategory === 'uvv' && data.taskStatus === CONSTANTS.STATUS.CLOSED) {

                return <DateNextPlannedAt {...props} />
            }
            return <></>
        }
        case 'plannedAt': {
            const data = props.formData;
            return data.taskStatus === 'planned' ?
                <DatePlannedAt {...props} /> :
                <></>
        }
        default: {
            return <DateField {...props} />
        }
    }
}