export const sortByArray = (arr, sortingArr) => {
  let array = [...arr];

  const has = (a, val) => (a?.indexOf(val) === -1 ? false : true);

  array.sort((a, b) => {
    //if the prop is present, sort it in front
    if (has(sortingArr, a) && has(sortingArr, b)) {
      return sortingArr.indexOf(a) > sortingArr.indexOf(b) ? 1 : -1;
    }
    if (has(sortingArr, a) && !has(sortingArr, b)) {
      return -1;
    }
    if (!has(sortingArr, a) && has(sortingArr, b)) {
      return 1;
    }
    if (!has(sortingArr, a) && !has(sortingArr, b)) {
      return 1;
    }

    return 1;
  });
  return array;
};

export const extractOperator = (value) => {
  let temp = { value, operator: "=" };
  const operators = ["<", ">", "<>", "_contains", "_not_contains", "_begins_with", "_in", "_exists", "_not_exists", "_is_true", "_is_false"];
  for (let i in operators) {
    if (value?.startsWith(operators[i])) {
      temp.value = value
        .replace(operators[i], "")
        .replace(")", "")
        .replace("(", "");
      temp.operator = operators[i];
      if (temp.operator === '_is_true') temp.value = true;
      if (temp.operator === '_is_false') temp.value = false;
      break;
    }
  }

  return temp;
};

export const uniqueMergeByKey = (arrays, key) => {
  const map = new Map();

  arrays.forEach((array) => {
    if (array && Array.isArray(array)) {
      array.forEach((item) => {
        if (!map.has(item[key])) {
          map.set(item[key], item);
        }
      });
    }
  });

  return Array.from(map.values());
}

export const chunkArray = (arr, chunkSize) => {
  const chunks = [];
  for (let i = 0; i < arr.length; i += chunkSize) {
    chunks.push(arr.slice(i, i + chunkSize));
  }
  return chunks;
};

export const executePromisesInChunks = async (promises, timeout = 0, chunkSize = 1) => {
  const results = [];
  for (let i = 0; i < promises.length; i += chunkSize) {
    const chunk = promises.slice(i, i + chunkSize);
    const chunkResults = await Promise.all(chunk.map(promise => promise()));
    if (timeout > 0) await asyncDelay(timeout);
    results.push(...chunkResults);
  }

  return results;
}

export function sortArrayWithConfig(array, config) {
  if (!array || !Array.isArray(array)) return array;

  return array.sort((a, b) => {
    for (let key in config) {
      if (config.hasOwnProperty(key)) {
        let direction = config[key];
        let valueA = a[key];
        let valueB = b[key];

        if (typeof (valueA) === 'object' && valueA?.sortByValue) valueA = valueA?.sortByValue;
        if (typeof (valueB) === 'object' && valueB?.sortByValue) valueB = valueB?.sortByValue;

        if (!valueA && valueB) return 1;
        if (valueA && !valueB) return -1;
        if (!valueA && !valueB) return 0;

        let dateA = new Date(valueA);
        let dateB = new Date(valueB);
        let isDateAValid = !isNaN(dateA.getTime());
        let isDateBValid = !isNaN(dateB.getTime());

        let comparison = 0;

        if (isDateAValid && isDateBValid) {
          comparison = dateA - dateB;
        } else {
          let isNumeric = !isNaN(parseFloat(valueA)) && isFinite(valueA) && !isNaN(parseFloat(valueB)) && isFinite(valueB);

          if (isNumeric) {
            comparison = valueA - valueB;
          } else {
            comparison = valueA.toString().localeCompare(valueB.toString());
          }
        }

        // Wenn die Werte nicht gleich sind, wende die Sortierrichtung an und breche die Schleife
        if (comparison !== 0) {
          return direction === 'asc' ? comparison : -comparison;
        }
        // Wenn die Werte gleich sind, fahre mit dem nächsten Schlüssel fort
      }
    }
    // Wenn alle Vergleiche gleich sind, behalte die ursprüngliche Reihenfolge bei
    return 0;
  });
}

export const langIsoMapper = (lang) => {
  const map = {
    "de": "de-DE",
    "en": "en-EN"
  };
  return map?.[lang] || 'en-EN'
}

export const capitalize = (str) => {
  if (typeof str !== 'string') return str;
  return str.charAt(0).toUpperCase() + str.slice(1);
}

export const cloneNestedObject = (obj) => {
  try { return JSON.parse(JSON.stringify(obj)) } catch (e) {
    console.warn('could not clone nested obj,', e, obj);
    return obj;
  }
}
export const asyncDelay = (delay = 1000) => {
  return new Promise((resolve) => {
    setTimeout(() => resolve(true), delay);
  });
};