import PropTypes from 'prop-types';
import React, { useState } from 'react';

import { useEffect } from 'react';

import Col from 'react-bootstrap/esm/Col';
import Container from 'react-bootstrap/esm/Container';
import Row from 'react-bootstrap/esm/Row';

import { AiOutlinePoweroff } from 'react-icons/ai';
import { FaCheck, FaCogs } from 'react-icons/fa';
import { GiRadioTower } from 'react-icons/gi';
import { GoAlert, GoMilestone } from 'react-icons/go';
import { MdOutlineDirectionsBike } from 'react-icons/md';

import { CONSTANTS } from '@constants';
import dayjs from 'dayjs';
import { t } from 'i18next';
import moment from 'moment';
import Button from 'react-bootstrap/Button';
import ContentLoader from 'react-content-loader';
import { BiEdit, BiLock, BiPlusCircle, BiWrench } from 'react-icons/bi';
import { RiBatteryLowFill } from 'react-icons/ri';
import { useNavigate } from 'react-router';
import { AbortManager } from 'utils/AbortManager';
import { assignPostTransformations } from '../../utils/postTransformations';
import { PieChart } from '../PieChart';
import { Colors } from '../utils/colors';
import { CollectionList } from './CollectionList';

const PieChartAndList = ({ vehicle, loading, getTableHeads, postTransformations, getRequest }) => {
    const [vehicleHistoryFilter, setVehicleHistoryFilter] = useState('all');

    return (
        <Col xs={12}>
            <PieChart
                collectionType={CONSTANTS.namespaces.VEHICLES}
                onShowAll={() => setVehicleHistoryFilter('all')}
                onSectionClick={(item) => setVehicleHistoryFilter(item?.status)}
                minHeight={'50%'}
                isLoading={loading.vehicles}
                title={t("vehicle_history")}
                data={[
                    { name: t('operational'), status: 'operational', y: vehicle?.vehicleHistory?.filter((item) => item.vehicleStatus === 'operational').length, color: Colors.success },
                    { name: t('defect'), status: 'defect', y: vehicle?.vehicleHistory?.filter((item) => item.vehicleStatus === 'defect').length, color: Colors.danger },
                    { name: t('maintenance'), status: 'maintenance', y: vehicle?.vehicleHistory?.filter((item) => item.vehicleStatus === 'maintenance').length, color: Colors.info },
                    { name: t('retired'), status: 'retired', y: vehicle?.vehicleHistory?.filter((item) => item.vehicleStatus === 'retired').length, color: Colors.dark },
                ]}
            />
            {vehicle?.vehicleHistory && vehicle?.vehicleHistory?.length > 0 &&
                <CollectionList
                    showProgress={false}
                    showExpand={true}
                    recognizeFilter={false}
                    tableRowClasses={
                        (row) => {
                            switch (row.vehicleStatus) {
                                case 'maintenance':
                                    return 'border-start border-start-4 border-info';
                                case 'defect':
                                case 'retired':
                                    return 'border-start border-start-4 border-danger';
                                case 'operational':
                                    return 'border-start border-start-4 border-success';
                                default:
                                    return ''
                            }
                        }
                    }
                    tableCellClasses={'p-2 fs-6'}
                    tableClasses={' px-2 w-100 dashboard-table vehicle-history'}
                    showClone={false}
                    showCreate={false}
                    showDelete={false}
                    showEdit={false}
                    showImporter={false}
                    showFilter={false}
                    showCheckboxes={false}
                    showSort={false}
                    include={['mileage', 'serial', 'vehicleStatus', 'vehicleName', 'changedAt']}
                    exclude={undefined}
                    head={getTableHeads('vehicle-history')}
                    schemeNamePlural={CONSTANTS.namespaces.VEHICLES}
                    postTransformations={postTransformations}
                    getHandler={async (path, init) => {

                        return await getRequest(
                            `/vehicles${path || ''}`,
                            init
                        );
                    }}
                    staticData={vehicle?.vehicleHistory?.filter((h) => {
                        if (vehicleHistoryFilter === 'all') return true;
                        else {
                            return h?.vehicleStatus === vehicleHistoryFilter;
                        }
                    }) || undefined}
                />}
        </Col>
    )
}

export const VehicleDashboard = ({ id, getHandler, redirect, showEditButton = true }) => {
    const [loading, setLoading] = useState({ maintenanceTasks: true, vehicles: true, components: true });
    const [vehicle, setVehicle] = useState({});
    const [fleet, setFleet] = useState({});
    const [mounted, setMounted] = useState(false);
    const [dateValues, setDateValues] = useState({ vehicleHistory: { from: undefined, to: undefined } });
    const listId = `vehicle_dashboard_${new Date().toISOString()}`;
    const abortManager = { id: listId, abortManager: AbortManager };
    const navigate = useNavigate();

    const postTransformations = {
        createdAt: function (val) {
            return moment(val).format("DD.MM.YYYY  HH:mm:ss");
        },
        changedAt: function (val) {
            return moment(val).format("DD.MM.YYYY  HH:mm:ss");
        },
    };

    const getRequest = async (path, init) => {
        try {
            const res = await getHandler(path, { ...init, abortManager });
            return res;
        } catch (e) {
            console.warn(e);
        }
    }
    const getTableHeads = (type) => {
        switch (type) {
            case 'vehicle-history':
                return ['mileage', 'serial', 'vehicleStatus', 'vehicleName', 'changedAt'];
            case 'vehicles':
                return ['serial', 'vehicleStatus', 'vehicleName', 'changedAt'];
            case 'maintenance-tasks':
                return ['taskStatus', 'taskCategory', 'taskAction', 'componentId', 'componentDefect', 'description', 'createdAt', 'plannedAt'];
            case 'components':
                return ['componentStatus', 'componentName', 'serial', 'componentType', 'componentInstallationState', 'mileageCurrent', 'createdAt', 'changedAt'];
            default:
                return;
        }
    }

    const handleRedirect = (path) => {
        redirect(path)
    }

    useEffect(() => {
        if (!id) return;
        if (mounted) return;
        try { apiRequests(); } catch (e) { console.warn(e) }
        setMounted(true)
        return () => {
            abortManager.abortManager.cancel(listId);
        }
    }, [id]);

    const apiRequests = async () => {
        let tempLoading = { ...loading };
        const res = await getRequest('/vehicles/' + id)
        if (res?.vehicles) setVehicle(res.vehicles);
        const resFleet = await getRequest('/fleets/' + res?.vehicles?.fleetId)
        if (resFleet?.fleets) setFleet(resFleet.fleets);
        tempLoading.vehicles = false;
        tempLoading.components = false;
        tempLoading.maintenanceTasks = false;
        setLoading({ ...tempLoading });
    }

    const renderCard = (header, body, footer, customCssClasses = 'flex-row justify-content-around h-100', style) => {
        return (
            <div style={{ height: '100%', ...style }} className="py-3 py-sm-0 shadow-sm bg-light dashboard-card-container">
                <div className={'dashboard-card-wrapper ' + customCssClasses}>
                    <div className='dashboard-card-header px-2'>
                        {header}
                    </div>
                    <div className='dashboard-card-body'>
                        {body}
                    </div>
                    <div className='dashboard-card-footer'>
                        {footer}
                    </div>
                </div>
            </div>
        )
    }
    const getStatusItem = (status) => {
        switch (status) {
            case 'operational':
                return (
                    <div className=" d-flex border-success bg-success text-light fw-bold justify-content-center align-items-center dashboard-icon">
                        <FaCheck size={20} />
                    </div>
                )
            case 'defect':
                return (
                    <div className=" d-flex border-danger bg-danger text-light fw-bold justify-content-center align-items-center dashboard-icon">
                        <GoAlert size={20} />
                    </div>
                )
            case 'maintenance':
                return (
                    <div className=" d-flex border-danger bg-info text-light fw-bold justify-content-center align-items-center dashboard-icon">
                        <BiWrench size={20} />
                    </div>
                )
            case 'retired':
                return (
                    <div className=" d-flex border-danger bg-dark text-light fw-bold justify-content-center align-items-center dashboard-icon">
                        <AiOutlinePoweroff size={20} />
                    </div>
                )
            case 'low_battery':
                return (
                    <div className=" d-flex border-warning bg-warning text-light fw-bold justify-content-center align-items-center dashboard-icon">
                        <RiBatteryLowFill size={20} />
                    </div>
                )
            default:
                return
        }
    }

    const LoadingContent = () => <ContentLoader><rect x="0" y="40%" width='50%' height="15%" /></ContentLoader>;

    return (
        <div>
            <Container className='mt-4 px-4' fluid>
                {showEditButton && <Button disabled={fleet?.lockedAt} key={'fleet_dash_button_edit'} onClick={() => handleRedirect(`/edit/vehicles/${id}?action=edit`)} variant="link" style={{ textDecoration: 'none', paddingLeft: 0 }}><BiEdit size={24} /> <span>{t('edit_vehicle', { name: '' })}</span></Button>}
                {fleet?.lockedAt && (<><BiLock color={Colors.danger} size={24} /> <span className="text-danger">{t("fleet_is_locked")}</span></>)}
                <Row className='g-2 min-vh-25 mb-3'>
                    <Col xs={12} sm={6} md={3}>
                        {renderCard(
                            loading.vehicles ? null : getStatusItem(vehicle?.vehicleStatus),
                            loading.vehicles ? <LoadingContent /> : (
                                <Row>
                                    <Col xs={6} md={12}>
                                        <div>
                                            <div className='ms-2 fs-6 text-small fw-bold'>{fleet?.fleetName ?
                                                <span
                                                    key={"link_fleet_Id"}
                                                    role="button"
                                                    className="text-decoration-underline"
                                                    onClick={() => {
                                                        navigate("/fleet-dashboard/" + fleet?.id);
                                                    }}
                                                >
                                                    {fleet?.fleetName}
                                                </span>

                                                : ''}</div>
                                            <div className='ms-2 fs-6 text-small text-muted text-uppercase'>{t('fleet')}</div>
                                        </div>
                                    </Col>
                                    <Col xs={6} md={12}>
                                        <div>
                                            <div className='ms-2 fs-6 text-small fw-bold'>{t(vehicle?.vehicleStatus)}</div>
                                            <div className='ms-2 fs-6 text-small text-muted text-uppercase'>{t('status')}</div>
                                        </div>
                                    </Col>

                                </Row>
                            ), undefined, undefined, { height: '100%' }
                        )}
                    </Col>
                    <Col xs={12} sm={6} md={3} >
                        {
                            renderCard(
                                <div style={{ background: '#2561115e', color: '#256111' }} className=" d-flex justify-content-center align-items-center dashboard-icon">
                                    <MdOutlineDirectionsBike size={23} />
                                </div>,
                                loading.vehicles ? <LoadingContent /> : (
                                    <Row>
                                        {/* <Col xs={6} md={12}>
                                            <div>
                                                <div className='ms-2 fs-6 text-small fw-bold'>{vehicle.model}</div>
                                                <div className='ms-2 fs-6 text-small text-muted text-uppercase'>{t('model')}</div>
                                            </div>
                                        </Col> */}
                                        <Col xs={6} md={12}>
                                            <div>
                                                <div className='ms-2 fs-6 text-small fw-bold'>{vehicle.serial}</div>
                                                <div className='ms-2 fs-6 text-small text-muted text-uppercase'>{t('serial')}</div>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={12}>
                                            <div>
                                                <div className='ms-2 fs-6 text-small fw-bold'>{vehicle.vehicleType}</div>
                                                <div className='ms-2 fs-6 text-small text-muted text-uppercase'>{t('type')}</div>
                                            </div>
                                        </Col>
                                        <Col xs={6} md={12}>
                                            <div>
                                                <div className='ms-2 fs-6 text-small fw-bold'>{vehicle.vehicleName}</div>
                                                <div className='ms-2 fs-6 text-small text-muted text-uppercase'>{t('name')}</div>
                                            </div>
                                        </Col>
                                    </Row>

                                ), undefined, undefined, { height: '100%' }
                            )
                        }
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        {
                            renderCard(
                                <div style={{ background: '#86fa5f7d', color: '#5fa049' }} className=" d-flex justify-content-center align-items-center dashboard-icon">
                                    <GiRadioTower size={23} />
                                </div>,
                                loading.vehicles ? <LoadingContent /> : (
                                    <div>
                                        {
                                            <>
                                                <div className='ms-2 fs-6 text-small fw-bold'>{vehicle?.lastPing ? moment(vehicle?.lastPing).format("DD.MM.YYYY  HH:mm:ss") : t('no_data')}</div>
                                                <div className='ms-2 fs-6 text-small text-muted text-uppercase'>{t('last_connection')}</div>
                                            </>
                                        }
                                    </div>
                                ), undefined, undefined, { height: '100%' }
                            )
                        }
                    </Col>
                    <Col xs={12} sm={6} md={3}>
                        {renderCard(
                            <div style={{ background: '#6108544d', color: '#610854' }} className=" d-flex justify-content-center align-items-center dashboard-icon">
                                <GoMilestone size={18} />
                            </div>,
                            loading.vehicles ? <LoadingContent /> : (
                                <>
                                    <div className='ms-2 fs-6 text-small fw-bold'> {vehicle?.mileage}km</div>
                                    <div className='ms-2 fs-6 text-small text-muted text-uppercase'>{t('mileage')}</div>
                                </>
                            ), undefined, undefined, { height: '100%' }
                        )}
                    </Col>
                </Row>
                <Row className='g-2 h-50'>
                    <Col xs={12} md={6}>
                        {
                            renderCard(
                                null
                                ,
                                (
                                    <PieChartAndList
                                        vehicle={vehicle}
                                        loading={loading}
                                        getTableHeads={getTableHeads}
                                        postTransformations={postTransformations}
                                        getRequest={getRequest}
                                    />
                                ),
                                null,
                                'flex-column justify-content-between h-100'
                            )
                        }
                    </Col>
                    <Col xs={12} md={6} className='h-100'>
                        {renderCard(
                            <>
                                <div className="d-flex pt-2 pb-2 justify-space-between">
                                    <FaCogs size={18} />
                                    <div className='ms-1 fs-6 text-uppercase'>{t('maintenance_history')}</div>
                                </div>
                                <Button disabled={loading.maintenanceTasks} onClick={(ev) =>
                                    handleRedirect(`/create/maintenance-tasks?action=create&preDefinedProperties=${JSON.stringify({ fleetId: fleet?.id, vehicleId: vehicle?.id })}`)}
                                    style={{ padding: 0, marginLeft: '0.5rem' }} variant='link'><BiPlusCircle size={20} /></Button>
                            </>,
                            loading.vehicles ? <LoadingContent /> : (
                                <CollectionList
                                    showProgress={false}
                                    showExpand={true}
                                    recognizeFilter={false}
                                    tableRowClasses={
                                        (row) => {
                                            switch (row.taskStatus) {
                                                case 'in_progress':
                                                    return 'border-start border-start-4 border-info';
                                                case 'open':
                                                    return 'border-start border-start-4 border-danger';
                                                case 'closed':
                                                    return 'border-start border-start-4 border-success';
                                                case "planned":
                                                    {
                                                        const isDue = dayjs().isAfter(row?.plannedAt) || dayjs().isSame(row?.plannedAt)
                                                        if (isDue) return 'bg-due td-bg-transparent'
                                                        return `border-start border-start-4 border-dark`;
                                                    }
                                                default:
                                                    return ''
                                            }
                                        }
                                    }
                                    tableCellClasses={'p-2 fs-6'}
                                    tableClasses={' px-2 w-100 dashboard-table'}
                                    showClone={false}
                                    showCreate={false}
                                    showDelete={false}
                                    showEdit={false}
                                    showImporter={false}
                                    showFilter={false}
                                    showCheckboxes={false}
                                    showSort={false}
                                    customSort={(tasks) => {
                                        return tasks.sort((a, b) => b.taskStatus === 'closed' ? -1 : b.taskStatus === 'planned' ? 0 : 1)
                                    }}
                                    getHandler={async (urlExtension, init) => {
                                        return await getRequest(`/${CONSTANTS.namespaces.MAINTENANCE_TASKS}${urlExtension || ''}`, init)
                                    }
                                    }
                                    include={getTableHeads(CONSTANTS.namespaces.MAINTENANCE_TASKS)}
                                    exclude={undefined}
                                    schemeNamePlural={CONSTANTS.namespaces.MAINTENANCE_TASKS}
                                    onClickShow={(id) => {
                                        handleRedirect(`/edit/maintenance-tasks/${id}?action=edit`)
                                    }}
                                    postTransformations={assignPostTransformations(CONSTANTS.namespaces.MAINTENANCE_TASKS, redirect)}
                                    directFilters={[{
                                        filterSearchTerm: vehicle?.id,
                                        filterProperty: "vehicleId",
                                        filterOperator: "="
                                    }]}
                                />
                            ),
                            null,
                            'flex-column justify-content-between',
                            { minHeight: '100%', height: "auto" }
                        )}
                    </Col><Col xs={12} md={12} className='h-100'>
                        {renderCard(
                            <>
                                <div className="d-flex pt-2 pb-2 justify-space-between">
                                    <FaCogs size={18} />
                                    <div className='ms-1 text-uppercase'>{t('components')}</div>
                                    <Button disabled={loading.components} onClick={(ev) => handleRedirect(`/create/components?action=create&preDefinedProperties=${JSON.stringify({ fleetId: fleet?.id, vehicleId: vehicle?.id })}`)}
                                        style={{ padding: 0, marginLeft: '0.5rem' }} variant='link'><BiPlusCircle size={20} /></Button>
                                </div>
                            </>,
                            loading.vehicles ? <LoadingContent /> : (
                                <CollectionList
                                    showProgress={false}
                                    showExpand={true}
                                    recognizeFilter={false}
                                    tableRowClasses={
                                        (row) => {
                                            switch (row.componentStatus) {
                                                case 'maintenance':
                                                case 'in_progress':
                                                    return 'border-start border-start-4 border-info';
                                                case 'defect':
                                                case 'open':
                                                    return 'border-start border-start-4 border-danger';
                                                case 'operational':
                                                case 'closed':
                                                    return 'border-start border-start-4 border-success';
                                                case 'retired':
                                                    return 'border-start border-start-4 border-dark';
                                                case 'low_battery':
                                                case 'planned':
                                                    return 'border-start border-start-4 border-warning';
                                                default:
                                                    return ''
                                            }
                                        }
                                    }
                                    tableCellClasses={'p-2 fs-6'}
                                    tableClasses={' px-2 w-100 dashboard-table'}
                                    showClone={false}
                                    showCreate={false}
                                    showDelete={false}
                                    showEdit={false}
                                    showImporter={false}
                                    showFilter={false}
                                    showCheckboxes={false}
                                    showSort={false}
                                    customSort={(components) => {
                                        return components.sort((a, b) => b.componentStatus === 'retired' ? -1 : b.componentStatus === 'maintenance' ? 0 : 1)
                                    }}
                                    getHandler={async (urlExtension, init) => {
                                        return await getRequest(`/${CONSTANTS.namespaces.COMPONENTS}${urlExtension || ''}`, init)
                                    }
                                    }
                                    include={getTableHeads(CONSTANTS.namespaces.COMPONENTS)}
                                    exclude={undefined}
                                    schemeNamePlural={CONSTANTS.namespaces.COMPONENTS}
                                    onClickShow={(id) => {
                                        handleRedirect(`/edit/${CONSTANTS.namespaces.COMPONENTS}/${id}?action=edit`)
                                    }}
                                    postTransformations={assignPostTransformations(CONSTANTS.namespaces.COMPONENTS, redirect)}
                                    directFilters={[{
                                        filterSearchTerm: vehicle?.id,
                                        filterProperty: "vehicleId",
                                        filterOperator: "="
                                    }]}
                                />
                            ),
                            null,
                            'flex-column justify-content-between h-100',
                            { height: 'fit-content' }
                        )}
                    </Col>
                </Row>
            </Container>
        </div >
    );
};

VehicleDashboard.propTypes = {
    id: PropTypes.string,
    showEditButton: PropTypes.bool
};

VehicleDashboard.defaultProps = {
    id: '0',
    title: 'Vehicle Dashboard'
};


