import { t } from 'i18next';
import React, { useState } from 'react';
import Button from 'react-bootstrap/Button';
import ListGroup from 'react-bootstrap/ListGroup';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { BiMenuAltLeft } from "react-icons/bi";

export const Sidebar = ({ items }) => {
    const [show, setShow] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    return (
        <>
            <Button style={{ color: '#fff' }} variant="link" onClick={handleShow}>
                <BiMenuAltLeft size={26} />
            </Button>

            <Offcanvas show={show} onHide={handleClose}>
                <Offcanvas.Header closeButton>
                    <Offcanvas.Title>{t('menu')}</Offcanvas.Title>
                </Offcanvas.Header>
                <Offcanvas.Body>
                    <ListGroup defaultActiveKey="#link1">
                        {items.map((item, i) => {
                            if (item?.group) {
                                return (
                                    <ListGroup.Item
                                        key={'sidebar_group_' + i}
                                        className='group-heading'
                                        role="button">
                                        <h5>{item.name}</h5>
                                        {
                                            item.group.map(groupItem => {
                                                return (
                                                    <ListGroup.Item
                                                        key={'sidebar_group_item_' + i}
                                                        role="button"
                                                        onClick={(ev) => { groupItem.link(ev); setShow(false); }}>
                                                        {groupItem.name}
                                                    </ListGroup.Item>
                                                )
                                            })
                                        }
                                    </ListGroup.Item>
                                )
                            }
                            return (
                                <ListGroup.Item key={'sidebar_item_' + i} role="button" onClick={(ev) => { item.link(ev); setShow(false); }}>
                                    {item.name}
                                </ListGroup.Item>
                            )
                        })}
                    </ListGroup>
                </Offcanvas.Body>
            </Offcanvas>
        </>
    );
};

