import { t } from "i18next";
import React, { useEffect, useState } from "react";
import {
  FloatingLabel,
  Form,
  ListGroup
} from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { setNavTitle } from "store/globalsSlice";
import { selectIsAdmin } from "store/userSlice";
import { LanguageSelect } from "stories/LanguageSelect";
import { VSCLoader } from "stories/VSCLoader";
import { get, put } from "utils/api";

const ListItemWithHeading = (props) => {
  return (
    <ListGroup.Item title="hello">
      <div className="ms-2 me-auto">
        {props?.heading && <div className="fw-bold">{props?.heading}</div>}
        {props.children}
      </div>
    </ListGroup.Item>
  );
};

export default function Settings() {
  const dispatch = useDispatch();
  const isAdmin = useSelector(selectIsAdmin);
  const [fleets, setFleets] = useState();
  const [loading, setLoading] = useState();
  const [fleet, setFleet] = useState();
  const Picker = () => {
    return (
      <FloatingLabel
        controlId={"floatingTextSelect_"}
        label={t("fleet")}
        className="mb-3"
      >
        {loading && (
          <VSCLoader
            style={{
              zIndex: 3,
              position: "absolute",
              top: "50%",
              left: "2rem",
            }}
          />
        )}
        <Form.Select
          required={true}
          onChange={(ev) => {
            setFleet(fleets.find((f) => f.id === ev.target.value));
          }}
          value={fleet?.id}
        >
          <option></option>
          {fleets?.map((item, i) => {
            return (
              <option key={"floatingOptionKey_" + i} value={item.id}>
                {item.fleetName}
              </option>
            );
          })}
        </Form.Select>
      </FloatingLabel>
    );
  };
  useEffect(() => {
    dispatch(setNavTitle(t("settings")));
    const fetch = async () => {
      setLoading(true);
      try {
        const res = await get("/fleets", {
          queryParams: {
            limit: 0,
          },
        });
        setFleets(res.fleets);
      } finally {
        setLoading(false);
      }
    };
    fetch();
    return () => { };
  }, []);
  const sync = async () => {
    if (!fleet?.id) return;
    setLoading(true);
    try {
      await put(
        `/fleets/components/sync/${fleet.id}`
      );
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <ListGroup>
        {/* {isAdmin && (
          <ListItemWithHeading heading={t("sync_vehicle_components")}>
            <Picker />
            <Button
              disabled={!fleet || loading}
              onClick={() => {
                sync();
              }}
            >
              {t("sync")}
            </Button>
          </ListItemWithHeading>
        )} */}

        <ListItemWithHeading heading={t('language')}>
          {<LanguageSelect />}
        </ListItemWithHeading>

      </ListGroup>
    </div>
  );
}
