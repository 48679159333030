import { CONSTANTS } from "@constants";
import { CompanyDashboardSettings } from "components/CompanyDashboardSettings";
import DateRangeFilter from "components/DateRangePicker";
import dayjs from "dayjs";
import isBetween from "dayjs/plugin/isBetween";
import { t } from "i18next";
import React, { useEffect, useRef, useState } from "react";
import Col from "react-bootstrap/esm/Col";
import Container from "react-bootstrap/esm/Container";
import Row from "react-bootstrap/esm/Row";
import ContentLoader from "react-content-loader";
import { useSelector } from "react-redux";
import { selectIsFleetManager, selectIsMaintenanceManager, selectUseDemoBackend, selectUserFleetCompanyId } from "store/userSlice";
import { isEmpty } from "stories/utils/common";
import { AbortManager } from "utils/AbortManager";
import { chunkArray, executePromisesInChunks } from "utils/general";
import { assignPostTransformations } from "../../utils/postTransformations";
import { PieChart } from "../PieChart";
import { Colors } from "../utils/colors";
import { CollectionList } from "./CollectionList";
import "./CompanyDashboard.css";
dayjs.extend(isBetween)

const MemoizedPieChart = React.memo(PieChart);

const renderCard = (
  header,
  body,
  footer,
  customCssClasses = "flex-row justify-content-around",
  bodyClasses = "",
  style
) => {
  return (
    <div
      style={{ height: "100%", ...style }}
      className="py-3 py-sm-0 shadow-sm bg-light dashboard-card-container"
    >
      <div className={"dashboard-card-wrapper " + customCssClasses}>
        {header && <div className="dashboard-card-header ps-2">{header}</div>}
        <div className={"dashboard-card-body " + bodyClasses}>{body}</div>
        <div className="dashboard-card-footer">{footer}</div>
      </div>
    </div>
  );
};

export const CompanyDashboard = ({ getHandler, onShow, mandatoryParams }) => {
  const [loading, setLoading] = useState({ fleets: false, components: true, vehicles: true, maintenanceTasks: true });
  const [vehicles, setVehicles] = useState();
  const [maintenanceTasks, setMaintenanceTasks] = useState();
  const [components, setComponents] = useState();
  const [mounted, setMounted] = useState(false);
  const [chosenCollection, setChosenCollection] = useState();
  const listId = `company_dashboard_${new Date().toISOString()}`;
  const abortManager = { id: listId, abortManager: AbortManager };
  const isFleetManager = useSelector(selectIsFleetManager);
  const isMaintenanceManager = useSelector(selectIsMaintenanceManager);
  const usersFleetCompanyId = useSelector(selectUserFleetCompanyId);
  const isDemoBackend = useSelector(selectUseDemoBackend);
  const listRef = useRef(null);
  const [dates, setDates] = useState({});
  const datesRef = useRef(dates);
  const vehiclesRef = useRef(vehicles);
  const tasksRef = useRef(maintenanceTasks);
  const componentsRef = useRef(components);

  const toGermanDayFormat = (date) => dayjs(date).format('DD.MM.YYYY')

  const getRequest = async (path, options) => {
    return await getHandler(path, {
      abortManager,
      ...options,
    });
  };
  const chunkedGetRequest = async (path, options, chunkItems, chunkKey, type, chunkSize = 80) => {
    const idChunks = chunkArray(chunkItems, chunkSize);
    const promises = [];
    for (let c in idChunks) {
      const chunk = idChunks[c];
      promises.push(() => getHandler(path, {
        abortManager, ...options, queryParams: { ...options?.queryParams, [chunkKey]: chunk.join(',') }
      }));
    }
    const results = await executePromisesInChunks(promises, 50, 5);

    return results.flatMap(result => result[type]);
  };

  const LoadingContent = () => (
    <ContentLoader>
      <rect x="0" y="40%" width="50%" height="15%" />
    </ContentLoader>
  );

  const getTableHeads = (type) => {
    switch (type) {
      case CONSTANTS.namespaces.FLEETS:
        const h = [
          "fleetName",
          "vehicles",
          "maintenance-tasks",
          "changedAt",
        ];
        if (!isMaintenanceManager) {
          h.splice(3, 0, 'maintenanceCompanyId')
        }
        return h;
      case CONSTANTS.namespaces.VEHICLES:
        return [
          "serial",
          "vehicleStatus",
          "fleetId",
          "vehicleName",
          "changedAt",
        ];
      case CONSTANTS.namespaces.COMPONENTS:
        return [
          "componentType",
          "componentName",
          "componentStatus",
          "vehicleId",
          "createdAt",
          "changedAt",
        ];
      case CONSTANTS.namespaces.MAINTENANCE_TASKS:
        return [
          "fleetId",
          "vehicleId",
          "serial",
          "componentId",
          "componentDefect",
          "taskCategory",
          "taskStatus",
          "createdAt",
          "changedAt",
          "reportedAt",
        ];
      default:
        return;
    }
  };
  const getCollection = async (type, setCollection = false) => {
    const dateValues = datesRef.current;
    let createdAt;
    if (dateValues?.start && dateValues?.end) {
      const start = dayjs().isSame(dateValues.start, 'day') ?
        dayjs().set('hour', 0).toISOString() :
        dayjs(dateValues.start).set("hour", 0).toISOString();
      const end = dayjs().isSame(dateValues.end, 'day') ?
        dayjs().toISOString() :
        dayjs(dateValues.end).set("hour", 24).toISOString();
      switch (type) {
        case CONSTANTS.namespaces.MAINTENANCE_TASKS:
          createdAt = { createdAt: `_between(${start},${end})` };
          break;
        default:
          createdAt = { createdAt: `<=${end}` };
      }
    }


    const queryParams = {
      ...createdAt,
      ...mandatoryParams,
    }
    if (type === CONSTANTS.namespaces.COMPONENTS) queryParams.componentStatus = 'defect'
    const res = await getRequest(`/${type}`, { queryParams });
    if (!res) return;
    let collectionRes = res[type];

    if (type === CONSTANTS.namespaces.COMPONENTS) createdAt.action = 'MODIFY';
    const resHistory = await chunkedGetRequest("/histories", {
      queryParams: {
        model: type,
        ...createdAt,
        ...mandatoryParams,
      }
    }, collectionRes?.map(c => c.id), 'batchGetModelIds', 'histories', 130);

    collectionRes = collectionRes.map(v => {
      return {
        ...v,
        history: resHistory?.filter(h => h.modelId === v.id)
      }
    })
    let statusProperty;
    switch (type) {
      case 'vehicles':
        statusProperty = 'vehicleStatus';
        setVehicles({ data: collectionRes });
        vehiclesRef.current = { data: collectionRes };
        break;
      case 'components':
        statusProperty = 'componentStatus';
        setComponents({ data: collectionRes });
        componentsRef.current = { data: collectionRes };
        break;
      case 'maintenance-tasks':
        statusProperty = 'taskStatus';
        setMaintenanceTasks({ data: collectionRes });
        tasksRef.current = { data: collectionRes };
        break;
      default:
        return;
    }

    let head = collectionRes[0] ? Object.keys(collectionRes[0]) : [];
    let th = getTableHeads(type);
    head = head
      ?.filter((h) => th?.includes(h))
      ?.sort((a, b) => th?.indexOf(a) - th?.indexOf(b));
    if (setCollection)
      setChosenCollection({
        status: 'all',
        data: collectionRes,
        statusProperty,
        type,
        head,
        title: dates ? t(`all_${type}_from_to`, {
          from: toGermanDayFormat(dates.start),
          to: toGermanDayFormat(dates.end)
        }) : t(`all_${type}`),
      });
  };

  useEffect(() => {
    const day = dayjs();
    const initialDates = {
      start: day.subtract(1, 'month').format('YYYY-MM-DD'),
      end: day.format('YYYY-MM-DD')
    }
    setDates(initialDates);
    datesRef.current = initialDates;
    apiRequests();
    setMounted(true);
    return () => {
      abortManager.abortManager.cancel(listId);
    };
  }, [])

  const apiRequests = async () => {
    try {
      setChosenCollection(collection => undefined);
      setLoading(loading => ({ components: true, maintenanceTasks: true, vehicles: true }));
      await getCollection(CONSTANTS.namespaces.VEHICLES);
      await getCollection(CONSTANTS.namespaces.COMPONENTS);
      await getCollection(CONSTANTS.namespaces.MAINTENANCE_TASKS);

      setLoading(loading => ({ components: false, maintenanceTasks: false, vehicles: false }));
    } catch (e) { console.warn(e) }

  };

  useEffect(() => {
    datesRef.current = dates;
    if (!mounted) return;
    if (loading.vehicles || loading.components || loading.maintenanceTasks) return;
    apiRequests();
  }, [dates])

  useEffect(() => {
    if (!mounted) return;
    setChosenCollection();
    setMaintenanceTasks();
    setComponents();
    setVehicles();
    apiRequests();
  }, [mandatoryParams])

  const onComponentsSectionClick = (item) => {
    if (!mounted) return;

    setChosenCollection({
      status: item.status,
      data: componentsRef?.current?.data,
      statusProperty: "componentStatus",
      type: CONSTANTS.namespaces.COMPONENTS,
      title: dates ? t("components_from_to", {
        from: toGermanDayFormat(dates.start),
        to: toGermanDayFormat(dates.end),
        status: t(item.status)
      }) : t("components") + " " + t(item.status),
    });
    scrollToList();
  };

  const filterByHistory = (data, status, statusKeyName) => {
    return data?.filter(m => {
      if (status === 'all') return true;
      const history = m?.history?.filter(h => h?.action === 'MODIFY' || h?.action === 'INSERT');
      //get latest history object...
      const latest = history?.sort((a, b) => a?.createdAt?.localeCompare(b?.createdAt))?.reverse()?.[0];
      // if there is no MODIFY history entry, use the current status and compare
      if (!latest?.contentChanged?.[statusKeyName]) {
        if (latest?.contentBefore?.[statusKeyName]) return latest.contentBefore[statusKeyName] === status;

        return status === m?.[statusKeyName];
      } else {
        if (latest?.contentChanged?.[statusKeyName] === status) return true;
        else return false;
      }
    })
  }

  const onStatusSectionClick = (item) => {
    const dateValues = datesRef.current;
    if (!mounted) return;

    setChosenCollection({
      status: item.status,
      data: vehiclesRef?.current?.data,
      statusProperty: "vehicleStatus",
      type: CONSTANTS.namespaces.VEHICLES,
      title: dateValues ? t("vehicles_from_to", {
        from: toGermanDayFormat(dateValues.start),
        to: toGermanDayFormat(dateValues.end),
        status: t(item.status)
      }) : t("vehicles") + " " + t(item.status),
    });
    scrollToList();
  };
  const onMaintenanceSectionClick = (item, skipSet = false) => {
    const dateValues = datesRef.current;
    if (!mounted) return;

    setChosenCollection({
      status: item.status,
      data: tasksRef?.current?.data,
      statusProperty: "taskStatus",
      type: CONSTANTS.namespaces.MAINTENANCE_TASKS,
      title: dateValues ? t("maintenance-tasks_from_to", {
        from: toGermanDayFormat(dateValues.start),
        to: toGermanDayFormat(dateValues.end),
        status: t(item.status)
      }) : t("maintenance-tasks") + " " + t(item.status),
    });
    scrollToList();
  };
  const onMaintenanceCreationTypeClick = (item) => {
    console.log('item', item)
    const dateValues = datesRef.current;
    const onlyImported = item.status === 'automatically';
    if (!mounted) return;

    setChosenCollection({
      status: 'all',
      data: tasksRef?.current?.data?.filter(d =>
        onlyImported ? !isEmpty(d?.reportedAt) : isEmpty(d?.reportedAt)
      ),
      statusProperty: "taskStatus",
      type: CONSTANTS.namespaces.MAINTENANCE_TASKS,
      title: dateValues ? t("maintenance-tasks_from_to", {
        from: toGermanDayFormat(dateValues.start),
        to: toGermanDayFormat(dateValues.end),
        status: t(onlyImported ? 'automatically' : 'manually')
      }) : t("maintenance-tasks") + " " + t(onlyImported ? 'automatically' : 'manually'),
    });
    scrollToList();
  };


  const handleOnShow = (id) => {
    switch (chosenCollection.type) {
      case "fleets":
        return onShow("/fleet-dashboard/" + id);
      case "vehicles":
        return onShow("/vehicle-dashboard/" + id);
      case "maintenance-tasks":
        return onShow(`/edit/maintenance-tasks/${id}?action=edit`);
      case "components":
        return onShow(`/edit/components/${id}?action=edit`);
      default:
        return;
    }
  };

  const onShowAll = (type) => {
    const dateValues = datesRef.current;
    if (!mounted) return;
    let statusProperty;
    let data;
    let title;

    switch (type) {
      case "vehicles":
        data = vehiclesRef?.current?.data;
        statusProperty = "vehicleStatus";
        title = (dateValues ?
          t('all_vehicles_from_to', { from: toGermanDayFormat(dateValues.start), to: toGermanDayFormat(dateValues.end) })
          : t("all_vehicles"))
        break;
      case "maintenance-tasks":
        data = tasksRef?.current?.data;
        statusProperty = "taskStatus";
        title = (dateValues ?
          t('all_maintenance-tasks_from_to', { from: toGermanDayFormat(dateValues.start), to: toGermanDayFormat(dateValues.end) })
          : t("all_maintenance-tasks"))
        break;
      case "components":
        data = componentsRef?.current?.data;
        title = (dateValues ?
          t('all_components_from_to', { from: toGermanDayFormat(dateValues.start), to: toGermanDayFormat(dateValues.end) })
          : t("all_components"))
        statusProperty = "componentStatus";
        break;
      default:
        return;
    }

    setChosenCollection({
      status: 'all',
      type,
      statusProperty,
      data,
      title
    });
    scrollToList();
  };

  const scrollToList = () => {
    if (listRef.current) listRef.current.scrollIntoView({ behavior: "smooth" });
  };
  const checkFleetCompanyId = () => {
    if (isDemoBackend) return false;
    if (process.env?.REACT_APP_STAGE !== 'production') return true;
    return usersFleetCompanyId === '76663020-b9e2-11ee-a0da-e516bb679c3c'
  }

  const allLoading = loading.components || loading.fleets || loading.maintenanceTasks || loading.vehicles

  return (
    <div>
      <Container className=" px-4" fluid>
        <Row className="g-2 mb-3">
          {isFleetManager && <Col ref={listRef} xs={12}>
            {!loading.fleets && mounted && checkFleetCompanyId() && <CompanyDashboardSettings />}
          </Col>}
          <Col ref={listRef} xs={12}>
            {mounted && renderCard(
              !loading.fleets && (
                <div className="d-flex pt-2 pb-2 justify-space-between">
                  <div className="ms-1 fs-6 text-uppercase">{t("fleets")}</div>
                </div>
              ),
              !loading.fleets && mounted ? (
                <CollectionList
                  showProgress={false}
                  mandatoryParams={mandatoryParams}
                  showExpand={true}
                  recognizeFilter={false}
                  tableCellClasses={"p-2 fs-6"}
                  tableClasses={" px-2 overflow-scroll w-100 minh-25 maxh-25"}
                  getHandler={async (path, init) => {
                    return await getRequest(
                      `/${CONSTANTS.namespaces.FLEETS}${path || ''}`,
                      init
                    );
                  }}
                  schemeNamePlural={CONSTANTS.namespaces.FLEETS}
                  include={getTableHeads(CONSTANTS.namespaces.FLEETS)}
                  postTransformations={assignPostTransformations(
                    CONSTANTS.namespaces.FLEETS,
                    onShow
                  )}
                  exclude={undefined}
                  defaultSortBy={{ fleetName: 'asc' }}
                  onClickShow={(id) => onShow("/fleet-dashboard/" + id)}
                  showCheckboxes={false}
                  showClone={false}
                  showImporter={false}
                  showDelete={false}
                  showCreate={false}
                  showEdit={false}
                  showFilter={false}
                  showSort={false}
                />
              ) : (
                <LoadingContent />
              ),
              null,
              "flex-column justify-content-between",
              undefined,
              { height: "fit-content" }
            )}
          </Col>
        </Row>
        <Row className="g-2 mb-3 d-flex justify-content-center align-items-center">
          {!allLoading && mounted && <DateRangeFilter
            defaultStartDate={dates?.start}
            defaultEndDate={dates?.end}
            onDateRangeSelected={(start, end) => {
              if (isEmpty(start) || isEmpty(end)) {
                setDates();
                return;
              }
              setDates({ start, end })
            }}
          />}

        </Row>

        <Row className="g-2 min-vh-25 mb-3">
          <Col xs={12} md={6} lg={3}>
            {mounted && renderCard(
              undefined,
              <>
                <MemoizedPieChart
                  isLoading={allLoading || !mounted}
                  title={t("createdTasks")}
                  onShowAll={onShowAll}
                  collectionType={CONSTANTS.namespaces.MAINTENANCE_TASKS}
                  data={[
                    {
                      name: t("automatically"),
                      status: "automatically",
                      y: maintenanceTasks?.data.filter(m => !isEmpty(m?.reportedAt))?.length || null,
                      color: Colors.info,
                    },
                    {
                      name: t("manually"),
                      status: "manually",
                      y: maintenanceTasks?.data.filter(m => isEmpty(m?.reportedAt))?.length || null,
                      color: Colors.danger,
                    },
                  ]}
                  onSectionClick={(item) => { onMaintenanceCreationTypeClick(item) }}
                /></>,
              undefined,
              undefined,
              !loading.maintenanceTasks ? "d-block" : ""
            )}
          </Col>
          <Col xs={12} md={6} lg={3}>
            {mounted && renderCard(
              undefined,
              <>
                <MemoizedPieChart
                  isLoading={allLoading || !mounted}
                  title={t("taskStatus")}
                  onShowAll={onShowAll}
                  collectionType={CONSTANTS.namespaces.MAINTENANCE_TASKS}
                  data={[
                    {
                      name: t("inProgress"),
                      status: "in_progress",
                      y: filterByHistory(maintenanceTasks?.data, 'in_progress', 'taskStatus')?.length || null,
                      color: Colors.info,
                    },
                    {
                      name: t("open"),
                      status: "open",
                      y: filterByHistory(maintenanceTasks?.data, 'open', 'taskStatus')?.length || null,
                      color: Colors.danger,
                    },
                    {
                      name: t("closed"),
                      status: "closed",
                      y: filterByHistory(maintenanceTasks?.data, 'closed', 'taskStatus')?.length || null,
                      color: Colors.success,
                    },
                    {
                      name: t("planned"),
                      status: "planned",
                      y: filterByHistory(maintenanceTasks?.data, 'planned', 'taskStatus')?.length || null,
                      color: Colors.dark,
                    },
                  ]}
                  onSectionClick={onMaintenanceSectionClick}
                /></>,
              undefined,
              undefined,
              !loading.maintenanceTasks ? "d-block" : ""
            )}
          </Col>
          <Col xs={12} md={6} lg={3}>
            {mounted && renderCard(
              undefined,
              <>

                <MemoizedPieChart
                  isLoading={allLoading || !mounted}
                  title={t("vehicles")}
                  onSectionClick={(item) => { onStatusSectionClick(item) }}
                  onShowAll={onShowAll}
                  collectionType={"vehicles"}
                  data={[
                    {
                      name: t("operational"),
                      status: "operational",
                      y: filterByHistory(vehicles?.data, 'operational', 'vehicleStatus')?.length || null,
                      color: Colors.success,
                    },
                    {
                      name: t("defect"),
                      status: "defect",
                      y: filterByHistory(vehicles?.data, 'defect', 'vehicleStatus')?.length || null,
                      color: Colors.danger,
                    },
                    {
                      name: t("maintenance"),
                      status: "maintenance",
                      y: filterByHistory(vehicles?.data, 'maintenance', 'vehicleStatus')?.length || null,
                      color: Colors.info,
                    },
                    {
                      name: t("retired"),
                      status: "retired",
                      y: filterByHistory(vehicles?.data, 'retired', 'vehicleStatus')?.length || null,
                      color: Colors.dark,
                    },
                    {
                      name: t("inactive"),
                      status: "inactive",
                      y: filterByHistory(vehicles?.data, 'inactive', 'vehicleStatus')?.length || null,
                      color: Colors.warning,
                    },
                  ]}
                /></>,
              undefined,
              'flex-column justify-content-center w-100',
              !loading.vehicles ? "d-block" : ""
            )}
          </Col>
          <Col xs={12} md={6} lg={3}>
            {renderCard(
              undefined,
              <>
                <MemoizedPieChart
                  isLoading={allLoading || !mounted}
                  labelFormat={`${t("amount")}: {point.y}`}
                  title={t("defect_components")}
                  collectionType={"components"}
                  data={[
                    {
                      name: t("defect"),
                      status: "defect",
                      y: filterByHistory(components?.data, 'defect', 'componentStatus')?.length,
                      color: Colors.danger,
                    },
                  ]}
                  onSectionClick={(item) => { onComponentsSectionClick(item) }}
                />
              </>,
              undefined,
              undefined,
              !loading.components ? "d-block" : ""
            )}
          </Col>
        </Row>
        {chosenCollection && chosenCollection?.data && (
          <Row className="g-2 h-50">
            <Col ref={listRef} xs={12}>
              {renderCard(
                !allLoading && (
                  <div className="d-flex pt-2 pb-2 justify-space-between">
                    <div dangerouslySetInnerHTML={{ __html: chosenCollection?.title }} className="ms-1 fs-6 text-uppercase">
                    </div>
                  </div>
                ),
                !allLoading && chosenCollection ? (
                  <CollectionList
                    showProgress={false}
                    mandatoryParams={mandatoryParams}
                    showExpand={true}
                    recognizeFilter={false}
                    tableRowClasses={(row) => {
                      switch (row[chosenCollection.statusProperty]) {
                        case "maintenance":
                        case "in_progress":
                          return "border-start border-start-4 border-info";
                        case "defect":
                        case "open":
                          return "border-start border-start-4 border-danger";
                        case "operational":
                        case "closed":
                          return "border-start border-start-4 border-success";
                        case "retired":
                          return "border-start border-start-4 border-dark";
                        case "low_battery":
                          return "border-start border-start-4 border-warning";
                        case "planned":
                          {
                            const isDue = dayjs().isAfter(row?.plannedAt) || dayjs().isSame(row?.plannedAt)
                            if (isDue) return 'bg-due td-bg-transparent'
                            return `border-start border-start-4 border-dark`;
                          }
                        default:
                          return "";
                      }
                    }}
                    tableCellClasses={"p-2 fs-6"}
                    tableClasses={" px-2 overflow-scroll w-100 minh-25 maxh-25"}
                    schemeNamePlural={chosenCollection?.type}
                    include={getTableHeads(chosenCollection?.type)}
                    tableHeadMappings={{
                      vehicleStatus: t('actualVehicleStatus'),
                      componentStatus: t('actualComponentStatus'),
                      taskStatus: t('actualTaskStatus'),
                    }}
                    staticData={filterByHistory(chosenCollection?.data, chosenCollection.status, chosenCollection.statusProperty)}
                    getHandler={async (path, init) => {
                      if (chosenCollection?.type)
                        return await getRequest(
                          `/${chosenCollection?.type}${path || ''}`,
                          init
                        );
                    }}
                    postTransformations={
                      chosenCollection?.type
                        ? assignPostTransformations(
                          chosenCollection.type,
                          onShow
                        )
                        : undefined
                    }
                    defaultSortBy={
                      chosenCollection.type ===
                        CONSTANTS.namespaces.COMPONENTS ? { componentName: 'asc' } :
                        { fleetId: 'asc' }
                    }
                    exclude={undefined}
                    onClickShow={handleOnShow}
                    showCheckboxes={false}
                    showClone={false}
                    showImporter={false}
                    showDelete={false}
                    showCreate={false}
                    showEdit={false}
                    showFilter={false}
                    showSort={false}
                  />
                ) : (
                  <LoadingContent />
                ),
                null,
                "flex-column justify-content-between",
                undefined,
                { height: "fit-content" }
              )}
            </Col>
          </Row>
        )}
      </Container>
    </div>
  );
};
