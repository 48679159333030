import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    pages: {
        fleets: {
            data: null,
            relatedCollectionItems: null,
            filter: [],
            fetchedAt: null,
            ttl: 3600
        },
        vehicles: {
            data: null,
            relatedCollectionItems: null,
            filter: [],
            fetchedAt: null,
            ttl: 3600
        },
        components: {
            data: null,
            relatedCollectionItems: null,
            filter: [],
            fetchedAt: null,
            ttl: 3600
        },
        "maintenance-tasks": {
            data: null,
            relatedCollectionItems: null,
            filter: [],
            fetchedAt: null,
            ttl: 3600
        }
    }
};

const cacheSlice = createSlice({
    name: "cache",
    initialState,
    reducers: {
        setCache(state, action) {
            if (action.payload?.page && action.payload?.filter && action.payload?.data && action.payload?.relatedCollectionItems) {
                state.pages[action.payload.page] = {
                    data: action.payload.data,
                    relatedCollectionItems: action.payload.relatedCollectionItems,
                    filter: action.payload.filter,
                    fetchedAt: new Date().toISOString(),
                    ttl: state.pages[action.payload.page]?.ttl
                }
            }

        },
        resetCache(state, action) {
            if (action.payload) state.pages[action.payload] = {
                data: null,
                filter: [],
                relatedCollectionItems: null,
                fetchedAt: null,
                ttl: state.pages[action.payload]?.ttl
            }
        },
        resetCaches() {
            return initialState;
        }
    },
});

export const getCacheByPage = (state, pageName) => {
    return state.cache.pages?.[pageName];
}

export const cacheReducer = cacheSlice.reducer;
export const { setCache, resetCache, resetCaches } = cacheSlice.actions;
