import PropTypes from 'prop-types';
import React from 'react';
import { selectUseDemoBackend } from 'store/userSlice';
import { TextField } from 'stories/crud/FieldTypes/TextField';
import { store } from '../../../../store';
import { TextMaintenanceCompanyName } from './TextMaintenanceCompanyName';

export const TextFleetCompanies = (props) => {
    switch (props?.attr) {
        case 'userEmail': {
            const isDemoBackend = selectUseDemoBackend(store.getState());
            if (props.action !== 'create') return <></>;
            const data = props.formData;
            return data.createDemoData === true && isDemoBackend ? <TextField {...props} /> : <></>;
        }
        case 'maintenanceCompanyName': {
            if (props.action !== 'create') return <></>;
            const data = props.formData;
            const isDemoBackend = selectUseDemoBackend(store.getState());
            return data.createDemoData === true && isDemoBackend ? <TextMaintenanceCompanyName {...props} /> : <></>;
        }
        case 'fleetCompanyImportFolder': {
            const isDemoBackend = selectUseDemoBackend(store.getState());
            return !isDemoBackend ? <TextField {...props} /> : <></>;
        }
        default: {
            return <TextField {...props} />
        }
    }
}
TextFleetCompanies.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
};