import { CONSTANTS } from '@constants';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React, { useEffect } from 'react';
import { isEmpty } from 'stories/utils/common';
import { SelectField } from '../../../../stories/crud/FieldTypes/SelectField';

export const SelectTaskCategory = (props) => {
    const {
        type,
        action,
        itemId,
        preDefinedProperties,
        schemesHandler,
        postHandler,
        patchHandler,
        backHandler,
        hidden,
        getHandler,
        attr,
        value,
        index,
        formData,
        collectionData,
        htmlSchemes,
        onChangeFormData,
        onChangeCollectionData,
        relatedResources
    } = props;

    useEffect(() => {
        const mapOptions = async () => {
            if (!relatedResources?.[CONSTANTS.namespaces.VEHICLES]) return;

            const col = { ...collectionData };
            const vehicleType = relatedResources[CONSTANTS.namespaces.VEHICLES]?.find((v) => v.id === formData.vehicleId)?.vehicleType;
            if (col?.taskCategory) {
                col.taskCategory.options = [
                    { label: t("uvv"), value: 'uvv' },
                    { label: t("inspection"), value: 'inspection' }
                ]
                if (vehicleType === CONSTANTS.VEHICLE_TYPES.electrical) {
                    col.taskCategory.options.unshift({ label: t(CONSTANTS.VEHICLE_TYPES.electrical), value: CONSTANTS.VEHICLE_TYPES.electrical })
                    col.taskCategory.options.unshift({ label: t(CONSTANTS.VEHICLE_TYPES.mechanical), value: CONSTANTS.VEHICLE_TYPES.mechanical })
                } else {
                    col.taskCategory.options.unshift({ label: t(CONSTANTS.VEHICLE_TYPES.mechanical), value: CONSTANTS.VEHICLE_TYPES.mechanical })
                }
                onChangeCollectionData(col);
            }
        }
        if (!isEmpty(formData.vehicleId)) mapOptions()

    }, [formData.vehicleId])
    const handleOnChange = async (val) => {
        let data = { ...val };
        const colData = { ...collectionData };
        data.componentId = undefined;
        data.componentDefect = undefined;
        colData.componentId.options = [];
        colData.componentDefect.options = [];
        onChangeCollectionData(colData);
        onChangeFormData(data)
    }

    return (
        <SelectField {...props} onChangeFormData={handleOnChange} collectionData={
            {
                ...collectionData,
                [attr]: {
                    ...collectionData[attr],
                    readonly: formData?.immutableByInitialTask ? true : collectionData[attr]?.readonly
                }
            }
        } />
    )
}
SelectTaskCategory.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
    relatedResources: PropTypes.any
};