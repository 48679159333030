import { useAuthenticator } from "@aws-amplify/ui-react";
import { CONSTANTS } from "@constants";
import i18next, { t } from "i18next";
import React, { useEffect } from "react";
import { Alert } from "react-bootstrap";
import Badge from "react-bootstrap/esm/Badge";
import { BiCog, BiLogOut } from "react-icons/bi";
import { useDispatch, useSelector } from "react-redux";
import { Outlet, useNavigate } from "react-router-dom";
import { resetCaches } from "store/cacheSlice";
import { AbortManager } from "utils/AbortManager";
import { get } from "utils/api";
import { sortByArray } from "utils/general";
import {
  fetchAttributes,
  fetchUserSessionData,
  resetUser,
  selectIsAdmin,
  selectIsFleetManager,
  selectIsFleetViewer,
  selectIsMaintenanceManager,
  selectUseDemoBackend,
  selectUserAttributes,
  selectUserPermissions,
  selectUserRoles,
  toggleUseDemoBackend
} from "../store/userSlice";
import { Navbar } from "../stories/Navbar";
import Footer from "./Footer";
import Switch from "./Switch";

const Layout = () => {
  const { route } = useAuthenticator((context) => [context.route]);
  const { authStatus } = useAuthenticator((context) => [context.authStatus]);
  const { signOut } = useAuthenticator((context) => [context.signOut]);
  const isAuthenticating = route === "idle";
  const isAuthenticated = authStatus === "authenticated";
  const userAttributes = useSelector(selectUserAttributes);
  const userPermissions = useSelector(selectUserPermissions);
  const userRoles = useSelector(selectUserRoles);
  const isFleetManager = useSelector(selectIsFleetManager);
  const listId = `BaseLayout_${new Date().toISOString()}`;
  const abortManager = { id: listId, abortManager: AbortManager };
  const isFleetViewer = useSelector(selectIsFleetViewer);
  const isAdmin = useSelector(selectIsAdmin);
  const useDemoBackend = useSelector(selectUseDemoBackend);
  const isMaintenanceManager = useSelector(selectIsMaintenanceManager);

  const navigate = useNavigate();
  const dispatch = useDispatch();

  let sidebarItems = [];
  if (isAuthenticated && !isAuthenticating) {
    if (userPermissions?.permissions) {
      const items = sortByArray(userPermissions?.permissions
        .filter((r) =>
          r.showInSideBar === true &&
          r.route !== CONSTANTS.namespaces.APP_LOGS &&
          r.route !== CONSTANTS.namespaces.DATASETS &&
          r.route !== CONSTANTS.namespaces.REMINDERS &&
          r.route !== CONSTANTS.namespaces.HISTORIES &&
          r.route !== CONSTANTS.namespaces.USERS &&
          r.route !== CONSTANTS.namespaces.ERRORS &&
          r.route !== CONSTANTS.namespaces.TRANSLATIONS &&
          r.route !== CONSTANTS.namespaces.FLEET_COMPANIES
        )
        .map((r) => r.route), ["fleets", "vehicles", "maintenance-tasks", "components"]);
      let adminItems, userItems;

      sidebarItems = items.map((route) => {
        return {
          name: t(route),
          link: () => navigate("/list/" + route),
        }
      })

      if (isAdmin) {
        adminItems = {
          name: t('admin_sidebar_items'),
          group: userPermissions?.permissions
            .filter((r) =>
              r.showInSideBar === true &&
              (r.route === CONSTANTS.namespaces.APP_LOGS ||
                r.route === CONSTANTS.namespaces.DATASETS ||
                r.route === CONSTANTS.namespaces.REMINDERS ||
                r.route === CONSTANTS.namespaces.HISTORIES ||
                r.route === CONSTANTS.namespaces.USERS ||
                r.route === CONSTANTS.namespaces.ERRORS ||
                r.route === CONSTANTS.namespaces.FLEET_COMPANIES ||
                r.route === CONSTANTS.namespaces.TRANSLATIONS)
            )
            .map((r) => ({
              name: t(r.route),
              link: () => navigate("/list/" + r.route),
            }))
        }

      }


      if (isFleetManager || isFleetViewer || isAdmin || isMaintenanceManager)
        sidebarItems.unshift(({
          name: t('dashboard'),
          link: () => navigate("/company-dashboard"),
        }))
      if (isAdmin) {
        adminItems.group.unshift(({
          name: t('statistics'),
          link: () => navigate("/statistics"),
        }))
        userItems = {
          name: t('user_sidebar_items'),
          group: sidebarItems
        }
        sidebarItems = [userItems, adminItems];
      }
    }
  }
  const handleLogout = () => {
    signOut()
    dispatch(resetUser());
    dispatch(resetCaches());
  }

  useEffect(() => {
    async function onLoad() {
      if ((route !== 'authenticated' && route !== 'setup') || authStatus === 'configuring') return;

      if (authStatus === "unauthenticated") return;

      if (authStatus === 'authenticated') {
        try {
          await dispatch(fetchUserSessionData()).unwrap();
          await dispatch(fetchAttributes()).unwrap();
          const locales = ["de", "en"];
          const backendTranslations = await get(
            `/${CONSTANTS.namespaces.TRANSLATIONS}/all`,
            { abortManager }
          );
          for (let l in locales) {
            let obj = {};
            const locale = locales[l];
            for (let b in backendTranslations) {
              for (let bd in backendTranslations[b].data) {
                const data = backendTranslations[b].data[bd];
                obj = { ...obj, [data.id]: data.translations[locale] };
              }
            }
            i18next.addResourceBundle(locale, "translation", obj, true, false);
          }
        } catch (e) {
          console.warn(e)
        }
      }
    }
    onLoad();
    return () => {
      abortManager.abortManager.cancel(listId);
    };
  }, [route, authStatus]);

  useEffect(() => {
    if (isAuthenticated && !isAuthenticating && (!userAttributes?.email || !userRoles?.[0])) {
      handleLogout();
      return;
    }
  }, [isAuthenticated, isAuthenticating])

  const toggleDemoMode = () => {
    dispatch(toggleUseDemoBackend());
    setTimeout(() => window.location.reload(), 100);
  }
  const DemoBanner = () => {
    return useDemoBackend ?
      <Alert
        className="d-flex justify-content-center align-items-center mb-3"
        variant="danger"
        style={{
          textAlign: 'center',
          margin: 0, padding: 5,
          marginTop: '-1rem',
          fontWeight: 'bold',
          textShadow: '1px 1px 1px #fff',
          color: '#de716f'
        }}>
        <div>{isAdmin ? t('demo_system_on') : t('demo_mode')}</div> {isAdmin &&
          <Switch className="ms-1" checked={useDemoBackend} onChange={() => {
            toggleDemoMode()
          }} />}
      </Alert> :
      isAdmin && process.env?.REACT_APP_DEMO_API_URL ?
        <Alert
          className="d-flex justify-content-center align-items-center mb-3"
          variant="link"
          style={{
            textAlign: 'center',
            margin: 0, padding: 5,
            marginTop: '-1rem',
            fontWeight: 'bold',
            textShadow: '1px 1px 1px #fff',
            color: '#ccc',
            background: '#eeeeeeee'
          }}>
          <div>{t('demo_system_off')}</div>

          <Switch className="ms-1" checked={useDemoBackend} onChange={() => {
            toggleDemoMode()
          }} />
        </Alert> :
        null
  }

  return (
    <div className="App">
      {isAuthenticated && !isAuthenticating && (
        <>
          <Navbar
            accountItems={[
              {
                name: (
                  <div className="d-flex flex-column w-100">
                    <div style={{ marginRight: 20 }}>
                      {t("hello", { name: userAttributes?.email })}
                    </div>
                    <Badge
                      key={"badge_role_account_nav"}
                      role="button"
                      bg="primary"
                    >
                      {t(userRoles[0])}
                    </Badge>
                  </div>
                ),
              },
              {
                name: (
                  <span>
                    <BiCog /> {t("settings")}
                  </span>

                ),
                link: () => navigate('/settings')
              },
              {
                link: handleLogout, name: <span>
                  <BiLogOut /> {t("logout")}
                </span>
              },
            ]}
            sidebarItems={sidebarItems}
          />
          <DemoBanner /></>
      )}

      <div className="main-content" style={{ minHeight: "100vh" }}>
        <Outlet />
      </div>
      <Footer />
    </div >
  );
};

export default Layout;
