import React, { useEffect, useState } from "react";
import { Card } from "react-bootstrap";
import ContentLoader from "react-content-loader";
import { isEmpty } from "stories/utils/common";
import "./AnimatedCounter.css";

const AnimatedCounter = ({ count, label, onClick, icon, loading, max }) => {
  const [displayCount, setDisplayCount] = useState(0);
  const [isFocused, setIsFocused] = useState(false);
  const LoadingContent = () => (
    <ContentLoader>
      <rect x="25%" y="80%" rx="0" ry="0" width="50%" height="10%" />
      <rect x="4%" y="93%" rx="0" ry="0" width="100%" height="5%" />
    </ContentLoader>
  );
  useEffect(() => {
    if (displayCount !== count) {
      const increment = count > displayCount ? 1 : -1;
      const interval = setInterval(() => {
        setDisplayCount((prevCount) => {
          if (prevCount === count) {
            clearInterval(interval);
            return prevCount;
          }
          return prevCount + increment;
        });
      }, 2); // Geschwindigkeit der Animation
      return () => clearInterval(interval);
    }
  }, [count, displayCount]);

  return loading ? <LoadingContent /> : (
    <Card
      tabIndex={0}
      role="button"
      bg={isFocused ? "white" : "transparent"}
      text="black"
      onBlur={() => setIsFocused(false)}
      onClick={() => {
        setIsFocused(true)
        onClick()
      }}
      className="d-flex align-items-center border-0 shadow rounded"
    >
      <Card.Body className="fw-bold m-0 p-0 mt-3">
        <p className="fw-bold h2 m-0 p-0">{displayCount}{<span className="h2 fw-light" >{!isEmpty(max) ? ` / ${max}` : ''}</span>}</p>
      </Card.Body>
      <Card.Footer className="border-0 bg-transparent">
        <p>{label}</p>
      </Card.Footer>
    </Card>
  );
};

export default AnimatedCounter;
