import useModal from "components/modal/hook";
import dayjs from "dayjs";
import { getAllTranslationObjects } from "i18n";
import i18next, { t } from "i18next";
import jsPDF from "jspdf";
import autoTable from "jspdf-autotable";
import Papa from 'papaparse';
import React, { useCallback, useEffect, useState } from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/esm/Button";
import Col from "react-bootstrap/esm/Col";
import Row from "react-bootstrap/esm/Row";
import { BiMinusCircle, BiPlusCircle, BiSolidFilePdf } from "react-icons/bi";
import { BsQrCode } from "react-icons/bs";
import { FaFileCsv } from "react-icons/fa";
import { GiExpand } from "react-icons/gi";
import { MdMinimize } from "react-icons/md";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";
import { getCacheByPage, resetCache, setCache } from "store/cacheSlice";
import { addListFilter, changeListFilter, clearListFilter, destroyFiltersOnOnmount, removeListFilter, selectListFilters } from "store/pagesSlice";
import { usePrevious } from "stories/utils/hooks";
import { AbortManager } from "utils/AbortManager";
import { get } from "utils/api";
import { chunkArray, cloneNestedObject, extractOperator, uniqueMergeByKey } from "utils/general";
import { assignPdfTransformations } from "utils/postTransformations";
import { generateUUID } from "../../stories/utils/common";
import { CSVImporter } from "../CSVImporter";
import { Filter } from "../Filter";
import ApiUrls from "../constants/api";
import List from "../crud/List";
import { QRCodeModal } from "./QRCodeModal";

const ColList = ({
  directFilters,
  recognizeFilter = true,
  schemeNamePlural,
  include,
  exclude,
  postTransformations,
  staticData = undefined,
  onClickShow,
  showCreate = true,
  showImporter = true,
  showClone = true,
  showEdit = true,
  showDelete = true,
  showFilter = true,
  showCheckboxes = true,
  getHandler,
  postHandler,
  deleteHandler,
  onClickCreate,
  onClickEdit,
  autoTranslate = true,
  tableRowClasses,
  tableCellClasses,
  tableClasses,
  computedHeads,
  customSort,
  customCSVHandler,
  customCSVFields,
  showExport = true,
  showQRExport = false,
  exportConfig,
  filterProperties = [],
  excludeFilterProperties,
  defaultSortBy,
  showExpand = false,
  mandatoryParams,
  useCache = false,
  onLoading,
  tableHeadMappings,
  showHistory,
  showProgress = true
}) => {
  const [loading, setLoading] = useState(false);
  const [data, setData] = useState([]);
  const [properties, setProperties] = useState([]);
  const [reload, setReload] = useState(false);
  const [thead, setTHead] = useState([]);
  const [CSVhead, setCSVHead] = useState([]);
  const [mounted, setMounted] = useState();
  const [filterError, setFilterError] = useState();
  const listId = `${schemeNamePlural}_list`;
  const abortManager = { id: listId, abortManager: AbortManager };

  const dispatch = useDispatch();
  const filters = useSelector((state) => selectListFilters(state, schemeNamePlural))
  const prevFilters = usePrevious(filters);
  const [additionaltableClasses, setAdditionalTableClasses] = useState('');
  const cachedCollection = useSelector(state => getCacheByPage(state, schemeNamePlural));
  const { modal, onConfirmModal } = useModal();



  function useQuery() {
    const { search } = useLocation();
    return React.useMemo(() => new URLSearchParams(search), [search]);
  }
  const query = useQuery();
  const queryParams = Object.fromEntries(query.entries());
  const prevQueryParams = usePrevious(queryParams);

  const LIST_PROP_TYPES = {
    COUNT: "count",
    MAP_OBJECT_PROP: "mapObjectProperty",
  };

  const onLoadingChange = useCallback((isLoading) => {
    setLoading(isLoading);
  }, []);

  const filterRequest =
    (prop, val, op, id) => {
      let filter = { ...[...filters]?.find((f) => f.id === id) };
      let changed = false;
      if (!filter) return;


      if (prop !== filter?.filterProperty) {
        filter.filterProperty = prop;
        changed = true;
      }
      if (val !== filter?.filterSearchTerm) {
        filter.filterSearchTerm = val;
        changed = true;
      }
      if (op !== filter?.filterOperator) {
        filter.filterOperator = op;
        changed = true;
      }

      if (changed) {
        dispatch(changeListFilter({ filter, namespace: schemeNamePlural }))
      }
    }

  useEffect(() => {
    setMounted(true);
    return function () {
      dispatch(destroyFiltersOnOnmount(schemeNamePlural));
      setMounted(false);
    };
  }, []);
  useEffect(() => {
    if (!mounted) return;
    if (onLoading) onLoading(loading);
  }, [loading])

  useEffect(() => {
    if (loading) {
      abortManager.abortManager.cancel(listId);
      setData([]);
      setProperties([])

    }
    if (recognizeFilter && prevQueryParams === queryParams && prevFilters === filters) return;
    //if a new filter was created, without any new property or value defined, just return and wait for more input
    if (recognizeFilter && filters && filters.length > prevFilters?.length && Object.keys(filters[filters.length - 1]).length === 1) return;
    //if an empty filter was removed, do nothing;
    if (recognizeFilter && prevFilters && prevFilters.length > filters?.length && Object.keys(prevFilters[prevFilters.length - 1]).length === 1) return;
    const apiRequest = async () => {
      try {
        setFilterError();
        setLoading(true);
        let query;
        const queryFilter = [];

        for (let i in queryParams) {
          const prop = extractOperator(queryParams[i]);
          queryFilter.push({
            filterSearchTerm: t(prop.value),
            filterProperty: i,
            filterOperator: prop.operator,
          })
        }

        let merged = !recognizeFilter ? [] : [...queryFilter, ...filters];
        merged = directFilters ? directFilters : merged;
        let params = {};

        const propertiesResult = getHandler ? await getHandler("/properties", {
          abortManager,
        }) : undefined;
        const listProperties = propertiesResult?.listProperties;
        for (let f in merged) {
          const property = merged[f].filterProperty;
          let searchTerm = merged[f].filterSearchTerm;
          const operator = merged[f].filterOperator;
          if (!property || !operator) continue;
          if (!searchTerm) {
            if (
              operator !== '_exists' &&
              operator !== '_not_exists' &&
              operator !== '_is_true' &&
              operator !== '_is_false') continue;
          }

          const prefiltered = await preFilterTranslations(
            property,
            searchTerm,
            operator
          );

          let mapped = false;

          if (
            listProperties?.[property] &&
            !directFilters &&
            !queryParams?.[property]
          )
            mapped = true;
          if (!prefiltered) {
            switch (operator) {
              case "=":
                params = {
                  ...params,
                  [`${property.toString()}`]: mapped ? `_mapped(${searchTerm.toString()})` : `${searchTerm.toString()}`
                };
                break;
              case "<":
              case ">":
              case "<>":
                params = {
                  ...params,
                  [`${property.toString()}`]: mapped ? `_mapped(${operator}${searchTerm.toString()})` : `${operator}${searchTerm.toString()}`,
                };
                break;
              case "_begins_with":
              case "_contains":
              case "_not_contains":
              case "_in":
                params = {
                  ...params,
                  [`${property.toString()}`]: mapped ? `_mapped(${operator}(${searchTerm.toString()}))` : `${operator}(${searchTerm.toString()})`,
                };
                break;
              case "_exists":
              case "_not_exists":
                params = {
                  ...params,
                  [`${property.toString()}`]: `${operator}(${property.toString()})`,
                };
                break;
              case "_is_true":
              case "_is_false":
                params = {
                  ...params,
                  [`${property.toString()}`]: `${operator}`,
                };
                break;
              default:
                params = {
                  ...params,
                  [`${property.toString()}`]: mapped ? `${searchTerm.toString()}` : `${searchTerm.toString()}`,
                };
                break;
            }
          } else {
            if (prefiltered.length > 80) {
              setFilterError(t('filter_error'));
              setData([]);
              setLoading(false);
              return;
            }
            const op = operator === '_not_contains' || operator === '<>' ? '_not_in' : '_in';
            const simpleOp = operator === '_not_contains' || operator === '<>' ? '<>' : '';
            if (mapped) {
              params = {
                ...params,
                [`${property.toString()}`]: `_mapped(${prefiltered.length > 1 ? `${op}(${prefiltered.join(',')})` : `${simpleOp}${prefiltered[0].toString()}`})`,
              };
            } else {
              params = {
                ...params,
                [`${property.toString()}`]: `${prefiltered.length > 1 ? `${op}(${prefiltered.join(',')})` : `${simpleOp}${prefiltered[0].toString()}`}`,
              };
            }
          }
        }
        //if (maxItems) params.limit = maxItems;
        if (mandatoryParams) params = { ...params, ...mandatoryParams };

        let skipFetch = false;
        if (useCache && cachedCollection) {
          if (dayjs().diff(cachedCollection.fetchedAt, 'seconds') >= cachedCollection?.ttl) {
            dispatch(resetCache(schemeNamePlural));
            skipFetch = false;
          } else if (useCache) {
            try {
              const cacheFilter = JSON.stringify(cachedCollection.filter);
              const currentFilter = JSON.stringify(params);
              //if filter hasn't change, use cache
              if (cacheFilter === currentFilter && cachedCollection?.data) { skipFetch = true; }
            } catch (e) {
            }
          }
        }

        let res;

        if (!skipFetch) {
          res = staticData
            ? { [schemeNamePlural]: cloneNestedObject(staticData) }
            : (
              !getHandler
                ? []
                : await getHandler(query, {
                  abortManager,
                  queryParams: { ...params },
                })
            );
        } else {
          res = { [schemeNamePlural]: JSON.parse(cachedCollection.data) };
        }

        let head = [];
        if (!propertiesResult) throw new Error('no properties found.')
        const rawProperties = propertiesResult?.properties || propertiesResult;
        let properties = rawProperties;

        if (listProperties) properties = { ...properties, ...listProperties };

        head = Object.keys(properties)
          .sort((a, b) => (a.toLowerCase().includes("id") ? -1 : 1))
          .map((i) => i);

        if (!include)
          head = exclude ? head.filter((i) => !exclude.includes(i)) : head;
        if (include)
          head = head
            .filter((i) => include.includes(i))
            .sort((a, b) => include.indexOf(a) - include.indexOf(b));
        if (computedHeads) head = [...head, ...computedHeads];
        setTHead(head);

        setProperties([...head, "id"]);

        const csvProps =
          schemeNamePlural?.toLowerCase() === "components" && getHandler
            ? await getHandler("/all-properties", {
              abortManager,
            })
            : rawProperties;
        setCSVHead(
          Object.keys(csvProps)?.sort((a, b) =>
            a.toLowerCase().includes("id") ? -1 : 1
          ) || []
        );

        if (listProperties) {
          let prefetchedCollections = [];
          let resItems = [];

          for (let l in listProperties) {
            const listProp = listProperties[l];
            if (
              (!head.includes(listProp.key) &&
                listProp.type === LIST_PROP_TYPES.MAP_OBJECT_PROP) ||
              (!postTransformations[listProp.key] &&
                listProp.type === LIST_PROP_TYPES.MAP_OBJECT_PROP)
            )
              continue;


            const existingPrefetchedCollection =
              prefetchedCollections?.findIndex((p) => p[listProp.collection]);

            let relatedIds = [
              ...new Set(res[schemeNamePlural].map((r) => r[listProp.key])),
            ]

            if (relatedIds.length === 0) continue;
            if (existingPrefetchedCollection > -1) {
              const foundItems = prefetchedCollections[existingPrefetchedCollection][listProp.collection]
                .filter(c => relatedIds.includes(c[listProp.foreignKey])).map(c => c[listProp.foreignKey]);
              //continuing if all ids are already in prefetched array, skipping fetch
              if (foundItems.length === relatedIds.length) continue;
              //remove already fetched ids if there are some
              if (foundItems.length > 0) relatedIds = relatedIds.filter(r => !foundItems.includes(r));
            }


            if (!skipFetch) {
              if (listProp.foreignKey !== 'id') {
                const idChunks = chunkArray(relatedIds, 10);
                let preDefinedQueryParams;
                if (listProp?.filterBy) {
                  preDefinedQueryParams = {
                    [listProp.filterBy.key]: listProp.filterBy.value
                  }
                }

                for (let c in idChunks) {
                  try {
                    const chunk = idChunks[c];
                    if (!chunk || chunk.length === 0) continue;

                    const resCollection = await get(
                      listProp.path,
                      {
                        abortManager,
                        queryParams: {
                          ...preDefinedQueryParams,
                          [listProp.foreignKey]: `_in(${chunk.join(",")})`,
                        },
                      }
                    );
                    resItems = resItems.concat(resCollection[listProp.collection]);
                  } catch (e) {

                  }
                }
              } else {
                const idChunks = chunkArray(relatedIds, 80);
                for (let c in idChunks) {
                  try {
                    const chunks = idChunks[c];
                    if (!chunks || chunks.length === 0) continue;

                    const resCollection = await get(
                      listProp.path,
                      {
                        abortManager,
                        queryParams: {
                          batchGet: chunks.join(',')
                        }
                      }
                    );
                    resItems = resItems.concat(resCollection[listProp.collection]);
                  } catch (e) {

                  }
                }
              }
            } else {
              try {
                resItems = JSON.parse(cachedCollection.relatedCollectionItems);
              } catch (e) { console.error('JSON.parse(cachedCollection.relatedCollectionItems) failed:', e) }
            }


            if (existingPrefetchedCollection !== -1) {
              prefetchedCollections[existingPrefetchedCollection][
                listProp.collection
              ] = uniqueMergeByKey(
                [
                  prefetchedCollections[existingPrefetchedCollection][
                  listProp.collection
                  ],
                  resItems,
                ],
                "id"
              );
            } else {
              prefetchedCollections.push({
                [listProp.collection]: resItems,
              });
            }
          }
          if (!skipFetch) {
            let tempRes = [];
            //cloning initial result array of main collection, to avoid new mapping interference 
            try { tempRes = cloneNestedObject(res[schemeNamePlural]); } catch (e) { }
            for (let r in tempRes) {
              for (let l in listProperties) {
                const listProp = listProperties[l];
                if (!listProp?.key) continue;
                const itemId = tempRes[r][listProp.key];
                const foreignKey = listProp.foreignKey;
                const filterBy = listProp?.filterBy;

                const items =
                  prefetchedCollections?.find(
                    (p) => typeof p[listProp.collection] !== "undefined"
                  )?.[listProp.collection] || [];
                if (!items || items?.length === 0) continue;
                const filtered = items.filter((i) => {
                  let filterRes = i[foreignKey] === itemId;
                  if (filterBy)
                    filterRes =
                      i[foreignKey] === itemId &&
                      i[filterBy.key] === filterBy.value;

                  return filterRes;
                });
                let v;
                switch (listProp.type) {
                  case LIST_PROP_TYPES.COUNT:
                    v = filtered.length || undefined;
                    break;
                  case LIST_PROP_TYPES.MAP_OBJECT_PROP:
                    v = filtered || undefined;
                    if (!v) break;

                    const map = listProp.map;
                    if (!map) break;

                    const items = filtered;
                    if (items.length === 0) break;

                    if (items.length === 1) v = items[0];
                    else v = items;
                    break;
                  default:
                    break;
                }
                if (v) res[schemeNamePlural][r][l] = v;
              }
            }
          }
          if (useCache && !skipFetch && res?.[schemeNamePlural] && res?.[schemeNamePlural]?.length > 0) {
            try {
              const data = JSON.stringify(res[schemeNamePlural]);
              const relatedCollectionItems = JSON.stringify(resItems);
              dispatch(setCache({
                data,
                filter: params,
                page: schemeNamePlural,
                relatedCollectionItems
              }))
            } catch (e) { console.warn('setCache Error', e) }
          }
        }

        const data = res[schemeNamePlural].map((item) => {
          let tempItem = { ...item };

          for (let i in postTransformations) {
            if ((item[i] || postTransformations?.allowedEmptyProps?.includes(i)) && typeof postTransformations[i] === "function") {
              tempItem[i] = {
                postProcess: postTransformations[i],
                value: item[i],
                sortByValue: postTransformations.sortByValues?.[i] ?
                  postTransformations.sortByValues[i](item[i]) :
                  undefined
              };
            }
          }

          return tempItem;
        });

        setData(data);
        setLoading(false);
      } catch (e) {
        console.warn(e);
        setData([]);
        setLoading(false);
      }
    };
    try { apiRequest(); } catch (e) {
      console.error(e);
      setLoading(false);
    }
    return () => {
      abortManager.abortManager.cancel(listId);
    };
  },
    [
      directFilters,
      mandatoryParams,
      recognizeFilter,
      reload,
      schemeNamePlural,
      staticData
    ]);

  const handleOnEdit = useCallback(
    (data) => {
      onClickEdit(schemeNamePlural, data.id);
    },
    [schemeNamePlural]
  );

  const handleOnDelete = useCallback(
    async (data) => {
      try {
        if (Array.isArray(data)) {
          if (window.confirm(t("delete_multiple_confirmation")) == false)
            return;
          setLoading(true);
          await deleteHandler(null, null, {
            abortManager,
            queryParams: { ids: data.map((d) => d.id) },
          });
        } else {
          if (window.confirm(t("delete_confirmation")) == false) return;
          setLoading(true);
          await deleteHandler(data.id, null, {
            abortManager,
          });
        }
        dispatch(resetCache(schemeNamePlural));
        setReload((reload) => !reload);
      } catch (e) {
        console.warn(e);
      }
    },
    [deleteHandler]
  );

  const preFilterTranslations = async (searchKey, searchValue, operator) => {
    const translateable = [
      "componentName",
      "componentStatus",
      "componentCategory",
      "componentType",
      "componentDefect",
      "componentId",
      "taskCategory",
      "taskStatus",
      "taskAction",
      "vehicleStatus",
      "vehicleType"
    ]
    if (!translateable.find(t => t === searchKey)) return;

    const locale = i18next.language;
    const collectionTranslations = getAllTranslationObjects(locale);
    let keyResults;

    if (!searchKey || !searchValue) return;
    switch (operator) {
      case "=":
        keyResults = collectionTranslations?.filter(
          (c) => c.value.toLowerCase() === searchValue.toLowerCase()
        )?.map(c => c.key);
        break;
      case "<":
        keyResults = collectionTranslations?.filter(
          (c) => c.value.toLowerCase() < searchValue.toLowerCase()
        )?.map(c => c.key);
        break;
      case ">":
        keyResults = collectionTranslations?.filter(
          (c) => c.value.toLowerCase() > searchValue.toLowerCase()
        )?.map(c => c.key);
        break;
      case "<>":
        keyResults = collectionTranslations?.filter(
          (c) => c.value.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        )?.map(c => c.key);
        break;
      case "_begins_with":
        keyResults = collectionTranslations?.filter((c) =>
          c.value.toLowerCase().startsWith(searchValue.toLowerCase())
        )?.map(c => c.key);
        break;
      case "_contains":
        keyResults = collectionTranslations?.filter(
          (c) => c.value.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        )?.map(c => c.key);
        break;
      case "_not_contains":
        keyResults = collectionTranslations?.filter(
          (c) => c.value.toLowerCase().indexOf(searchValue.toLowerCase()) !== -1
        )?.map(c => c.key);
        break;
      case "_in":
        keyResults = collectionTranslations?.filter((c) =>
          c.value.toLowerCase().includes(searchValue.toLowerCase())
        )?.map(c => c.key);
        break;
      default:
        break;
    }

    return keyResults && keyResults?.length > 0 ? keyResults : undefined;

  };

  const handleClone = useCallback(
    async (data) => {
      try {
        setLoading(true);
        await postHandler(
          Array.isArray(data)
            ? data.map((d) => {
              return { id: d.id };
            })
            : { id: data.id },
          { abortManager }
        );
        setReload((reload) => !reload);
      } catch (e) {
        console.warn(e);
      }
    },
    [postHandler]
  );

  const handleOnShow = useCallback(
    (data) => {
      if (typeof onClickShow === "function") onClickShow(data.id);
    },
    [onClickShow]
  );

  const onRowProcessed = async (row) => {
    try {
      await postHandler(row, { abortManager });
    } catch (e) {
      console.warn(e);
    }
  };

  const createFilter = () => {
    dispatch(addListFilter({ filter: { id: generateUUID() }, namespace: schemeNamePlural }));
  };
  const removeFilter = (id) => {
    dispatch(removeListFilter({ id, namespace: schemeNamePlural }));
  };
  const openQRConfigModal = async () => {
    try {
      await new Promise((resolve, reject) => {
        modal(
          <QRCodeModal schemeProperties={properties} scheme={schemeNamePlural} onSubmit={() => resolve()} data={data} />,
          t("edit_changed_component_title"),
          t("close"),
          undefined,
          () => {
            reject();
          },
          undefined
        );
      });
    } catch (e) {

    }
  }

  const createPdf = () => {
    const creationDate = dayjs().format('DD.MM.YYYY - HH:mm:ss');
    const doc = new jsPDF("landscape");
    let body = customSort ? customSort(data) : data;
    const transformations = assignPdfTransformations(schemeNamePlural);
    const head = exportConfig?.head ? exportConfig.head(thead) : thead;
    body = body
      .map((item) => {
        return head.reduce((obj, key) => {
          if (item.hasOwnProperty(key)) {
            obj[key] = item[key];
          }
          return obj;
        }, {});
      })
      .map((item) => {
        return head.map((key) => {
          if (typeof item[key] === "string") return t(item[key]);
          if (typeof item[key] === "object" && item[key] !== null && item[key] !== undefined) {
            if (transformations[key])
              return transformations[key](item[key]?.value);
          }
          return "";
        });
      });

    doc.text(exportConfig?.title || t(schemeNamePlural), 14, 10);
    const totalPagesExp = "{total_pages_count_string}";

    autoTable(doc, {
      startY: 20,
      headStyles: { fillColor: [80, 174, 47] },
      head: [head.map((h) => t(h))],
      body,
      didDrawPage: function (data) {
        //creation date
        doc.setFontSize(8)
        doc.text(`Erstellt: ${creationDate}`, doc.internal.pageSize.getWidth() - 53, 10);
        var str = doc.internal.getNumberOfPages()
        if (typeof doc.putTotalPages === 'function') {
          str = str + ' / ' + totalPagesExp
        }
        doc.setFontSize(10)
        var pageSize = doc.internal.pageSize
        var pageHeight = pageSize.height ? pageSize.height : pageSize.getHeight()
        doc.text(str, data.settings.margin.left, pageHeight - 10)
      },
    });
    if (typeof doc.putTotalPages === 'function') {
      doc.putTotalPages(totalPagesExp)
    }
    doc.save(`${t(schemeNamePlural)}_${dayjs().format("DDMMYYYY_HHmmss")}.pdf`);
  };
  const createCSV = () => {
    let body = customSort ? customSort(data) : data;
    const transformations = assignPdfTransformations(schemeNamePlural);
    const head = exportConfig?.head ? exportConfig.head(thead) : thead;

    body = body.map(item => {
      return head.reduce((acc, key) => {
        if (key in item) {
          acc[key] = item[key];
        }
        return acc;
      }, {});
    }).map(item => {
      let itemModified = {};
      for (let i in item) {
        let val = item[i];
        if (typeof item[i] === "string") val = t(item[i]);
        if (typeof item[i] === "object" && item[i] !== null && item[i] !== undefined) {
          if (transformations?.[i]) {
            val = transformations[i](item[i]?.value);
          }
        }
        itemModified[`${t(i)}`] = val;
      }
      return itemModified;
    });
    const csv = Papa.unparse(body, {
      delimiter: ";", // Verwende Semikolon als Trennzeichen
    });
    const bom = "\uFEFF";
    const csvWithBom = bom + csv;
    const blob = new Blob([csvWithBom], { type: 'text/csv;charset=utf-8;' });

    const url = URL.createObjectURL(blob);

    const link = document.createElement('a');
    link.href = url;
    link.download = `${t(schemeNamePlural)}_${dayjs().format("DDMMYYYY_HHmmss")}.csv`;
    link.click();
    URL.revokeObjectURL(url);
  };

  const expandList = () => {
    if (additionaltableClasses !== '')
      setAdditionalTableClasses('')
    else
      setAdditionalTableClasses('ch-100')
  }

  const renderListActions = () => {
    return (
      <div style={{ background: '#fff' }} className={`w-100 d-flex justify-content-end`}>
        {!loading && showQRExport && data?.length > 0 && (
          <div className="d-flex justify-content-end">
            <OverlayTrigger
              key={'tooltip_create_qr_code_list'}
              placement="auto"
              overlay={(props) => {
                return (
                  <Tooltip id={'tooltip_qr_code'} {...props}>
                    {t('tooltip_create_qr_code_list')}
                  </Tooltip>
                )
              }}
              delay={{ show: 250, hide: 400 }
              }
            >
              <Button
                size="sm"
                onClick={() => openQRConfigModal()}
                className="mt-0 me-2 p-1"
                variant="inline"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <BsQrCode size={22} />
                </div>
              </Button>
            </OverlayTrigger>
          </div>
        )}
        {!loading && showExport && data?.length > 0 && (
          <div className="d-flex justify-content-end">
            <OverlayTrigger
              key={'tooltip_overlay_csv'}
              placement="auto"
              overlay={(props) => {
                return (
                  <Tooltip id={'tooltip_csv'} {...props}>
                    {t('tooltip_create_csv')}
                  </Tooltip>
                )
              }}
              delay={{ show: 250, hide: 400 }
              }
            >
              <Button
                size="sm"
                onClick={() => createCSV()}
                className="mt-0 me-2 p-1"
                variant="inline"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <FaFileCsv size={22} />
                </div>
              </Button>
            </OverlayTrigger>
          </div>
        )}
        {!loading && showExport && data?.length > 0 && (
          <div className="d-flex justify-content-end">
            <OverlayTrigger
              key={'tooltip_overlay_pdf'}
              placement="auto"
              overlay={(props) => {
                return (
                  <Tooltip id={'tooltip_pdf'} {...props}>
                    {t('tooltip_create_pdf')}
                  </Tooltip>
                )
              }}
              delay={{ show: 250, hide: 400 }
              }
            >
              <Button
                size="sm"
                onClick={() => createPdf()}
                className="mt-0 me-2 p-1"
                variant="inline"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <BiSolidFilePdf size={26} />
                </div>
              </Button>
            </OverlayTrigger>
          </div>
        )}
        {!loading && data?.length > 0 && showExpand && (
          <div className="d-flex justify-content-end">
            <OverlayTrigger
              key={'tooltip_overlay_expand_list'}
              placement="auto"
              overlay={(props) => {
                return (
                  <Tooltip id={'tooltip_expand_list'} {...props}>
                    {t('tooltip_expand_list')}
                  </Tooltip>
                )
              }}
              delay={{ show: 250, hide: 400 }
              }
            >
              <Button
                size="sm"
                onClick={() => expandList()}
                className="me-2 p-1"
                variant="inline"
              >
                <div className="d-flex justify-content-center align-items-center">
                  {additionaltableClasses === '' ? <GiExpand size={26} /> : <MdMinimize size={26} />}
                </div>
              </Button>
            </OverlayTrigger>
          </div>
        )}
      </div>)
  }

  return (
    <>
      {(showCreate || showImporter || showFilter) &&
        <Form className="flex-wrap-reverse flex-md-wrap w-100 mb-3" as={Row}>
          {showFilter && (
            <Form.Group
              className="d-flex align-items-start justify-content-start mb-3 mb-md-0 flex-column"
              as={Col}
              lg={8}
              md={8}
              sm={12}
            >
              {filters.map((filter, i) => (
                <div
                  key={"div_cl_" + filter.id}
                  className={
                    "d-flex align-items-center justify-content-start" + (i > 0 ? " mt-3" : "")
                  }
                >
                  <Filter
                    key={"filterKey_" + filter.id}
                    disabled={loading}
                    defaultOperator={filter.filterOperator}
                    value={filter.filterSearchTerm}
                    searchProperty={filter.filterProperty}
                    filterId={filter.id}
                    onFilter={(p, st, o) => {
                      filterRequest(p, st, o, filter.id);
                    }}
                    head={[...properties, ...filterProperties].filter(p => excludeFilterProperties ? !excludeFilterProperties.includes(p) : true)}
                    onLoadingChange={onLoadingChange}
                  />
                  <div className="filter-button-actions d-flex">
                    {i > 0 && !loading && (
                      <Button
                        size="sm"
                        className="p-1"
                        key={"removeBtnKey_" + filter.id}
                        onClick={() => {
                          removeFilter(filter.id);
                        }}
                        variant="link"
                      >
                        <div>
                          <BiMinusCircle color="red" size={20} />
                        </div>
                      </Button>
                    )}
                    {((!filter.filterSearchTerm && filter?.filterOperator?.includes('exists'))
                      || filter.filterSearchTerm) &&
                      !loading && (
                        <Button
                          size="sm"
                          className="p-1"
                          key={"createBtnKey_" + filter.id}
                          onClick={() => createFilter()}
                          variant="link"
                        >
                          <div>
                            <BiPlusCircle size={20} />
                          </div>
                        </Button>
                      )}
                  </div>
                </div>

              ))}
              <div className="ps-4 pt-3 flex flex-column">
                <Button
                  disabled={loading}
                  style={{ fontSize: 14 }}
                  size="sm"
                  className="pt-2 px-4"
                  key={"filterBtnSubmit_key"}
                  onClick={() => {
                    dispatch(resetCache(schemeNamePlural));
                    setReload(!reload);
                  }}
                  variant="primary"
                >
                  <div className="d-flex justify-content-start">
                    <div><span>{t('execute')}</span></div>
                  </div>
                </Button>
                <Button
                  disabled={loading}
                  style={{ fontSize: 14 }}
                  size="sm"
                  className="pt-2 px-4"
                  key={"filterBtnReset_key"}
                  variant="light"
                  onClick={() => {
                    dispatch(clearListFilter({ namespace: schemeNamePlural }))
                  }}

                >
                  <div className="d-flex justify-content-start">
                    <div><span>{t('reset')}</span></div>
                  </div>
                </Button>
                {!loading && data?.length > 0 && cachedCollection?.fetchedAt && useCache && (
                  <div className="d-flex justify-content-start">
                    <div style={{
                      fontSize: 12,
                      color: '#50ae31',
                      fontWeight: 600,
                      opacity: 0.8,
                      marginTop: '5px',
                    }} >{`${t('fetched')} ${dayjs(cachedCollection.fetchedAt).format('DD.MM.YYYY HH:mm:ss')}`}</div>
                  </div>
                )}
              </div>
              {filterError && <div className="ps-4 pt-3 flex flex-row text-danger">
                {filterError}
              </div>}
            </Form.Group>
          )}
          <Form.Group
            className="d-flex align-items-center justify-content-md-end mb-3 mb-md-0"
            as={Col}
            md={4}
            sm={12}
          >
            {showImporter && (
              <CSVImporter
                className={
                  `text-nowrap ${showCreate ? "ms-4 ms-md-0 me-md-0" : "ms-4 ms-md-0 me-md-4"}`
                }
                onClose={() => setReload(!reload)}
                onRowProcessed={customCSVHandler || onRowProcessed}
                fields={
                  customCSVFields ? [...CSVhead, ...customCSVFields] : CSVhead
                }
              />
            )}
            {showCreate && (
              <Button
                onClick={() => onClickCreate(schemeNamePlural)}
                className={showImporter ? "ms-2 me-md-4" : "ms-4 ms-md-0 me-md-4"}
                variant="primary"
              >
                <div className="d-flex justify-content-center align-items-center">
                  <BiPlusCircle size={16} />
                  <span className="ms-1">{t("new")}</span>
                </div>
              </Button>
            )}
          </Form.Group>
        </Form>}
      <List
        showProgress={showProgress}
        abortManager={abortManager}
        schemeName={schemeNamePlural}
        listActions={renderListActions}
        defaultSortBy={defaultSortBy}
        tableClasses={`${tableClasses} ${additionaltableClasses}`}
        tableCellClasses={tableCellClasses}
        tableRowClasses={tableRowClasses}
        showCheckboxes={showCheckboxes}
        multiSelect={true}
        onShow={typeof onClickShow === "function" ? handleOnShow : undefined}
        onEdit={showEdit && handleOnEdit}
        onDelete={showDelete ? handleOnDelete : null}
        onClone={showClone && handleClone}
        loading={loading}
        head={thead}
        tableHeadMappings={tableHeadMappings}
        rows={customSort ? customSort(data) : data}
        showHistory={showHistory}
        autoTranslate={autoTranslate}
      />
    </>
  );
};

ColList.defaultProps = {
  title: "Vehicles",
  apiUrl: ApiUrls.BASE + ApiUrls.COLLECTIONS["vehicles"],
  schemeNameSingular: "Vehicle",
  schemeNamePlural: "vehicles",
  head: [
    "id",
    "fleetId",
    "serial",
    "type",
    "name",
    "model",
    "status",
    "description",
    "lastLat",
    "lastLng",
  ],
};

export const CollectionList = React.memo(ColList);
