import { CONSTANTS } from "@constants";
import PropTypes from "prop-types";
import React, { useEffect, useState } from "react";
import { get } from "utils/api";
import { SelectField } from "../../../../stories/crud/FieldTypes/SelectField";
import { isEmpty } from "../../../../stories/utils/common";

export const SelectModelProperty = (props) => {
  const {
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    value,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData,
  } = props;
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    const getOptions = async () => {
      const col = { ...collectionData };
      setLoading(true);
      const url =
        formData.modelType !== CONSTANTS.namespaces.COMPONENTS
          ? `/${formData.modelType}/properties`
          : `/${formData.modelType}/all-properties`;
      try {
        const res = await get(url, {});
        col.modelProperty.options = Object.keys(res.properties || res).map(
          (r) => ({ value: r, label: r })
        );
        onChangeCollectionData(col);
      } finally {
        setLoading(false);
      }
    };
    if (!isEmpty(formData.modelType)) getOptions();
  }, [formData.modelType]);

  return (
    <SelectField
      {...props}
      onChangeFormData={onChangeFormData}
      loading={loading}
    />
  );
};
SelectModelProperty.propTypes = {
  type: PropTypes.string,
  action: PropTypes.string,
  itemId: PropTypes.any,
  preDefinedProperties: PropTypes.any,
  schemesHandler: PropTypes.func,
  postHandler: PropTypes.func,
  patchHandler: PropTypes.func,
  backHandler: PropTypes.func,
  hidden: PropTypes.array,
  getHandler: PropTypes.func,
  attr: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
  formData: PropTypes.object,
  collectionData: PropTypes.object,
  htmlSchemes: PropTypes.object,
  onChangeFormData: PropTypes.func,
};
