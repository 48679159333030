import Highcharts from "highcharts";
import { HighchartsReact } from "highcharts-react-official";
import { t } from "i18next";
import React from "react";
import Button from "react-bootstrap/Button";
import PieChartContentLoader from "./utils/PieChartContentLoader";

const Pie = ({
  data,
  title = "",
  onSectionClick,
  collectionType,
  onShowAll = undefined,
  isLoading = true,
  minHeight = "440px",
  labelFormat,
}) => {


  let options = {
    credits: { enabled: false },
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      type: "pie",
      spacingBottom: 80
    },
    title: { text: "" },
    tooltip: {
      pointFormat:
        `{series.name}: <b>{point.percentage:.1f}%</b></br>${t('amount')}: <b>{point.y}</b>`,
    },
    accessibility: {
      point: {
        valueSuffix: "%",
      },
      enabled: false,
    },
    plotOptions: {
      pie: {
        allowPointSelect: true,
        cursor: "pointer",
        dataLabels: {
          enabled: true,
          distance: -50,
          format: labelFormat || "{point.percentage:.1f} %",
        },
        showInLegend: true,
      },
    },
    series: [
      {
        name: t("status"),
        colorByPoint: true,
        data: data,
      },
    ],
    legend: {
      margin: 0,
      padding: 0,
      floating: true,
      align: 'center',
      verticalAlign: 'bottom',
      layout: 'horizontal',
      y: 50
    },
  };
  if (onSectionClick) {
    options.series[0].point = {
      events: {
        click: function (event) {
          onSectionClick(this);
        },
      },
    };
  }

  return (
    <div className="w-100" style={{ minHeight }}>

      <div className={`w-100 ${!isLoading && 'd-none'}`}>
        <PieChartContentLoader />
      </div>
      <div className={`${isLoading ? 'd-none ' : ''}`}>
        <div style={{ minHeight: 73 }} className={`pie-chart-header h6 d-flex py-2 justify-content-between align-items-center`}>
          <div className="pie-chart-title fs-6 ms-3">{title}</div>

          <div className={`pie-chart-button me-3 text-nowrap`}>
            {onShowAll && <Button
              style={{ padding: "0.5rem", marginTop: "0.5rem" }}
              variant="outline-primary"
              onClick={(ev) => {
                onShowAll(collectionType);
              }}
            >
              {t("show_all")}
            </Button>}
          </div>
        </div>
        <HighchartsReact highcharts={Highcharts} options={options} />
      </div>

    </div>
  );
};

Pie.propTypes = {};

Pie.defaultProps = {
  data: [
    { name: "Group A", value: 400 },
    { name: "Group B", value: 300 },
    { name: "Group C", value: 300 },
    { name: "Group D", value: 200 },
  ],
};

export const PieChart = React.memo(Pie);
