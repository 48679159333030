import i18next, { t } from "i18next";
import moment from "moment";
import React from "react";
import useModal from "./modal/hook";
import TermsOfUse_DE from "./termsOfUse/de";
import TermsOfUse_EN from "./termsOfUse/en";

const Footer = () => {
  const { modal } = useModal();

  const showModal = () => {
    modal(
      i18next.language === "de" ? <TermsOfUse_DE /> : <TermsOfUse_EN />,
      t("legal_notice"),
      undefined,
      t("ok"),
      () => {},
      () => {}
    );
  };
  return (
    <footer className="py-3 my-4 me-0">
      <ul className="nav justify-content-center border-bottom pb-3 mb-3">
        <li className="nav-item">
          <a
            target="blank"
            href={t("privacy_policy_link")}
            className="nav-link px-2 text-body-secondary"
          >
            {t("privacy_policy")}
          </a>
        </li>
        <li className="nav-item">
          <span
            role="button"
            onClick={() => {
              showModal();
            }}
            className="nav-link px-2 text-body-secondary"
          >
            {t("legal_notice")}
          </span>
        </li>
        <li className="nav-item me-0">
          <a
            target="blank"
            href={t("terms_and_conditions_link")}
            className="nav-link px-2 text-body-secondary"
          >
            {t("terms_and_conditions")}
          </a>
        </li>
      </ul>
      <p className="text-center text-body-secondary">
        © {moment().format("YYYY")} VSC.Bike GmbH
      </p>
    </footer>
  );
};

export default Footer;
