import { configureStore } from '@reduxjs/toolkit';
import { persistCombineReducers, persistStore } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
import reducers from '../reducers';

// Redux Persist config
const config = {
  key: 'root',
  storage,
  whitelist: [
    'user',
  ],
  timeout: null,
};

const reducer = persistCombineReducers(config, reducers);

const loggerMiddleWare = (store) => (next) => (action) => {
  const result = next(action);
  if (process.env?.REACT_APP_STAGE !== 'production') {
    if (action.type.includes('/rejected')) {
      console.log(action);
    } else {
      console.log(action.type);
    }
  }

  return result;
};

const middleware = [loggerMiddleWare];

const store = configureStore({
  reducer: reducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
      immutableCheck: false,
    }).concat(middleware),
});

const persistor = persistStore(store, null, () => {
  store.getState();
});

export { persistor as persistor, store as store };
