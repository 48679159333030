import PropTypes from 'prop-types';
import React from 'react';
import { MultiselectField } from '../../../../stories/crud/FieldTypes/MultiselectField';
import { MultiselectAttachedRoles } from './MultiselectAttachedRoles';

export const MultiselectUsers = (props) => {
    switch (props?.attr) {
        case 'attachedRoles': {
            return <MultiselectAttachedRoles {...props} />;
        }
        default: {
            return <MultiselectField {...props} />;
        }
    }
}
MultiselectUsers.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
};