import React from 'react';

import { useSelector } from 'react-redux';



export const NavbarTitle = () => {
  const title = useSelector(state => state.globals.navTitle)
  return (
      <p className="m-0 p-0 d-flex justify-content-center fs-5" style={
        { color: '#fff' }
      }>{title}</p>
  )
};
