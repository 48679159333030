export const de = {
  translation: {
    nextPlannedAt: 'nächste wiederkehrende Aufgabe am',
    attachedRoles: 'zugeordnete Rollen',
    handleUVVAsInspection: "UVV als Inspektion behandeln",
    lockedAt: 'gesperrt',
    used: 'gebraucht',
    componentInstallationState: 'Einbauzustand',
    vehicleManufacturer: 'Hersteller',
    vehicleConstructionYear: 'Baujahr',
    fleetCompanyImportFolder: 'S3 Import Ordner',
    'create_fleet-company': 'Flotten-Firma erstellen',
    model: 'Modell',
    no_interval: 'kein Intervall',
    fleetCompanyInspectionInterval: 'Inspektionsintervall (innerhalb von 365 Tagen)',
    every_singular_month: 'Jeden Monat',
    every_plural_month: 'Alle {{month}} Monate',
    admin_sidebar_items: 'Admin',
    user_sidebar_items: 'Kunden',
    tooltip_create_csv: 'CSV generieren',
    statistics: 'Statistiken',
    vehicles_without_uvv: 'Fahrzeuge ohne abgeschlossene UVV',
    vehicles_without_inspection: 'Fahrzeuge ohne abgeschlossene Inspektionen',
    getLogs: 'App Logging aktivieren',
    yes: 'Ja',
    no: 'Nein',
    attachedRoles: 'Zugewiesene Rollen',
    admin_unlock_fleet: 'Möchtest du die Flotte wirklich entsperren ?\n\nDadurch werden alle nicht synchronisierten Daten des Nutzers ({{user}}) verworfen.',
    fleet_is_locked: 'Flotte gesperrt',
    locked: 'Gesperrt',
    fleet_is_locked_description: 'Die Flotte ist momentan durch einen Nutzer gesperrt. Bitte versuche es später noch einmal.',
    planned: 'geplant',
    plannedAt: 'geplant',
    createDemoData: ' Demodaten erstellen?',
    total: 'Gesamt',
    item_history: 'Objekthistorie',
    manual: 'manuell',
    imported: 'importiert',
    MODIFY: 'GEÄNDERT',
    REMOVE: 'GELÖSCHT',
    INSERT: 'ERSTELLT',
    createdBy: 'Benutzer',
    contentBefore: 'Daten vorher',
    contentChanged: 'Daten geändert',
    createdTasks: 'Erstellte Aufgaben',
    manually: 'manuell',
    automatically: 'automatisch',
    close: 'Schließen',
    vehicles_from_to: 'Fahrzeuge selektiert anhand <span class="fw-bold">"{{status}}"</span> vom <span class="fw-bold">{{from}}</span> bis <span class="fw-bold">{{to}}</span>',
    all_vehicles_from_to: 'Alle Fahrzeuge selektiert vom <span class="fw-bold">{{from}}</span> bis <span class="fw-bold">{{to}}</span>',
    components_from_to: 'Komponenten selektiert anhand <span class="fw-bold">"{{status}}"</span> vom <span class="fw-bold">{{from}}</span> bis <span class="fw-bold">{{to}}</span>',
    all_components_from_to: 'Alle Komponenten selektiert vom <span class="fw-bold">{{from}}</span> bis <span class="fw-bold">{{to}}</span>',
    'maintenance-tasks_from_to': 'Aufgaben selektiert anhand <span class="fw-bold">"{{status}}"</span> vom <span class="fw-bold">{{from}}</span> bis <span class="fw-bold">{{to}}</span>',
    'all_maintenance-tasks_from_to': 'Alle Aufgaben selektiert vom <span class="fw-bold">{{from}}</span> bis <span class="fw-bold">{{to}}</span>',
    actualVehicleStatus: 'Aktueller Fahrzeugstatus',
    actualComponentStatus: 'Aktueller Komponentenstatus',
    actualTaskStatus: 'Aktueller Aufgabenstatus',
    commissioningDate: 'Inbetriebnahme',
    full_period: 'Kompletter Zeitraum',
    day: 'Tag',
    days: 'Tage',
    apply: 'Übernehmen',
    last_week: '7 Tage',
    last_month: '30 Tage',
    last_year: '365 Tage',
    execute: "ausführen",
    reset: "zurücksetzen",
    cancel: "abbrechen",
    between: 'ist zwischen',
    inventoryNumber: 'Inventarnummer',
    reminders: 'Erinnerungen',
    "app-logs": 'App-Logs',
    logout: 'Abmelden',
    fetched: 'abgerufen am',
    refresh: 'aktualisieren',
    tooltip_show_vehicles: "Fahrzeuge anzeigen",
    tooltip_show_open_maintenance_tasks: "Offene Service-Aufgaben anzeigen",
    tooltip_open_fleets_item: "Zur Flotte",
    tooltip_open_vehicles_item: "Zum Fahrzeug",
    tooltip_open_components_item: "Zur Komponente",
    'tooltip_open_maintenance-company_item': "Zur Service-Firma",
    tooltip_open_maintenance_company_item: "Zur Service-Firma",
    'tooltip_open_maintenance-tasks_item': "Zur Service-Aufgabe",
    tooltip_open_fleet_companies_item: "Zur Flotten-Firma",
    tooltip_expand_list: "Liste maximieren/minimieren",
    tooltip_create_pdf: "PDF herunterladen",
    tooltip_create_qr_code_list: "QR Code Liste erzeugen",
    create_list: "Liste erzeugen",
    large_request_warning: "Die Abfrage kann etwas länger dauern als gewöhnlich, da es viele Einträge gibt. Möchtest du Fortfahren ?",
    language: 'Sprache',
    de: 'Deutsch',
    en: 'English',
    items_loaded: 'Einträge geladen',
    loading_items: 'Lade Einträge',
    from: "von",
    choose_fleet_company: "Wähle eine Flotten-Firma aus, um fortzufahren",
    enter_your_email: 'Ihre E-Mail Adresse',
    defect_components: 'Defekte Komponenten',
    reportedAt: 'Zeitstempel aus .CSV',
    inspection: 'Inspektion',
    amount: 'Anzahl',
    company: 'Firma',
    company_dashboard: 'Firmen-Dashboard',
    dashboard: 'Dashboard',
    uvv: "UVV",
    fleets: "Flotten",
    users: "Benutzer",
    "fleet-companies": "Flotten-Unternehmen",
    fleet: "Flotte",
    vehicle: "Fahrzeug",
    vehicles: "Fahrzeuge",
    "maintenance-tasks": "Service-Aufgaben",
    "maintenance-companies": "Service-Unternehmen",
    maintenanceTasks: "Service-Aufgaben",
    "maintenance-task": "Service-Aufgabe",
    components: "Komponenten",
    component: "Komponente",
    componentId: "Komponente",
    new: "neu",
    all: "Alle",
    Filter: "Filtern",
    create: "anlegen",
    edit: "bearbeiten",
    delete: "löschen",
    actions: "Aktionen",
    no_data: "Keine Daten",
    import_csv: ".csv Import",
    create_fleet: "Flotte erstellen",
    edit_fleet: "Flotte {{name}} bearbeiten",
    create_vehicle: "Fahrzeug erstellen",
    edit_vehicle: "Fahrzeug {{name}} bearbeiten",
    "create_maintenance-task": "Service-Aufgabe erstellen",
    "edit_maintenance-task": "Service-Aufgabe {{name}} bearbeiten",
    create_component: "Komponente erstellen",
    edit_component: "Komponente {{name}} bearbeiten",
    fleetName: "Name",
    fleetCompanyName: "Firmenname",
    maintenanceCompanyName: "Service-Firma Name",
    createdAt: "erstellt",
    changedAt: "geändert",
    serial: "Seriennummer",
    vehicleStatus: "Fahrzeugstatus",
    vehicleName: "Name",
    description: "Beschreibung",
    taskStatus: "Aufgabenstatus",
    vehicleId: "Fahrzeug",
    fleetId: "Flotte",
    status: "Status",
    type: "Typ",
    name: "Name",
    last_connection: "letzte Verbindung",
    mileage: "Kilometerstand",
    inProgress: "In Bearbeitung",
    open_link: "{{item}} anzeigen",
    all_components: "Alle Komponenten",
    all_vehicles: "Alle Fahrzeuge",
    "all_maintenance-tasks": "Alle Service-Aufgaben",
    all_maintenanceTasks: "Alle Service-Aufgaben",
    show_all: "Alle anzeigen",
    from_date: "von",
    to_date: "bis",
    maintenance_history: "Verlauf Service-Aufgaben",
    vehicle_history: "Verlauf Fahrzeug-Status",
    hello: "Hallo {{name}}!",
    accidentPreventionRegulationAccepted:
      "Unfallverhütungsvorschriften wurden geprüft",
    taskCategory: "Aufgabenkategorie",
    componentDefect: "Art des Defekts",
    vehicleType: "Fahrzeugtyp",
    lastPing: "letzter Standort",
    fleetCompanyId: "Flotten-Firma",
    maintenanceCompanyId: "Service-Firma",
    lat: "Breitengrad",
    lng: "Längengrad",
    choose: "auswählen",
    choose_none: "Auswahl aufheben",
    reset_filter: "Filter zurücksetzen",
    is_equal: "ist gleich",
    filter_error: "Bitte konkretisiere deine Suche, um Ergebnisse zu erhalten. ",
    unequal: "ist ungleich",
    not_contains: "beinhaltet nicht",
    is_not: "ist nicht",
    greater_than: "ist größer als",
    less_than: "kleiner als",
    contains: "beinhaltet",
    begins_with: "beginnt mit",
    is_true: "wahr",
    is_false: "falsch",
    isDemoUser: "Demo-Account",
    demo_system_on: "Demosystem",
    demo_system_off: "Livesystem",
    demo_mode: "Demomodus",
    exists: "ist vorhanden",
    not_exists: "ist nicht vorhanden",
    is_in: "beinhaltet (kommasepariert)",
    delete_confirmation:
      "möchtest du dieses Objekt (und damit verbundene Objekte) wirklich löschen?",
    delete_multiple_confirmation:
      "möchtest du diese Objekte (und damit verbundene Objekte) wirklich löschen?",
    upload_files: "Dateien hochladen",
    upload_file: "Datei hochladen",
    import_file_tasks: "Aufgaben-Datei importieren",
    only_utf_8: "Kommaseparierte, UTF-8 kodierte CSV Datei",
    importing_file: 'Datei importiert.',
    NotAuthorizedException: "Benutzername und/oder Passwort sind falsch.",
    UserNotFoundException: "Benutzername nicht gefunden.",
    save: "Speichern",
    success: "Erfolgreich geändert",
    error: "Fehler",
    edit_user: "Benutzer bearbeiten",
    create_user: "Benutzer erstellen",
    userEmail: "E-mail",
    admin: "Administrator",
    maintenance_manager: "Service-Manager",
    fleet_manager: "Flotten-Manager",
    fleet_viewer: "Flottenbetrachter (FM nur lesen)",
    attachedRoles: "Benutzerrollen",
    datasets: "Datensätze",
    errors: "Fehler",
    histories: "Änderungshistorie",
    "fleet-company": "Flottenunternehmen",
    "maintenance-company": "Serviceunternehmen",
    translations: "Übersetzungen",
    menu: "Menü",
    privacy_policy: "Datenschutz",
    privacy_policy_link: "https://vsc.bike/datenschutz/portal",
    terms_and_conditions: "Nutzungsbedingungen",
    terms_and_conditions_link: "https://vsc.bike/agb/portal",
    legal_notice: "Impressum",
    edit_changed_component_desc:
      "Möchtest du den neuen, ausgetauschten Komponenten jetzt bearbeiten?",
    edit_changed_component_title: "Ausgetauschte Komponente bearbeiten",
    change_password: "Passwort ändern",
    new_password: "Neues Passwort",
    password_constraints: `Ihr Passwort muss mindestens 8 Zeichen lang sein, einen Großbuchstaben und einen Kleinbuchstaben sowie ein Sonderzeichen enthalten.`,
    try_again_require_new_password: "Bitte versuche es erneut",
    hmi: "HMI",
    front_tire: "Vorderrad",
    rear_tire: "Hinterrad",
    front_rim: "Felge Vorderrad",
    rear_rim: "Felge Hinterrad",
    chain: "Kette",
    pedals: "Pedale",
    front_brake: "Bremse (vorne)",
    rear_brake: "Bremse (hinten)",
    front_brake_pads: "Bremsbeläge (vorne)",
    rear_brake_pads: "Bremsbeläge (hinten)",
    front_brake_lever: "Bremshebel (vorne)",
    rear_brake_lever: "Bremshebel (hinten)",
    front_brake_rotor: "Scheibenbremse (vorne)",
    rear_brake_rotor: "Scheibenbremse (hinten)",
    front_brake_cable: "Bremsseilzug (vorne)",
    rear_brake_cable: "Bremsseilzug (hinten)",
    rear_shifter_cable: "Schaltseilzug (hinten)",
    middle_shifter_cable: "Schaltseilzug (mitte)",
    shift_lever: "Schalthebel",
    battery: "Akku",
    motor: "Motor",
    speed_sensor: "Geschwindigkeitssensor",
    flat: "Platten",
    loosen_spoke: "Speiche lose",
    broken_spoke: "Speiche gebrochen",
    broken: "gebrochen",
    demolished: "beschädigt",
    deformed: "deformiert",
    cracked: "gerissen",
    loosen: "lose",
    shaft_broken: "Achse gebrochen",
    bearing_broken: "Lager gebrochen",
    bearing_rough: "Lager abgenutzt",
    mounting_part_loosen: "Anbauteil lose",
    torn: "zerrissen",
    defect: "Defekt",
    operational: "Funktional",
    retired: "Ausgemustert",
    inactive: "Inaktiv",
    maintenance: "In Wartung",
    mechanical: "mechanisch",
    electrical: "elektrisch",
    other: "Sonstiges",
    open: "offen",
    in_progress: "in Bearbeitung",
    closed: "abgeschlossen",
    repaired: "repariert",
    replaced: "ausgetauscht",
    no_action: "kein Defekt am Fahrzeug",
    vehicle_not_present: "Fahrzeug nicht vorhanden",
    settings: "Einstellungen",
    importer: "Importer",
    componentType: "Typ",
    componentName: "Komponente",
    mileageCurrent: "Kilometerstand",
    syncedAt: "Synchronisiert am",
    componentModel: "Modell",
    componentStatus: "Status",
    sync_vehicle_components: "Fahrzeugkomponenten synchronisieren",
    componentCategory: 'Teilekategorie',
    taskAction: "Wartungsaktion",
    frame: 'Rahmen',
    controls: 'Bedienelemente',
    brakes: 'Bremsen',
    displays: 'Anzeigen',
    drive: 'Antrieb',
    fenders: 'Schutzbleche',
    handlebar: 'Lenker',
    lights: 'Beleuchtung',
    reflectors: 'Reflektoren',
    stands: 'Ständer',
    //PIN AG translations
    "drink_holder": "Getränkehalter",
    "front_basket": "Korb vorn",
    "rear_basket": "Korb hinten",
    "corrosion_condition": "Korrosionszustand",
    "key_box": "Schlüsselkiste",
    "fairing": "Verkleidung",
    "screws": "Verschraubungen",
    "front_bumper": "Stoßfänger vorn",
    "rear_bumper": "Stoßfänger hinten",
    "windshield": "Frontscheibe",
    "cargo_space_doors": "Laderaum -> Türen",
    "cargo_space_shelving_system": "Laderaum -> Regalsystem",
    "cargo_box": "Ladebox",
    "lid": "Deckel",
    "drawbar": "Deichsel",
    "coupling": "Kupplung",
    "tension_strap": "Spanngurt",
    "safety_strap": "Fangband",
    "fork": "Gabel",
    "saddle": "Sattel",
    "seat_post": "Sattelstütze",
    "seat": "Sitz",
    "bell": "Klingel",
    "grips": "Griffe",
    "headset": "Steuersatz",
    "gear_shift": "Schaltung",
    "battery_switch": "Schalter Akku",
    "rear_view_mirror": "Rückspiegel",
    "horn": "Signalhorn",
    "heated_windshield": "Frontscheibenheizung",
    "heated_grips": "Griffheizung",
    "windshield_wiper": "Scheibenwischer",
    "miscellaneous_switches": "Sonstige Schalter",
    "general_speedometer": "Tacho allg.",
    "fuel_display_range": "Tankanzeige/Reichweite",
    "oil_display": "Ölanzeige",
    "engine_control_light": "Motorkontrollleuchte",
    "actuating_lever": "Betätigungshebel",
    "wheels": "Räder",
    "wheel_front": "Rad (vorne)",
    "wheel_rear": "Rad (hinten)",
    "spring": "Feder",
    "main_stand": "Hauptständer",
    "side_stand": "Seitenständer",
    "front_brakes": "Radbremsen vorn",
    "rear_brakes": "Radbremsen hinten",
    "parking_brake": "Feststellbremse",
    "foot_brake": "Fußbremse",
    "front_running_light": "Fahrlicht vorn",
    "rear_running_light": "Fahrlicht hinten",
    "dynamo": "Dynamo",
    "parking_light": "Standlicht",
    "dimmed_headlight": "Abblendlicht",
    "high_beam": "Fernlicht",
    "tail_light": "Rücklicht",
    "brake_light": "Bremslicht",
    "turn_indicator": "Fahrtrichtungsanzeiger",
    "front_reflector": "Reflektor vorn",
    "rear_reflector": "Reflektor hinten",
    "spoke_reflectors_front": "Speichenreflektoren vorn",
    "spoke_reflectors_rear": "Speichenreflektoren hinten",
    "tread_depth_wear_front": "Profiltiefe/Verschleiß vorn",
    "tread_depth_wear_front_left": "Profiltiefe/Verschleiß vorne links",
    "tread_depth_wear_front_right": "Profiltiefe/Verschleiß vorne rechts",
    "tread_depth_wear_rear": "Profiltiefe/Verschleiß hinten",
    "tread_depth_wear_rear_left": "Profiltiefe/Verschleiß hinten links",
    "tread_depth_wear_rear_right": "Profiltiefe/Verschleiß hinten rechts",
    "tire_pressure_front": "Luftdruck vorn",
    "tire_pressure_front_left": "Luftdruck vorne links",
    "tire_pressure_front_right": "Luftdruck vorne rechts",
    "tire_pressure_rear": "Luftdruck hinten",
    "tire_pressure_rear_left": "Luftdruck hinten links",
    "tire_pressure_rear_right": "Luftdruck hinten rechts",
    "spokes_front": "Speichen vorn",
    "spokes_rear": "Speichen hinten",
    "spokes_front_left": "Speichen vorne links",
    "spokes_front_right": "Speichen vorne rechts",
    "spokes_rear_left": "Speichen hinten links",
    "spokes_rear_right": "Speichen hinten rechts",
    "rims_front": "Felgen vorn",
    "rims_rear": "Felgen hinten",
    "rims_front_left": "Felgen vorne links",
    "rims_front_right": "Felgen vorne rechts",
    "rims_rear_left": "Felgen hinten links",
    "rims_rear_right": "Felgen hinten rechts",
    "front_fender": "Schutzblech vorn",
    "rear_fender": "Schutzblech hinten",
    "chain_belt": "Kette/Riemen",
    "chain_guard_defect": "Kettenschutz defekt",
    "tension_defect": "Spannung fehlerhaft",
    "bottom_bracket": "Tretlager",
    "front_sprocket": "Zahnkranz vorn",
    "rear_sprocket": "Zahnkranz hinten",
    "crank": "Kurbel",
    "engine": "Motor",
    "battery_mount": "Halterung Akku",
    "wiring": "Verkabelung",
    "does_not_start": "geht nicht an",
    "shows_error_message": "zeigt Fehlermeldung",
    "casing_damaged": "Gehäuse beschädigt",
    "casing_defect": "Gehäuse defekt",
    "wirings_defect": "Verkabelung defekt",
    "contacts_defect": "Kontakte defekt",
    "fluid_leak": "Flüssigkeitsaustritt",
    "contacts": "Kontakte",
    "miscellaneous": "Sonstiges",
    //PIN AG translations
    //PENDIX translations
    "tire": "Reifen",
    "puncture": "Platten",
    "front_tire_tread": "Vorderreifenprofil",
    "worn": "abgenutzt",
    "front_tire_sidewall": "Vorderreifen-Seitenwand",
    "damaged": "beschädigt",
    "rear_tire_tread": "Hinterreifenprofil",
    "rear_tire_sidewall": "Hinterreifen-Seitenwand",
    "front_spoke": "Vorderspeiche",
    "wheel": "Rad",
    "front_hub_bearing": "Vorderradnabenlager",
    "rough": "rau",
    "rear_spoke": "Hinterspeiche",
    "rear_hub_bearing": "Hinterradnabenlager",
    "drive_train": "Antriebsstrang",
    "bottom_bracket_shaft_crank_thread": "Tretlager-Mittelschaft-Kurbelgewinde",
    "torn_out": "ausgerissen",
    "bottom_bracket_shaft": "Tretlager-Mittelschaft",
    "bottom_bracket_bearing": "Tretlager-Mittellager",
    "bottom_bracket_mounting_part": "Tretlager-Montageteil",
    "front_disc_brake": "Vorderrad-Scheibenbremse",
    "brake": "Bremse",
    "front_disc_brake_brake_line": "Bremsleitung der Vorderrad-Scheibenbremse",
    "leaking": "undicht",
    "rear_disc_brake": "Hinterrad-Scheibenbremse",
    "rear_disc_brake_brake_line": "Bremsleitung der Hinterrad-Scheibenbremse",
    "front_disc_brake_pads": "Bremsbeläge der Vorderrad-Scheibenbremse",
    "rear_disc_brake_pads": "Bremsbeläge der Hinterrad-Scheibenbremse",
    "front_disc_brake_lever": "Bremshebel der Vorderrad-Scheibenbremse",
    "rear_disc_brake_lever": "Bremshebel der Hinterrad-Scheibenbremse",
    "parking_brake_lever_hydraulic": "Hydraulischer Feststellbremshebel",
    "front_disc_brake_brake_disc": "Bremsscheibe der Vorderrad-Scheibenbremse",
    "rear_disc_brake_brake_disc": "Bremsscheibe der Hinterrad-Scheibenbremse",
    "rear_shifter_cable_housing": "Schaltzuggehäuse hinten",
    "shifting": "Schalten",
    "front_shifter_cable_housing": "Schaltzuggehäuse vorne",
    "front_shifter_cable": "Schaltzug vorne",
    "front_shift_lever": "Schalthebel vorne",
    "rear_shift_lever": "Schalthebel hinten",
    "display_motor": "Anzeigemotor",
    "electronic": "Elektronik",
    "luggage_rack_front": "Gepäckträger vorne",
    "luggage_rack": "Gepäckträger",
    "bent": "verbogen",
    "luggage_rack_front_stand": "Gepäckträgerständer vorne",
    "stand": "Ständer",
    "luggage_rack_rear": "Gepäckträger hinten",
    "saddle_surface": "Satteloberfläche",
    "saddle_frame": "Sattelgestell",
    "seat_clamp": "Sattelklemme",
    "bar": "Lenker",
    "stem": "Vorbau",
    "front_fender_sheet": "Schutzblech vorne",
    "fender": "Schutzblech",
    "front_fender_brace": "Schutzblechstrebe vorne",
    "rear_fender_sheet": "Schutzblech hinten",
    "rear_fender_brace": "Schutzblechstrebe hinten",
    "frame_single_track_short": "Rahmen Single Track kurz",
    "frame_single_track_long": "Rahmen Single Track lang",
    "frame_multi_track_short": "Rahmen Multi Track kurz",
    "frame_multi_track_long": "Rahmen Multi Track lang",
    "lighting_front_headlight": "Beleuchtung Frontscheinwerfer",
    "lighting": "Beleuchtung",
    "lighting_front_lamp": "Frontlampe",
    "lighting_front_cabel": "Frontkabel",
    "lighting_rear_taillight": "Rücklicht",
    "lighting_rear_lamp": "Rücklampe",
    "lighting_rear_cabel": "Rückkabel",
    "lighting_frame_dynamo": "Rahmendynamo",
    "forkes_shaft": "Gabelschaft",
    "forkes_stand_pipe": "Gabelstandrohr",
    "headsets": "Steuersätze",
    "chain_ring_front": "Kettenblatt vorne",
    "crank_arm_left": "Kurbelarm links",
    "crank_arm_right": "Kurbelarm rechts",
    "chain_ring_rear": "Kettenblatt hinten",
    "cassette_rear": "Kassette hinten",
    "front_derailleur": "Umwerfer vorne",
    "misadjusted": "fehljustiert",
    "rear_derailleur": "Schaltwerk hinten",
    "gear_hub_gearbox": "Getriebegehäuse",
    "gear_hub_gearshift_chain": "Schaltkette der Getriebenabe",
    "gear_hub": "Getriebenabe",
    "mirror": "Spiegel",
    "center_bike_stand": "Mittelfahrradständer",
    "center_side_bike_stand": "Mittelseitenfahrradständer",
    "side_bike_stand": "Seitenfahrradständer",
    "motor_rosenberg_plug": "Motor-Rosenberg-Stecker",
    "not_calibratable": "nicht kalibrierbar",
    "motor_plug": "Motorstecker",
    "motor_housing": "Motorgehäuse",
    "motor_torque_arm": "Motordrehmomentarm",
    "motor_bearing": "Motorlager",
    "generator_crank_pedal_thread_right": "Generator-Kurbelgewinde rechts",
    "generator": "Generator",
    "generator_crank_pedal_thread_left": "Generator-Kurbelgewinde links",
    "generator_housing": "Generatorgehäuse",
    "generator_crank_square_shaft_thread_right": "Generator-Vierkantgewinde rechts",
    "generator_crank_square_shaft_thread_left": "Generator-Vierkantgewinde links",
    "generator_motor_bearing": "Generator-Motorlager",
    "generator_square_mount": "Generator-Vierkanthalterung",
    "motor_generator_housing": "Motor-Generator-Gehäuse",
    "coloured_flashing": "farbiges Blinken",
    "error_code": "Fehlercode",
    "battery_shell": "Batteriegehäuse",
    "battery_rosenberg_plug": "Batterie-Rosenberg-Stecker",
    "battery_usb_plug": "Batterie-USB-Stecker",
    "battery_rotary_switch": "Batterie-Drehschalter",
    "not_charging": "lädt nicht",
    "not_connecting": "verbindet nicht",
    "overheated": "überhitzt",
    "bottom_bracket_torque_sensor": "Tretlager-Drehmomentsensor",
    "front_drum_brake": "Vorderrad-Trommelbremse",
    "front_drum_brake_brake_cable": "Bremskabel der Vorderrad-Trommelbremse",
    "coaster_brake": "Rücktrittbremse",
    "front_drum_brake_pads": "Bremsbeläge der Vorderrad-Trommelbremse",
    "front_drum_brake_lever": "Bremshebel der Vorderrad-Trommelbremse",
    "front_rim_brake": "Vorderrad-Felgenbremse",
    "front_rim_brake_brake_cable": "Bremskabel der Vorderrad-Felgenbremse",
    "front_rim_brake_pads": "Bremsbeläge der Vorderrad-Felgenbremse",
    "front_rim_brake_lever": "Bremshebel der Vorderrad-Felgenbremse",
    "parking_brake_lever_mechanical": "Mechanischer Feststellbremshebel",
    "lighting_hub_dynamo": "Lichtmaschine-Nabendynamo",
    //PENDIX translations
  },
};
