import PropTypes from "prop-types";
import React from "react";
import Fade from "react-bootstrap/Fade";
import { VSCLoader } from "./VSCLoader";

export const LoadingWrapper = ({ loading, style }) => {
  return (
    <Fade in={loading}>
      <div
        style={{ height: "100%", ...style }}
        className={"loading-wrapper" + (loading ? " active" : "")}
      >
        <VSCLoader size={'lg'} />
      </div>
    </Fade>
  );
};

LoadingWrapper.propTypes = {
  icon: PropTypes.any,
};

LoadingWrapper.defaultProps = {
  loading: true,
};
