import { translations } from '@aws-amplify/ui';
import {
  Authenticator,
  ThemeProvider,
  useTheme
} from "@aws-amplify/ui-react";
import { I18n } from "aws-amplify/utils";
import { Colors } from "constants/colors";
import React from "react";
import { Card } from 'react-bootstrap';
import logo from "../../assets/vsc_logo_portal_white.svg";
import "./Login.css";


export default function Login() {
  const { tokens } = useTheme();
  const theme = {
    name: "AuthVSCTheme",
    tokens: {
      colors: {
        background: {
          primary: {
            value: 'rgba(0,0,0,0.1)',
          },
          secondary: {
            value: tokens.colors.neutral["100"].value,
          },
        },
        font: {
          active: {
            value: '#fff',
          },
          interactive: {
            value: tokens.colors.white.value,
          },
          primary: {
            value: '#fff'
          }
        },
        brand: {
          primary: {
            "10": Colors.primary,
            "80": Colors.primary,
            "90": Colors.primary,
            "100": Colors.primary,
          },
        },
      },
      components: {
        authenticator: {
          router: { borderWidth: 0, backgroundColor: 'transparent', boxShadow: 0 },
        },
        tabs: {
          item: {
            _focus: {
              color: {
                value: tokens.colors.white.value,
              },
            },
            _hover: {
              color: {
                value: tokens.colors.white.value,
              },
            },
            _active: {
              color: {
                value: tokens.colors.white.value,
              },
            },
          },
        },
      },
    },
  };

  const components = {
    Header() {
      return (
        <img
          alt="logo"
          src={logo}
          style={{
            width: "100%",
            maxWidth: "320px",
            height: "auto",
            margin: "0 auto",
            display: "block",
            marginBottom: '20px',
          }}
        />
      );
    },
    ForceNewPassword: {
      Footer() {
        return (
          <Card style={{ fontSize: 14, background: 'transparent', color: '#255216', borderColor: '#255216' }}>
            <Card.Body>
              <p><b>Die Passwortanforderungen sind:</b></p>
              <ul>
                <li>Mindestens 8 Zeichen lang</li>
                <li>Enthält mindestens 1 Zahl</li>
                <li>Enthält mindestens 1 Sonderzeichen</li>
                <li>Enthält mindestens 1 Großbuchstaben</li>
                <li>Enthält mindestens 1 Kleinbuchstaben</li>
              </ul>
            </Card.Body>
          </Card>)
      }
    }
  };

  const formFields = {
    signIn: {
      username: {
        labelHidden: true,
      },
      password: {
        labelHidden: true
      }
    },
    forgotPassword: {
      username: {
        labelHidden: true,
      }
    }
  }
  I18n.putVocabularies(translations);
  I18n.putVocabularies({
    de: {
      'Enter your Username': 'E-Mail Adresse',
      'Enter your Password': 'Passwort',
      'Please confirm your Password': 'Passwort bestätigen'
    }
  });
  I18n.setLanguage('de');

  I18n.putVocabulariesForLanguage('de', {
    'Forgot Password?': 'Passwort vergessen?',
    'Reset Password': 'Passwort zurücksetzen'
  });
  return (
    <ThemeProvider theme={theme}>
      <Authenticator formFields={formFields} hideSignUp={true} className="Login" components={components} />
    </ThemeProvider>
  );
}

