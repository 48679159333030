import { CONSTANTS } from '@constants';
import PropTypes from 'prop-types';
import React from 'react';
import { selectUseDemoBackend } from 'store/userSlice';
import { store } from '../../../../store';
import { SelectField } from '../../../../stories/crud/FieldTypes/SelectField';

export const SelectUsers = (props) => {
    switch (props?.attr) {
        case 'fleetCompanyId': {
            const data = props.formData;
            if (!selectUseDemoBackend(store.getState()) && data?.isDemoUser) return <></>;
            if (!data?.attachedRoles || data?.attachedRoles?.length === 0) return <></>;

            const isAdmin = data?.attachedRoles?.find(r => r === 'admin');
            const isFleetManager = data?.attachedRoles?.find(r => r === CONSTANTS.roles.FLEET_MANAGER);
            const isFleetOperator = data?.attachedRoles?.find(r => r === CONSTANTS.roles.FLEET_OPERATOR);
            const isMaintenanceEmployee = data?.attachedRoles?.find(r => r.includes('maintenance_'));
            if (isAdmin) return <></>;
            if (isFleetManager || isFleetOperator) return <SelectField {...props} />;
            if (isMaintenanceEmployee) return <SelectField {...props} />;

            return <SelectField {...props} />;
        }
        case 'fleetId': {
            // const data = props.formData;
            // if (!data?.attachedRoles || data?.attachedRoles?.length === 0) return <></>;

            // const isAdmin = data?.attachedRoles?.find(r => r === 'admin');
            // const isFleetOperator = data?.attachedRoles?.find(r => r === CONSTANTS.roles.FLEET_OPERATOR);

            // if (isAdmin) return <></>;
            // if (isFleetOperator) return <SelectFleetId {...props} />;
            return <></>;
        }
        case 'maintenanceCompanyId': {

            const data = props.formData;
            if (!selectUseDemoBackend(store.getState()) && data?.isDemoUser) return <></>;
            if (!data?.attachedRoles || data?.attachedRoles?.length === 0) return <></>;

            const isAdmin = data?.attachedRoles?.find(r => r === 'admin');
            const isFleetEmployee = data?.attachedRoles?.find(r => r.includes('fleet_'));
            const isMaintenanceEmployee = data?.attachedRoles?.find(r => r.includes('maintenance_'));
            if (isAdmin) return <></>;
            if (isMaintenanceEmployee) return <SelectField {...props} />;
            if (isFleetEmployee) return <></>;

            return <SelectField {...props} />;
        }
        default: {
            return <SelectField {...props} />
        }
    }
}
SelectUsers.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
};