
import React from 'react'
import ContentLoader from 'react-content-loader'

const PieChartContentLoader = ({height = '400', width = '100%',}) => {
  return (
    <ContentLoader height={height} width={width}>
      <rect x="4%" y="3%" rx="0" ry="0" width="92%" height="5%" />
      <circle cx="50%" cy="50%" r="30%" />
      <rect x="4%" y="93%" rx="0" ry="0" width="92%" height="5%" />
    </ContentLoader>
  )
}

export default PieChartContentLoader