import { setProgressItems } from "store/globalsSlice";
import { store } from "../store";

class AbortManagerInstance {
    cancelFunctions = [];
    initCancel(id, cancelFunction) {
        if (id && cancelFunction) this.cancelFunctions.push({ id, cancelFunction });
    }
    cancel(id) {
        if (id) {
            const deletedIndexes = [];
            for (let i in this.cancelFunctions) {
                const funcObj = this.cancelFunctions[i];
                if (funcObj.id === id && funcObj.cancelFunction) {
                    funcObj.cancelFunction(`cancelled id: ${id}`)
                    deletedIndexes.push(i);
                }
            }
            for (let i in deletedIndexes) {
                const index = deletedIndexes[i];
                if (this.cancelFunctions?.[index]) this.cancelFunctions.splice(index, 1);
            }
            store.dispatch(setProgressItems(null))
        }
    }
    success(id) {
        if (id) {
            const deletedIndexes = this.cancelFunctions.filter(o => o?.id === id).map((o, i) => i);
            for (let i in deletedIndexes) {
                const index = deletedIndexes[i];
                if (this.cancelFunctions?.[index]) this.cancelFunctions.splice(index, 1);
            }
        }
    }
}

export const AbortManager = new AbortManagerInstance();