export default class ApiUrls {
    static BASE = '/api';
    static COLLECTIONS = {
        maintenanceTasks:'/maintenance-tasks', 
        maintenanceTasksHistory:'/maintenance-tasks/history', 
        maintenanceCompanies:'/maintenance-companies',
        maintenanceCompaniesHistory:'/maintenance-companies/history',
        vehicles:'/vehicles',
        vehiclesHistory:'/vehicles/history',
        components: '/components',
        componentsHistory: '/components/history',
        fleets:'/fleets',
        fleetsHistory:'/fleets/history',
        fleetCompanies:'/fleet-companies',
        fleetCompaniesHistory:'/fleet-companies/history',
        users:'/users',
        usersHistory:'/users/history',
        roles:'/roles',
        rolesHistory:'/roles/history',
        rolePermissions:'/role-permissions',
        rolePermissionsHistory:'/role-permissions/history',
    }
}