import PropTypes from 'prop-types';
import React from 'react';
import { SelectField } from '../../../../stories/crud/FieldTypes/SelectField';
import { isEmpty } from '../../../../stories/utils/common';
import { SelectComponentCategory } from './SelectComponentCategory';
import { SelectComponentName } from './SelectComponentName';
import { SelectVehicleId } from './SelectVehicleId';

export const SelectFieldComponents = (props) => {

    switch (props?.attr) {
        case 'vehicleId': {
            return <SelectVehicleId {...props} />
        }
        case 'componentCategory': {
            const data = props.formData;
            return !isEmpty(data.vehicleId) ?
                <SelectComponentCategory {...props} /> : <></>
        }
        case 'componentName': {
            const data = props.formData;
            return !isEmpty(data.vehicleId) &&
                !isEmpty(data.componentCategory) ?
                <SelectComponentName {...props} /> : <></>
        }

        default: {
            return <SelectField {...props} />
        }
    }
}
SelectFieldComponents.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
};