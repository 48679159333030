import { CONSTANTS } from '@constants';
import PropTypes from 'prop-types';
import React from 'react';
import { SelectField } from '../../../../stories/crud/FieldTypes/SelectField';
import { isEmpty } from '../../../../stories/utils/common';
import { SelectComponentCategory } from './SelectComponentCategory';
import { SelectComponentDefect } from './SelectComponentDefect';
import { SelectComponentId } from './SelectComponentId';
import { SelectFleetId } from './SelectFleetId';
import { SelectTaskAction } from './SelectTaskAction';
import { SelectTaskCategory } from './SelectTaskCategory';
import { SelectTaskStatus } from './SelectTaskStatus';
import { SelectVehicleId } from './SelectVehicleId';

export const SelectFieldMaintenanceTasks = (props) => {

    switch (props?.attr) {
        case 'fleetId': {
            return <SelectFleetId {...props} />
        }
        case 'vehicleId': {
            return !isEmpty(props.formData.fleetId) ?
                <SelectVehicleId {...props} /> :
                <></>
        }
        case 'taskCategory': {
            return !isEmpty(props.formData.vehicleId) ?
                <SelectTaskCategory {...props} /> :
                <></>
        }
        case 'componentCategory': {
            const data = props.formData;
            return !isEmpty(data.vehicleId) &&
                (data.taskCategory === 'mechanical' || data.taskCategory === 'electrical') ?
                <SelectComponentCategory {...props} /> : <></>
        }
        case 'componentId': {
            const data = props.formData;
            return !isEmpty(data.vehicleId) &&
                !isEmpty(data.componentCategory) &&
                (data.taskCategory === 'mechanical' || data.taskCategory === 'electrical') ?
                <SelectComponentId {...props} /> : <></>
        }
        case 'componentDefect': {
            const data = props.formData;
            return !isEmpty(data.componentId) && !isEmpty(data.vehicleId) ?
                <SelectComponentDefect {...props} /> :
                <></>
        }
        case 'taskStatus': {
            return <SelectTaskStatus {...props} />;
        }
        case 'taskAction': {
            const data = props.formData;
            return !isEmpty(data.taskStatus) &&
                data.taskStatus === CONSTANTS.STATUS.CLOSED &&
                (data.taskCategory === 'mechanical' || data.taskCategory === 'electrical')
                ?
                <SelectTaskAction {...props} /> :
                <></>
        }
        case 'componentInstallationState': {
            const data = props.formData;
            return !isEmpty(data.taskAction) &&
                data.taskAction === CONSTANTS.ACTIONS.REPLACED ?
                <SelectField {...props} /> :
                <></>
        }
        default: {
            return <SelectField {...props} />
        }
    }
}
SelectFieldMaintenanceTasks.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
};