import Switch from 'components/Switch';
import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';


export const CheckboxField = ({
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    value,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData
}) => {
    console.log(`${t(attr)} switch`, formData[attr]);
    return (
        <div className='form-control ms-0 ps-0 mb-3'>
            <Switch label={t(attr) + (collectionData[attr].required ? '*' : '')} checked={!!formData?.[attr]} onChange={(checked) => {
                let temp = { ...formData };
                temp[attr] = checked;
                onChangeFormData(temp)
            }} />
        </div>


    )
}
CheckboxField.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
    onChangeCollectionData: PropTypes.func,
};