import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import Form from "react-bootstrap/Form";


export const TextareaField = ({
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    value,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData
}) => {
    return (
        <FloatingLabel key={"floatingTextAreaKey_" + attr} controlId={"floatingTextArea_" + attr} label={t(attr) + (collectionData[attr].required ? '*' : '')} className="mb-3">
            <Form.Control
                required={collectionData[attr].required}
                readOnly={collectionData[attr].readonly || false}
                value={formData[attr] || ''}
                onChange={(ev) => {
                    let temp = { ...formData };
                    temp[attr] = ev.target.value
                    onChangeFormData(temp)
                }}
                type={'textarea'} />
        </FloatingLabel>
    )
}
TextareaField.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
    onChangeCollectionData: PropTypes.func,
};