import { CONSTANTS } from "@constants";
import { AdminSelectFleetCompany } from "components/AdminSelectFleetCompany";
import useModal from "components/modal/hook";
import dayjs from "dayjs";
import { t } from "i18next";
import React, { useCallback, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router";
import { selectAdminChosenFleetCompanyId } from "store/pagesSlice";
import { del, get, post, put } from "utils/api";
import { store } from "../../store";
import { setNavTitle } from "../../store/globalsSlice";
import {
  selectIsAdmin,
  selectIsFleetManager,
  selectIsFleetViewer,
  selectUserPermissions
} from "../../store/userSlice";
import { CollectionList } from "../../stories/pages/CollectionList";
import { assignComputedHeads, assignPostTransformations } from "../../utils/postTransformations";

export default function List() {
  const navigate = useNavigate();
  const params = useParams();
  const dispatch = useDispatch();
  const userPermissions = useSelector(selectUserPermissions);
  const userRoutePermissions = userPermissions?.permissions?.find(
    (p) => p.route === params?.type
  );
  const isAdmin = useSelector(selectIsAdmin);
  const isFleetManager = useSelector(selectIsFleetManager);
  const isFleetViewer = useSelector(selectIsFleetViewer);
  const selectedFleetCompanyId = useSelector(selectAdminChosenFleetCompanyId);
  let customCSVHandler,
    customCSVFields,
    defaultSortBy,
    excludeFilterProperties,
    mandatoryParams,
    useCache,
    tableHeadMappings,
    tableRowClasses;
  let filterProperties = [];
  const { modal } = useModal();

  useEffect(() => {
    if (params?.type) dispatch(setNavTitle(t(params.type)));
  }, [params]);

  let showCheckboxes = true;

  const requestHandler = useCallback(
    (type, id, urlExtension, body, init) => {
      if (!params?.type) return "";
      switch (type) {
        case "post":
          return post("/" + params.type, { ...init, body });
        case "get":
          return get(
            "/" + params.type + (urlExtension || ''),
            init,
            [],
            store.dispatch
          );
        case "delete":
          return del("/" + params.type + "/" + id, {
            ...init
          });
        case "put":
          return put("/" + params.type + "/" + id, {
            ...init,
            body,
          });
        default:
          return;
      }
    },
    [params.type]
  );
  const getTableHeads = (type) => {
    switch (type) {
      case "fleets":
        const props = [
          "lockedAt",
          "fleetName",
          "tag",
          "vehicles",
          "maintenance-tasks",
          "createdAt",
          "changedAt",
        ];
        if (isAdmin || isFleetManager || isFleetViewer)
          props.splice(1, 0, "maintenanceCompanyId");
        return props;
      case "vehicles":
        return [
          "fleetId",
          "serial",
          "vehicleType",
          "vehicleStatus",
          "vehicleName",
          "createdAt",
          "changedAt",
        ];
      case "maintenance-tasks":
        return [
          "fleetId",
          "vehicleId",
          "serial",
          "taskCategory",
          "componentCategory",
          "componentId",
          "componentDefect",
          "componentInstallationState",
          "taskStatus",
          "taskAction",
          "reportedAt",
          "plannedAt",
          "createdAt",
          "changedAt",
        ];
      case "components":
        return [
          "serial",
          "vehicleId",
          "componentType",
          "componentName",
          "componentStatus",
          "componentInstallationState",
          "createdAt",
          "changedAt",
        ];
      case "fleet-companies":
        return ["fleetCompanyName", "createdAt", "changedAt"];
      case "maintenance-companies": {
        const props = ["maintenanceCompanyName", "createdAt", "changedAt"];
        if (isAdmin) props.splice(1, 0, "fleetCompanyId");
        return props;
      }
      case "users":
        const fleetCompanyPerms = userPermissions?.permissions?.find(
          (p) => p.route === "fleet-companies"
        );
        const maintenanceCompanyPerms = userPermissions?.permissions?.find(
          (p) => p.route === "maintenance-companies"
        );
        const heads = ["userEmail", "attachedRoles", "createdAt", "changedAt", "isDemoUser"];
        if (fleetCompanyPerms?.GET === CONSTANTS.CRUD_PERMISSIONS.ALL)
          heads.push("fleetCompanyId");
        if (maintenanceCompanyPerms?.GET === CONSTANTS.CRUD_PERMISSIONS.ALL)
          heads.push("maintenanceCompanyId");

        return heads;
      case "datasets":
        return ["datasetName", "createdAt", "changedAt"];
      default:
        return;
    }
  };

  const postTransformations = assignPostTransformations(params.type, navigate, modal);

  switch (params.type) {
    case CONSTANTS.namespaces.FLEETS:
      defaultSortBy = { fleetName: 'asc' }
      useCache = true;
      break;
    case CONSTANTS.namespaces.COMPONENTS:
      excludeFilterProperties = ["fleetId", "vehicleId"];
      useCache = true;
      break;
    case CONSTANTS.namespaces.USERS:
      showCheckboxes = false;
      break;
    case CONSTANTS.namespaces.MAINTENANCE_TASKS:
      useCache = true;
      tableRowClasses = (row) => {
        switch (row?.taskStatus) {
          case "in_progress":
            return "border-start border-start-4 border-info";
          case "open":
            return "border-start border-start-4 border-danger";
          case "closed":
            return "border-start border-start-4 border-success";
          case "planned":
            {
              const isDue = dayjs().isAfter(row?.plannedAt) || dayjs().isSame(row?.plannedAt)
              if (isDue) return 'bg-due td-bg-transparent'
              return `border-start border-start-4 border-dark`;
            }
          default:
            return "";
        }
      }
      break;
    case CONSTANTS.namespaces.VEHICLES:
      useCache = true;
      customCSVHandler = async (row) => {
        await post(CONSTANTS.namespaces.VEHICLES, '/vehicles', { body: row });
      }
      customCSVFields = ["defaultComponents"]
      break;
    default:
      break;
  }
  const isSpecialCollection = params.type === CONSTANTS.namespaces.VEHICLES ||
    params.type === CONSTANTS.namespaces.FLEETS ||
    params.type === CONSTANTS.namespaces.MAINTENANCE_TASKS ||
    params.type === CONSTANTS.namespaces.COMPONENTS
  if (isSpecialCollection) {
    if (isAdmin && !selectedFleetCompanyId) {
      return (
        <AdminSelectFleetCompany />
      )
    }
    if (isAdmin && selectedFleetCompanyId) {
      mandatoryParams = { fleetCompanyId: selectedFleetCompanyId }
    }
  }
  return (
    <>
      {isAdmin && isSpecialCollection && <AdminSelectFleetCompany />}
      <CollectionList
        tableHeadMappings={tableHeadMappings}
        useCache={useCache}
        computedHeads={assignComputedHeads(params.type, isAdmin)}
        defaultSortBy={defaultSortBy}
        customCSVHandler={customCSVHandler}
        customCSVFields={customCSVFields}
        title={params.title}
        filterProperties={filterProperties}
        tableRowClasses={tableRowClasses}
        excludeFilterProperties={excludeFilterProperties}
        include={getTableHeads(params.type)}
        exclude={undefined}
        schemeNamePlural={params.type}
        showCreate={userRoutePermissions?.POST !== CONSTANTS.CRUD_PERMISSIONS.NONE}
        showImporter={isAdmin}
        mandatoryParams={mandatoryParams}
        showEdit={userRoutePermissions?.PUT !== CONSTANTS.CRUD_PERMISSIONS.NONE}
        showDelete={userRoutePermissions?.DELETE === CONSTANTS.CRUD_PERMISSIONS.ALL}
        showClone={userRoutePermissions?.POST !== CONSTANTS.CRUD_PERMISSIONS.NONE}
        showHistory={isAdmin}
        showQRExport={isAdmin}
        showCheckboxes={showCheckboxes}
        autoTranslate={
          params.type === CONSTANTS.namespaces.TRANSLATIONS ? false : true
        }
        postTransformations={postTransformations}
        postHandler={(data, init) =>
          requestHandler("post", null, null, data, init)
        }
        deleteHandler={(id, data, init) =>
          requestHandler("delete", id, null, data, init)
        }
        getHandler={(urlExtension, init) =>
          requestHandler("get", null, urlExtension, null, init)
        }
        onClickCreate={(type) =>
          navigate({ pathname: "/create/" + type, search: "?action=create" })
        }
        onClickEdit={(type, id) =>
          navigate({
            pathname: "/edit/" + type + "/" + id,
            search: "?action=edit",
          })
        }
        onClickShow={
          params.type === CONSTANTS.namespaces.VEHICLES ? (id) => navigate("/vehicle-dashboard/" + id) :
            params.type === CONSTANTS.namespaces.FLEETS ? (id) => navigate("/fleet-dashboard/" + id) : undefined
        }
      />
    </>
  );
}
