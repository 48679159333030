import React, { useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { default as BSNavbar } from "react-bootstrap/Navbar";
import { BiArrowBack } from 'react-icons/bi';
import logo from '../assets/vsc_logo_portal_white.svg';
import { AccountNav } from './AccountNav';
import { NavbarTitle } from './NavbarTitle';
import { Sidebar } from './Sidebar';
import { Colors } from './utils/colors';


export const Navbar = ({ sidebarItems, accountItems }) => {

  const [windowWidth, setWindowWidth] = useState(window.innerWidth);


  const resize = function () {
    setWindowWidth(this.innerWidth);
  }
  useEffect(() => {
    window.addEventListener('resize', resize)
    return () => {
      window.removeEventListener('resize', resize);
    }
  }, [resize])

  return (
    <BSNavbar className='justify-content-between' style={{ background: Colors.background.header }} variant='dark'>
      <div className='dashboard-section-left d-flex'>
        <Sidebar items={sidebarItems} />
        <img alt="logo" className="d-none d-md-block" src={logo} style={{ width: '230px', height: 'auto' }} />
      </div>
      <div className='dashboard-section-center d-flex align-items-center' style={windowWidth >= 992 ? { marginLeft: '-230px' } : {}}>
        <Button className="d-flex align-items-center justify-content-center flex-row" variant='link' style={{ justifySelf: 'start', color: '#fff', textDecoration: 'none' }} onClick={() => { window.history.back() }}>
          <BiArrowBack size={20} color={'#fff'} />
        </Button>
        <NavbarTitle />
      </div>

      <div className='dashboard-section-right d-flex'>
        <AccountNav items={accountItems} />
      </div>
    </BSNavbar>
  )
};

Navbar.defaultProps = {
  accountItems: [{
    link: function (ev) {
    },
    name: 'Logout'
  }],
  sidebarItems: [{
    link: function (ev) { },
    name: 'Fleets'
  }],
};
