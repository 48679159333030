
import React from 'react';
import Fade from 'react-bootstrap/esm/Fade';
import { default as BSToast } from 'react-bootstrap/Toast'
import ToastContainer  from 'react-bootstrap/ToastContainer'
import { generateUUID } from './utils/common';

export const Toast = ({ id, show, variant, header, message, delay, onClose, position = 'top-center' }) => {
    return (
        <Fade in={show}>
        <ToastContainer position={position} className="p-3">
            <BSToast key={"toast_" + generateUUID()} bg={variant} onClose={() => onClose()} show={show} delay={delay} autohide>
                {
                    header &&
                    <BSToast.Header closeButton={true}>
                        {header}
                    </BSToast.Header>
                }
                <BSToast.Body><p className='text-light text-center m-0' >{message}</p></BSToast.Body>
            </BSToast>
        </ToastContainer>
        </Fade>
    );
}

Toast.propTypes = {};

Toast.defaultProps = {
    variant: 'success',
    message: 'Gespeichert!',
    delay: 3000
};