import PropTypes from 'prop-types';
import React from 'react';
import { TextField } from '../../../../stories/crud/FieldTypes/TextField';

export const TextMaintenanceTasks = (props) => {
    switch (props?.attr) {
        case 'tag': {
            return props.action === 'edit' ? <TextField {...props} /> : <></>
        }
        default: {
            return <TextField {...props} />
        }
    }
}
TextMaintenanceTasks.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
};