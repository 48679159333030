import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Select from 'react-select';

export const MultiselectAttachedRoles = ({
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    value,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData
}) => {
    return (
        <div key={"div_multi_" + index} className='mb-3'>
            <label style={{ opacity: '0.65', transform: 'scale(0.85) translateY(-0.5rem) translateX(0.15rem)' }} key={"LabelMultiSelect_" + attr} htmlFor={"multiSelect_" + attr} id={"multiSelectLabel_" + attr}>{attr}</label>
            {formData && <Select
                onChange={(newValue) => {
                    if (!newValue) return;
                    let temp = { ...formData };
                    temp[attr] = newValue?.map((n) => n.value);
                    onChangeFormData(temp)
                }}
                value={(formData?.[attr] && formData?.[attr]?.map((s) => ({ value: t(s), label: t(s) }))) || []}
                isMulti
                name={"multiselect_" + attr}
                options={collectionData[attr]?.options.map(o => {
                    if (formData?.[attr]?.length && o.value !== formData?.[attr]) {
                        return { ...o, label: t(o.label), isDisabled: true }
                    }
                    return { ...o, label: t(o.label) };
                })}
                className="basic-multi-select"
                classNamePrefix="select"
                id={"multiSelect_" + attr}
                key={"attrMultiSelect_" + attr}
                hidden={collectionData[attr]?.hidden || false}
            />}
        </div>
    )
}
MultiselectAttachedRoles.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
    onChangeCollectionData: PropTypes.func,
};