import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";

function ModalComponent({
  show,
  body,
  title,
  disabled,
  closeText,
  saveText,
  close,
  confirm,
}) {
  return (
    <>
      <Modal size={'lg'} show={show} onHide={close}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{body}</Modal.Body>
        <Modal.Footer>
          {closeText && (
            <Button disabled={disabled} variant="secondary" onClick={close}>
              {closeText}
            </Button>
          )}
          {saveText && typeof confirm === 'function' && (
            <Button disabled={disabled} variant="primary" onClick={confirm}>
              {saveText}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalComponent;
