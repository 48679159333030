import React, { useEffect, useState } from 'react';
import './Switch.css'; // Stil für den Switch

const Switch = (props) => {
    const { onChange, checked, label } = props;
    const [isChecked, setIsChecked] = useState(!!checked);

    const toggleSwitch = () => {
        const newCheckedState = !isChecked;
        setIsChecked(newCheckedState);
        onChange && onChange(newCheckedState);
    };
    useEffect(() => {
        setIsChecked(checked)
    }, [checked])

    return (
        <div className='d-flex'>
            <div style={props?.style} className={`switch ${isChecked ? 'checked' : ''} ${props?.className ? props.className : ''}`} onClick={toggleSwitch}>
                <div className="slider"></div>
            </div>
            {label && <div className="switch-label ms-1">{label}</div>}
        </div>

    );
};

export default Switch;