import { CONSTANTS } from "@constants";
import dayjs from "dayjs";
import { t } from "i18next";
import jsPDF from "jspdf";
import QRCode from 'qrcode';
import { useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import Select from 'react-select';

const Field = ({ type = 'text', label, value, setValue, options }) => {
    const customStyles = {
        control: (base, state) => ({
            ...base,
            minHeight: '48px',
            fontSize: '16px',
            paddingTop: '1.625rem',
            paddingBottom: '0.625rem',
            paddingLeft: '1rem',

            borderColor: '#0000002b',
            borderRadius: 0,
            boxShadow: 'none',
            '&:hover': {
                borderColor: state.isFocused ? '#a8d798' : '#0000002b'
            },
            backgroundColor: '#f7f7f9',
        }),
        menu: (base) => ({
            ...base,
            zIndex: 9999,
            // Stellen Sie sicher, dass das Dropdown-Menü über anderen Elementen steht.
        }),
    }
    return type !== 'select' ? (<FloatingLabel
        key={"qr_field_" + label} controlId={"qr_field_control"}
        label={label} className="mb-3">
        <Form.Control
            required={true}
            value={value}
            onChange={setValue}
            type={type} />
    </FloatingLabel>) :
        <FloatingLabel key={"floatingSelectKeyQR"} controlId={"floatingSelectKeyQR"} label={label} className="mb-3 select-form-field">
            <Select
                styles={customStyles}
                isSearchable
                isClearable
                key={"SelectKeyQR_" + label}
                required={true}
                onChange={setValue}
                placeholder=""
                value={value || ''}
                options={options?.map((item) => {
                    return { ...item, label: t(item.label) };
                })}
            />
        </FloatingLabel>
}
export const QRCodeModal = (props) => {
    const scheme = props.scheme;
    const excludedProps = ['vehicles', 'maintenance-tasks']
    const properties = props.schemeProperties?.filter(p => !excludedProps.includes(p));
    console.log('properties', properties);
    const defaultValueKey = 'id';
    const defaultDescriptionKeys = {
        [CONSTANTS.namespaces.COMPONENTS]: 'componentName',
        [CONSTANTS.namespaces.VEHICLES]: 'vehicleName',
        [CONSTANTS.namespaces.FLEETS]: 'fleetName',
        [CONSTANTS.namespaces.MAINTENANCE_TASKS]: 'id',
    }
    const [fields, setFields] = useState({
        title: '',
        codesPerPage: 4,
        codesPerRow: 2,
        valueKey: defaultValueKey,
        descriptionKey: defaultDescriptionKeys?.[scheme] || 'id'
    })

    const createQRCodeList = async () => {
        const { codesPerPage, codesPerRow, title, valueKey, descriptionKey } = fields;
        const creationDate = dayjs().format('DD.MM.YYYY - HH:mm:ss');
        const doc = new jsPDF("portrait");
        let raw = props.data;
        const pageWidth = doc.internal.pageSize.getWidth();

        // Dynamische Einstellung der Größe und des Layouts basierend auf codesPerPage

        const pageHeight = doc.internal.pageSize.getHeight();
        const codesPerColumn = Math.ceil(codesPerPage / codesPerRow);
        const itemWidth = (pageWidth - 20) / codesPerRow;
        const itemHeight = (pageHeight - 30) / codesPerColumn;
        const qrSize = Math.min(itemWidth, itemHeight * 0.7);
        const fontSize = qrSize * 0.12; // Schriftgröße basierend auf QR-Größe
        doc.setFontSize(8);
        doc.text(title + ' - ' + creationDate, 5, 5, { align: 'left' });
        doc.setFontSize(fontSize);

        let x = 10, y = 15;

        for (let i = 0; i < raw.length; i++) {
            if (!raw[i]?.[valueKey] || !raw[i]?.[descriptionKey]) continue;

            if (i > 0 && i % codesPerRow === 0) {
                x = 10;
                y += itemHeight;
            }

            if (i > 0 && i % codesPerPage === 0) {
                doc.addPage();
                doc.setFontSize(8);
                doc.text(title + ' - ' + creationDate, 5, 5, { align: 'left' });
                doc.setFontSize(fontSize);
                x = 10;
                y = 15; // Reset y position for new page
            }

            const value = raw[i][valueKey];
            const description = raw[i][descriptionKey];
            const base64Image = await QRCode.toDataURL(value);
            const imageX = x + (itemWidth - qrSize) / 2;
            const textY = y + qrSize + 5; // Text etwas unterhalb des QR-Codes

            doc.text(description, x + itemWidth / 2, textY, { align: 'center' });
            doc.addImage(base64Image, 'PNG', imageX, y, qrSize, qrSize);

            x += itemWidth;
        }


        doc.save(`${title}_${dayjs().format("DDMMYYYY_HHmmss")}.pdf`);
    };
    return (
        <Form key={"QRCodeModal"} onSubmit={() => createQRCodeList()}>
            <Row key="row" className="pt-3 justify-content-md-center">
                <Form.Group as={Col} xs={12} md={10}>
                    <Field type="text" label={'title'} value={fields.title} setValue={(e) => {
                        setFields({ ...fields, title: e.target.value })
                    }} />
                    <Field options={properties?.map(p => ({ label: t(p), value: p }))} type="select" label={'valueKey'} value={{ label: t(fields.valueKey), value: fields.valueKey }} setValue={(e) => {
                        setFields({ ...fields, valueKey: e.value })
                    }} />
                    <Field options={properties?.map(p => ({ label: t(p), value: p }))} type="select" label={'descriptionKey'} value={{ label: t(fields.descriptionKey), value: fields.descriptionKey }} setValue={(e) => {
                        setFields({ ...fields, descriptionKey: e.value })
                    }} />
                    <Field type="number" label={'codesPerPage'} value={fields.codesPerPage} setValue={(e) => {
                        setFields({ ...fields, codesPerPage: e.target.value })
                    }} />
                    <Field type="number" label={'codesPerRow'} value={fields.codesPerRow} setValue={(e) => {
                        setFields({ ...fields, codesPerRow: e.target.value })
                    }} />
                    <Button
                        className="mx-4 mb-3 ms-0"
                        size="default"
                        type="submit"
                    >
                        {t("create_list")}
                    </Button>
                </Form.Group>
            </Row>
        </Form>
    )
}