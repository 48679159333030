import { AnimatedEllipsis } from 'components/AnimatedEllipsis';
import { t } from 'i18next';
import React from 'react';
import { Button, Fade } from 'react-bootstrap';
import ContentLoader from 'react-content-loader';
import { useSelector } from 'react-redux';
import { selectProgressItems } from 'store/globalsSlice';
import { VSCLoader } from 'stories/VSCLoader';

const TableContentLoader = ({ width = '100%', height = 550, abortManager, showProgressItems = true }) => {
  const progressItems = useSelector(selectProgressItems);
  return (
    <>
      {(progressItems && showProgressItems) &&
        <Fade in={true}>
          <div className='d-flex justify-content-center align-items-center mt-3'>
            <VSCLoader />
            <b style={{ marginRight: 5, marginLeft: 5 }}>{progressItems}</b>{t('items_loaded')}<AnimatedEllipsis />
            <Button variant='text-danger' onClick={() => { abortManager?.abortManager?.cancel(abortManager?.id) }}>{t('cancel')}</Button>
          </div>
        </Fade>
      }
      {(!progressItems || !showProgressItems) &&
        <Fade in={true}>
          <div className='d-flex justify-content-center align-items-center mt-3'>
            <VSCLoader style={{ marginRight: 5 }} /> {t('loading_items')}<AnimatedEllipsis />
          </div>
        </Fade>
      }
      <ContentLoader height={height} width={width}>
        <rect x="2.5%" y="5%" width='95%' height="5%" />
        <rect x="2.5%" y="15%" width="95%" height="70%" />
      </ContentLoader>
    </>
  )
}


export default TableContentLoader