import { AdminSelectFleetCompany } from "components/AdminSelectFleetCompany";
import { t } from "i18next";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router";
import { selectAdminChosenFleetCompanyId } from "store/pagesSlice";
import { selectIsAdmin } from "store/userSlice";
import { setNavTitle } from "../../store/globalsSlice";
import StatisticsDashboard from "./Dashboard";

export default function Statistics() {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const isAdmin = useSelector(selectIsAdmin);
    const selectedFleetCompanyId = useSelector(selectAdminChosenFleetCompanyId);
    let mandatoryParams;

    useEffect(() => {
        dispatch(setNavTitle(t("statistics")));
        return () => { };
    }, []);
    if (isAdmin && !selectedFleetCompanyId) {
        return (
            <AdminSelectFleetCompany />
        )
    }
    if (isAdmin && selectedFleetCompanyId) {
        mandatoryParams = { fleetCompanyId: selectedFleetCompanyId }
    }
    return (
        <>
            {isAdmin && <AdminSelectFleetCompany />}
            <StatisticsDashboard mandatoryParams={mandatoryParams} />
        </>
    );
}