import { Dropzone, FileMosaic } from "@files-ui/react";
import { t } from "i18next";
import { useState } from "react";

const FileUpload = ({ file, getUploadUrl, defaultFileName, reload }) => {
  const [loading, setLoading] = useState(false);
  const sendFile = async (url, file, fileName) => {
    return await fetch(url, {
      body: file,
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "Content-Disposition": `attachment; filename="${fileName}"`,
      },
    });
  };
  const onChange = async (file) => {
    setLoading(true);
    const fileExtension = file?.name
      ?.substring(file?.name.lastIndexOf("."))
      ?.toLowerCase()
      ?.trim();
    if (fileExtension !== ".csv") return;
    const fileName = defaultFileName || file?.name?.replace(/\.[^/.]+$/, "");
    const url = await getUploadUrl(fileName, fileExtension);
    await sendFile(url, file.file, fileName);
    await reload();
  };
  const onFileDelete = () => { };
  return (
    <div className="mb-3">
      <Dropzone
        label={t("upload_file")}
        disabled={loading}
        autoClean
        multiple={false}
        footer={true}
        accept=".csv, .CSV"
        onChange={(files) => {
          onChange(files[0]);
        }}
        value={file ? [file] : []}
      >
        {file && (
          <FileMosaic
            key={file.id}
            {...file}
            onDelete={(id) => {
              onFileDelete(id);
            }}
            info
          />
        )}
      </Dropzone>
    </div>
  );
};
export default FileUpload;
