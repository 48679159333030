import React, { useEffect, useState } from 'react';

import { HistoryModal } from 'components/HistoryModal';
import Paginator from 'components/Paginator';
import useModal from 'components/modal/hook';
import { t } from 'i18next';
import { Col, Fade, OverlayTrigger, Row, Tooltip } from 'react-bootstrap';
import Table from 'react-bootstrap/Table';
import Button from 'react-bootstrap/esm/Button';
import { BiCheckbox, BiCheckboxChecked, BiChevronDown, BiChevronUp, BiEdit, BiHistory, BiSort, BiTrash } from 'react-icons/bi';
import { useNavigate } from 'react-router';
import { sortArrayWithConfig } from 'utils/general';
import "../../assets/css/themes/global-override.css";
import "../../assets/css/themes/index.scss";
import { Colors } from '../../constants/colors';
import TableContentLoader from '../utils/TableContentLoader';

function* dataGenerator(data, pageSize) {
    let currentPage = 0;
    while (currentPage * pageSize < data.length) {
        yield data.slice(currentPage * pageSize, (currentPage + 1) * pageSize);
        currentPage += 1;
    }
}
const List = ({
    head,
    rows,
    loading,
    onShow,
    onEdit,
    onClone,
    onDelete,
    multiSelect,
    showCheckboxes = true,
    responsive = true,
    tableClasses,
    tableCellClasses,
    tableRowClasses,
    autoTranslate = true,
    defaultSortBy,
    listActions,
    schemeName,
    showRowTooltip = true,
    abortManager,
    tableHeadMappings,
    showHistory = false,
    showProgress = true
}) => {




    const [sortBy, setSortBy] = useState(defaultSortBy || { changedAt: 'desc' });
    const [allRowsChecked, setAllRowsChecked] = useState(false);
    const [checkedRows, setCheckedRows] = useState([]);
    const pageSize = 100;
    const [currentPage, setCurrentPage] = useState(1);
    const [currentData, setCurrentData] = useState([]);
    const [mouseOverTarget, setMouseOverTarget] = useState();
    const totalPages = Math.ceil(rows.length / pageSize);
    const { modal } = useModal();
    const navigate = useNavigate();

    useEffect(() => {
        if (!rows || rows.length === 0) return;
        const generator = dataGenerator(sortArrayWithConfig(rows, sortBy), pageSize);
        // Zum aktuellen Stand springen
        for (let i = 0; i < currentPage - 1; i++) {
            generator.next();
        }
        const { value: currentPageData } = generator.next();
        setCurrentData(currentPageData);
    }, [currentPage, sortBy]);


    useEffect(() => {
        setCurrentPage(1);
    }, [rows]);

    useEffect(() => {
        setCurrentPage(1); // Zurück zur ersten Seite
        const generator = dataGenerator(sortArrayWithConfig(rows, sortBy), pageSize);
        const { value: firstPageData } = generator.next();
        setCurrentData(firstPageData);
    }, [rows, sortBy]);

    // Aktualisiere die aktuelle Datenseite, wenn sich die currentPage ändert
    useEffect(() => {
        if (currentPage === 1) return; // Vermeidet doppelte Ausführung bei initialer Einstellung

        const generator = dataGenerator(rows, pageSize);
        // Zum aktuellen Stand springen
        for (let i = 0; i < currentPage - 1; i++) {
            generator.next();
        }
        const { value: currentPageData } = generator.next();
        setCurrentData(sortArrayWithConfig(currentPageData, sortBy));
    }, [currentPage, sortBy]);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const renderTableHeadCheckbox = () => {
        if (!showCheckboxes || !rows || rows?.length === 0) return <></>
        return (
            <Button className='p-0' variant='link' onClick={() => {
                let checked = !allRowsChecked;
                setAllRowsChecked(checked);
                setCheckedRows(checked ? rows?.map((r) => r?.id) : []);
            }}>
                {!allRowsChecked && <BiCheckbox size={25} />}
                {allRowsChecked && <BiCheckboxChecked size={25} />}
            </Button>
        )
    }
    const renderTableRowCheckbox = (row) => {
        if (!showCheckboxes) return <></>
        return (
            <Button className='p-0' variant='link' onClick={() => {
                let checked = [...checkedRows];
                if (!checked.includes(row?.id)) {
                    checked.push(row?.id);
                    setCheckedRows(checked);
                } else {
                    checked = checked.filter((i) => i !== row?.id)
                    setCheckedRows(checked);
                }

                if (checked.length === rows.length) {
                    setAllRowsChecked(true)
                } else {
                    setAllRowsChecked(false)
                }
            }}>
                {!checkedRows.includes(row?.id) && <BiCheckbox size={25} />}
                {checkedRows.includes(row?.id) && <BiCheckboxChecked size={25} />}
            </Button>
        )
    }

    const handleAction = (type, row) => {
        switch (type) {
            case 'onClone':
                onClone(checkedRows.length > 0 ? rows.filter((r) => checkedRows.includes(r?.id)) : row)
                break;
            case 'onDelete':
                onDelete(checkedRows.length > 0 ? rows.filter((r) => checkedRows.includes(r?.id)) : row)
                break;
            default:
                return;
        }
        setCheckedRows([]);
        setAllRowsChecked(false);
    }
    const renderPagination = () => {
        return (
            <Paginator currentPage={currentPage} totalPages={totalPages} onPageChange={handlePageChange} />
        )
    }
    const renderPaginationAmount = () => {

        return (

            <div style={{ background: '#fff', textAlign: 'center' }} className='d-flex justify-content-center align-items-center'>
                {currentPage === totalPages ? rows.length : currentPage * pageSize} {t('from')}
                <b style={{ marginLeft: 5 }}>{rows.length}</b>
            </div>

        )
    }
    const renderRow = (row, i) => (
        <tr
            id={`list-row-${i}`}
            className={`list-row ${onShow ? ' cursor-pointer ' : ''}
                                ${typeof tableRowClasses === 'function' ?
                    tableRowClasses(row) :
                    (tableRowClasses || '')}`}
            onClick={(e) => {
                !e.target.classList.contains('actions-cell') && e.target.tagName === 'TD' ?
                    (onShow && onShow(row)) :
                    e.preventDefault()
            }} key={'trRow_' + i}>
            {multiSelect === true && showCheckboxes && <td key={'td_multi' + i}>{renderTableRowCheckbox(row)}</td>}
            {head && head.map((prop, index) => {
                return (
                    <td className={`${`list-row-${i}-td`} ${tableCellClasses || ''}`} key={'tdheads_' + index}>
                        {(typeof row[prop] === 'string' || typeof row[prop] === 'number') && (autoTranslate ? t(row[prop]) : row[prop])}
                        {typeof row[prop] === 'object' && row[prop]?.postProcess && row[prop].postProcess(row[prop].value, row)}
                    </td>
                )
            }
            )}
            {(onEdit || onClone || onDelete || showHistory) &&
                <td key={"action_td" + i} className='actions-cell sticky-right bg-white shadow-sm actions'>
                    <div className='d-flex align-items-center justify-content-evenly'>
                        {onEdit && <Button key={'action_button_edit_' + i} disabled={checkedRows.length > 1} onClick={() => onEdit(row)} style={{ padding: 0 }} variant="link"><BiEdit size={24} /></Button>}
                        {/* {onClone && <Button key={'action_button_clone_' + i} onClick={() => handleAction('onClone', row)} style={{ padding: 0 }} variant="link"><BiCopy size={24} /></Button>} */}
                        {onDelete && <Button key={'action_button_delete_' + i} onClick={() => handleAction('onDelete', row)} style={{ padding: 0 }} variant="link"><BiTrash size={24} /></Button>}
                        {showHistory && <Button key={'action_button_history_' + i} onClick={() => showHistoryModal(row?.id)} style={{ padding: 0 }} variant="link"><BiHistory size={24} /></Button>}
                    </div>
                </td>
            }
        </tr>
    )
    const showHistoryModal = (id) => {
        if (!id) return <></>;

        modal(
            <HistoryModal id={id} navigate={navigate} />,
            t("item_history"),
            t("close"),
            undefined,
            () => { },
            () => { }
        );
    }
    const renderTooltip = (props, content) => (
        <Tooltip id="button-tooltip" {...props}>
            {content}
        </Tooltip>
    );
    return (
        <div className={"list-wrapper " + tableClasses}>
            {!loading && currentData && currentData.length > 0 &&
                <Row className="sticky-top w-100 ms-0 me-0" style={style.row}>
                    {totalPages <= 1 || !rows || rows.length === 0 || loading ? <Col></Col> : <Col className="ps-0 pe-0 d-flex justify-content-start align-items-center" style={style.col}>{renderPagination()}</Col>}
                    {rows && rows.length > 0 && !loading && <Col className="px-0  d-flex justify-content-center align-items-center" style={style.col}>{renderPaginationAmount()}</Col>}
                    {listActions && <Col className="d-flex justify-content-end pe-0 align-items-center" style={style.col}>{listActions()}</Col>}
                </Row>}
            {loading && <TableContentLoader showProgressItems={showProgress} abortManager={abortManager} />}
            {!loading && currentData && currentData.length > 0 &&
                <Table
                    onScroll={() => setMouseOverTarget(undefined)}
                    onMouseOut={() => setMouseOverTarget(undefined)}
                    onMouseLeave={() => setMouseOverTarget(undefined)}
                    onMouseOver={(event) => {
                        setMouseOverTarget(event?.target)
                    }}
                    className={"list-table mb-0 mt-0"}
                    striped
                    borderless
                    responsive={responsive}
                    hover>
                    <thead>
                        <tr>
                            {multiSelect === true && showCheckboxes === true && <th key={'th_multi_1'} className={`text-nowrap w-1`}>{renderTableHeadCheckbox()}</th>}
                            {head && head?.map((item, index) =>
                                <th className={'text-nowrap ' + tableCellClasses} key={'th_' + index}>
                                    {tableHeadMappings?.[item] || t(item)}

                                    <span key={"span_head" + index} onClick={(ev) => {
                                        let sortArr = { ...sortBy };
                                        if (!sortBy?.[item]) {
                                            setSortBy({ [item]: 'asc' })
                                            return;
                                        }
                                        if (sortBy?.[item] === 'default') sortArr[item] = 'asc';
                                        if (sortBy?.[item] === 'asc') sortArr[item] = 'desc';
                                        if (sortBy?.[item] === 'desc') {
                                            setSortBy({ [item]: 'default' })
                                            return;
                                        }
                                        setSortBy(sortArr)
                                    }} >
                                        {(sortBy?.[item] === 'default' || !sortBy?.[item]) && <BiSort color={Colors.primary} style={{ fontWeight: 'bolder' }} size={16} />}
                                        {sortBy?.[item] === 'desc' && <BiChevronDown color={Colors.primary} size={16} />}
                                        {sortBy?.[item] === 'asc' && <BiChevronUp color={Colors.primary} size={16} />}
                                    </span>

                                </th>
                            )}
                            {typeof onEdit === 'function' && <th key={"onEdit_head"} className='sticky-right bg-white shadow-sm actions'>{t('actions')}</th>}
                        </tr>
                    </thead>
                    <tbody>

                        {currentData && currentData.map((row, i) => {
                            if (onShow && showRowTooltip) return (
                                <OverlayTrigger
                                    key={'tooltip_data_' + i}
                                    placement="top"
                                    show={mouseOverTarget &&
                                        mouseOverTarget?.classList?.contains(`list-row-${i}-td`) &&
                                        (!mouseOverTarget?.role || !mouseOverTarget?.href)
                                    }
                                    overlay={(props) => renderTooltip(props, t(`tooltip_open_${schemeName}_item`))}
                                    delay={{ show: 250, hide: 0 }}

                                >
                                    {renderRow(row, i)}
                                </OverlayTrigger>
                            );

                            return renderRow(row, i)
                        })
                        }


                    </tbody>
                </Table>}
            {(!currentData || currentData.length === 0) && !loading &&
                <Fade in={true}>
                    <div className='vh-50 d-flex justify-content-center align-items-center mt-3'>
                        <h2>{t('no_data')}</h2>
                    </div>
                </Fade>
            }
        </div>

    );
};

List.defaultProps = {
    head: [
        'ID',
        'Title',
        "Model",
        "Status",
        "Meta",
    ],
    rows: [
        {
            items: [
                '123213dd-d-111-1',
                'Vehicle1',
                "Scott",
                "maintenance",
                "Lorem Ipsum"
            ]
        },
        {
            items: [
                '523213dd-d-111-1',
                'Vehicle2',
                "Bosch",
                "operative",
                "Lorem Ipsum"
            ]
        }, {
            items: [
                '523213dd-d-111-4',
                'Vehicle3',
                "Zonenschein",
                "operative",
                "Lorem Ipsum"
            ]
        }, {
            items: [
                '523213dd-d-111-4',
                'Vehicle4',
                "Bosch",
                "operative",
                "Lorem Ipsum"
            ]
        }
    ]
};

const style = {
    col: {
        background: '#fff'
    },
    row: {
        background: '#fff'
    }
}

export default React.memo(List)


