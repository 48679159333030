import { t } from 'i18next';
import React from 'react';
import { Form } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { setLang } from "../store/settingsSlice";
import { isEmpty } from './utils/common';


export const LanguageSelect = ({ showLabel = false }) => {
    const dispatch = useDispatch();
    const settings = useSelector(state => state.settings)
    const selectedLanguage = settings.lang;
    const languages = settings.languages.map((l) => {
        return {
            label: l
        }
    })
    return (
        <div className='py-1'>
            {showLabel && <Form.Label htmlFor="choose-fleetCompany">{t('language')}</Form.Label>}
            <Form.Select
                id="choose-fleetCompany"
                key={"AdminSelectFleetCompany_select"}
                required={true}
                onChange={(ev) => {
                    if (!isEmpty(ev?.target?.value)) dispatch(setLang(ev.target.value))
                }}

                value={selectedLanguage || ''}
                aria-label="Floating label select example">
                <option></option>

                {languages?.map((item, i) => {
                    return (
                        <option
                            key={"lang_key" + item.label} value={item.label}>
                            {t(item.label)}
                        </option>
                    )


                })}
            </Form.Select>
        </div>

    );
};


