import { CONSTANTS } from "@constants";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  fetchAuthSession,
  fetchUserAttributes,
} from "aws-amplify/auth";
import { roles } from "..";



const initialState = {
  attributes: {},
  roles: [],
  sessionData: null,
  adminUseDemoBackend: false
};

const userSlice = createSlice({
  name: "user",
  initialState,
  reducers: {
    resetUser() {
      return initialState;
    },
    toggleUseDemoBackend(state) {
      state.adminUseDemoBackend = !state.adminUseDemoBackend;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchUserSessionData.fulfilled, (state, action) => {
      state.roles = action.payload.roles;
      state.sessionData = action.payload.data;
    });
    builder.addCase(fetchAttributes.fulfilled, (state, action) => {
      state.attributes = action.payload;
    });
  },
});

export const fetchUserSessionData = createAsyncThunk(
  "user/fetchUserSessionData",
  async () => {
    const session = await fetchAuthSession({ forceRefresh: true });
    let roles;
    const data = session?.tokens?.idToken?.payload;
    if (typeof session?.tokens?.idToken?.payload?.roles === "string") {
      roles = JSON.parse(session?.tokens?.idToken?.payload?.roles);
      return { roles, data };
    }
    else {
      throw new Error(CONSTANTS.ERROR_CODES.AUTH.NO_ROLE_PROVIDED)
    }
  }
);

export const fetchAttributes = createAsyncThunk(
  "user/fetchUserAttributes",
  async (args, { dispatch }) => {
    return await fetchUserAttributes();
  }
);

export const userReducer = userSlice.reducer;
export const { resetUser, toggleUseDemoBackend } = userSlice.actions;

export const selectUserAttributes = (state) => {
  return state.user.attributes;
};
export const selectUserPermissions = (state) => {
  const currentUserRole = state.user.roles?.find((i, index) => index === 0);
  return roles?.find((r) => r.role_name === currentUserRole);
};
export const selectIsAdmin = (state) => {
  const isAdmin = state.user.roles.find((r) => r === "admin");
  return !!isAdmin;
};
export const selectIsMaintenanceManager = (state) => {
  const hasRole = state.user.roles.find((r) => r === 'maintenance_manager');
  return !!hasRole;
};
export const selectIsFleetManager = (state) => {
  const hasRole = state.user.roles.find((r) => r === 'fleet_manager');
  return !!hasRole;
};
export const selectUserRoles = (state) => state.user.roles;
export const selectIsDemoUser = (state) => state.user?.sessionData?.isDemoUser === 'true';
export const selectUserFleetCompanyId = (state) => state.user?.sessionData?.fleetCompanyId;
export const selectUseDemoBackend = (state) => {
  const isAdmin = selectIsAdmin(state);
  const isDemoUser = selectIsDemoUser(state);
  if (isAdmin) return state.user.adminUseDemoBackend;
  if (isDemoUser) return true;

};
export const selectApi = (state) => {
  const useDemoBackend = selectUseDemoBackend(state);
  return useDemoBackend ? 'AWSDemoApi' : 'AWSApi';
};
export const selectIsFleetViewer = (state) => {
  const hasRole = state.user.roles.find((r) => r === 'fleet_viewer');
  return !!hasRole;
};