import {
  Dropzone,
  FileMosaic,
  FullScreen,
  ImagePreview,
} from "@files-ui/react";
import { Buffer } from "buffer";
import { t } from "i18next";
import PropTypes from "prop-types";
import React, { useState } from "react";
import { del, get } from "utils/api";

export const FilesField = ({
  type,
  action,
  itemId,
  preDefinedProperties,
  schemesHandler,
  postHandler,
  patchHandler,
  backHandler,
  hidden,
  getHandler,
  attr,
  value,
  index,
  formData,
  collectionData,
  htmlSchemes,
  onChangeFormData,
  onChangeCollectionData,
}) => {
  const [previewImg, setPreviewImg] = useState();

  const sendFile = async (url, file, fileName) => {
    return await fetch(url, {
      body: file,
      method: "PUT",
      headers: {
        "Content-Type": file.type,
        "Content-Disposition": `attachment; filename="${fileName}"`,
      },
    });
  };
  const getUploadUrl = async (fileName, fileExtension, id) => {
    return await get(
      `/${type}/get-upload-url/${id}/${fileName}/${fileExtension.replace(
        ".",
        ""
      )}`,
      {}
    );
  };

  const changeFormDataHandler = async (value, attr) => {
    let data = { ...formData };

    if (itemId) {
      const filesToUpload = value
        .map((item) => item.id)
        .filter((id) => data[attr].map((d) => d.id).indexOf(id) < 0);
      for (let f in filesToUpload) {
        const id = filesToUpload[f];
        const file = value.find((v) => v.id === id);
        file.uploadStatus = "uploading";
        data[attr].push(file);
        const index = data[attr].findIndex((f) => f.id === file.id);
        onChangeFormData({ ...data });
        try {
          const fileExtension = file?.name?.substring(
            file?.name.lastIndexOf(".")
          );
          const fileName = file?.name
            ?.replace(/\.[^/.]+$/, "")
            .replace(/[^A-Za-z0-9.\s]+/gi, "_");
          const url = await getUploadUrl(fileName, fileExtension, itemId);
          await sendFile(url, file.file, fileName);
          file.uploadStatus = "success";
          file.fileKey = `${type}/${itemId}/${fileName}${fileExtension}`;
          const fileInfos = await get(
            `/${type}/file/${itemId}/${Buffer.from(
              file.fileKey,
              "utf-8"
            ).toString("base64")}`,
            {}
          );
          file.imageUrl = fileInfos.imageUrl;
          data[attr][index] = file;
          onChangeFormData({ ...data });
        } catch (e) {
          console.error("error upload", e);
          file.uploadStatus = "error";
          data[attr][index] = file;
          onChangeFormData({ ...data });
        }
      }
    }
  };

  const onFileDelete = async (id) => {
    const data = { ...formData };
    const file = data["files"].find((f) => f.id === id);
    const index = data["files"].findIndex((f) => f.id === id);
    if (!file) return;
    data[attr][index].uploadStatus = "preparing";
    onChangeFormData({ ...data });
    await del(
      `/${type}/file/${itemId}/${Buffer.from(file.fileKey, "utf-8").toString(
        "base64"
      )}`,
      {}
    );

    if (index > -1) data["files"].splice(index, 1);
    onChangeFormData({ ...data });
  };

  if (action === "create") return <></>;

  return (
    <div className="mb-3">
      <Dropzone
        label={t("upload_files")}
        autoClean
        footer={false}
        onChange={(files) => {
          changeFormDataHandler(files, attr);
        }}
        value={formData[attr]}
      >
        {formData[attr]?.map((file, i) => {
          return (
            <FileMosaic
              uploadStatus={file.uploadStatus || ""}
              key={file.id}
              {...file}
              onDelete={(id) => {
                onFileDelete(id);
              }}
              info
              onSee={(img) => {
                setPreviewImg(img);
              }}
              downloadUrl={file.imageUrl}
            />
          );
        })}
      </Dropzone>
      <FullScreen
        open={previewImg !== undefined}
        onClose={() => setPreviewImg(undefined)}
      >
        <ImagePreview src={previewImg} />
      </FullScreen>
    </div>
  );
};
FilesField.propTypes = {
  type: PropTypes.string,
  action: PropTypes.string,
  itemId: PropTypes.any,
  preDefinedProperties: PropTypes.any,
  schemesHandler: PropTypes.func,
  postHandler: PropTypes.func,
  patchHandler: PropTypes.func,
  backHandler: PropTypes.func,
  hidden: PropTypes.array,
  getHandler: PropTypes.func,
  attr: PropTypes.any,
  value: PropTypes.any,
  index: PropTypes.any,
  formData: PropTypes.object,
  collectionData: PropTypes.object,
  htmlSchemes: PropTypes.object,
  onChangeFormData: PropTypes.func,
  onChangeCollectionData: PropTypes.func,
};
