import { t } from 'i18next';
import PropTypes from 'prop-types';
import React from 'react';
import Form from "react-bootstrap/Form";
import FloatingLabel from 'react-bootstrap/esm/FloatingLabel';
import { isEmpty } from '../../utils/common';


export const TextField = ({
    type,
    action,
    itemId,
    preDefinedProperties,
    schemesHandler,
    postHandler,
    patchHandler,
    backHandler,
    hidden,
    getHandler,
    attr,
    value,
    index,
    formData,
    collectionData,
    htmlSchemes,
    onChangeFormData,
    onChangeCollectionData
}) => {
    return (
        <FloatingLabel
            hidden={collectionData[attr].hidden || false}
            key={"floatingTextKey_" + attr} controlId={"floatingTextInput_" + attr}
            label={t(attr) + (collectionData[attr].required ? '*' : '')} className="mb-3">
            <Form.Control
                required={collectionData[attr].required}
                hidden={collectionData[attr].hidden || false}
                readOnly={collectionData[attr].readonly || false}
                value={isEmpty(formData[attr]) ? '' : formData[attr]}
                onChange={(ev) => {
                    let temp = { ...formData };
                    temp[attr] = ev.target.value
                    onChangeFormData(temp)
                }}
                type={collectionData[attr]?.type || 'text'} />
        </FloatingLabel>
    )
}
TextField.propTypes = {
    type: PropTypes.string,
    action: PropTypes.string,
    itemId: PropTypes.any,
    preDefinedProperties: PropTypes.any,
    schemesHandler: PropTypes.func,
    postHandler: PropTypes.func,
    patchHandler: PropTypes.func,
    backHandler: PropTypes.func,
    hidden: PropTypes.array,
    getHandler: PropTypes.func,
    attr: PropTypes.any,
    value: PropTypes.any,
    index: PropTypes.any,
    formData: PropTypes.object,
    collectionData: PropTypes.object,
    htmlSchemes: PropTypes.object,
    onChangeFormData: PropTypes.func,
    onChangeCollectionData: PropTypes.func,
};