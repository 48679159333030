export const Colors = {
    blue: '#007bff',
    indigo: '#6610f2',
    purple: '#6f42c1',
    pink: '#e83e8c',
    red: '#d9534f',
    orange: '#fd7e14',
    yellow: '#f0ad4e',
    green: '#4bbf73',
    teal: '#20c997',
    cyan: '#1f9bcf',
    white: '#fff',
    gray: '#919aa1',
    grayDark: '#343a40',
    primary: '#50ae30',
    secondary: '#fff',
    success: '#4bbf73',
    info: '#1f9bcf',
    warning: '#f0ad4e',
    danger: '#d9534f',
    light: '#fff',
    dark: '#343a40',
    background:{
        header:'linear-gradient(90deg, rgba(66,149,39,1) 0%, rgba(80,174,48,1) 30%)'
    }
}